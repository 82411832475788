import React from 'react';
import BBTooltip from '../../../../atoms/tooltip/BBTooltip';
import './CellWithOneLineOfText.scss';

const CellWithOneLineOfText = ({ text }) => {
  return (
    <div className='cell__container cell--one-line-text'>
      <BBTooltip text={text} placement='bottom'>
        <div>{text}</div>
      </BBTooltip>
    </div>
  );
};

export default CellWithOneLineOfText;
