import React, { useCallback, useEffect, useState } from 'react';
import BBTooltip from '../../atoms/tooltip/BBTooltip';

const defaultValue = { label: '', isEmpty: false, name: '' };

const CustomizedAxisTick = (props) => {
  const { x, y, index, data } = props;
  const [position, setPosition] = useState(0);
  const { label, isEmpty, name } = data?.[index] || defaultValue;

  const tooltipPosition =
    index === 0
      ? 'bottom-left'
      : index + 1 === data.length
      ? 'bottom-right'
      : 'bottom';

  const updatePosition = useCallback(() => {
    const selector = `.custom-label.label-index-${index}`;
    const content = document.querySelector(selector);
    if (!content) return false;
    setPosition(-content.offsetWidth / 2);
  }, [setPosition, x, index]);

  useEffect(() => {
    updatePosition();
    window.addEventListener('resize', updatePosition, false);
    return () => window.removeEventListener('resize', updatePosition);
  }, []);

  if (label.length === 0) return null;

  return (
    <g
      className='recharts-layer recharts-cartesian-axis-tick'
      transform={`translate(${x},${y})`}
    >
      <foreignObject
        x={position}
        width={'100%'}
        height={'100%'}
        orientation='bottom'
        className='recharts-text recharts-cartesian-axis-tick-value'
        textAnchor='middle'
        fill='#414B5C'
        style={{ stroke: '#414B5C', strokeWidth: 2, overflow: 'visible' }}
      >
        <BBTooltip text={name}>
          <div
            xmlns='http://www.w3.org/1999/xhtml'
            className={`custom-label label-index-${index} ${
              isEmpty ? 'empty' : ''
            }`}
            aria-label={name}
            style={{ display: 'inline-block', position: 'absolute' }}
          >
            {label}
          </div>
        </BBTooltip>
      </foreignObject>
    </g>
  );
};

export default CustomizedAxisTick;
