import React from 'react';
import PlanetDecoration from '../../assets/planet.png';
import { useTranslation } from 'react-i18next';
import './questionProgressBar.scss';

const QuestionProgressBar = ({
  currentQuestion,
  totalQuestions,
  isFinished
}) => {
  const { t } = useTranslation();
  const progress = (currentQuestion / totalQuestions) * 100;
  const isLastQuestion = currentQuestion === totalQuestions;

  return (
    <div
      className={`question-progress-bar ${
        isFinished && isLastQuestion ? 'question-progress-bar--finished' : ''
      }`}
    >
      <div className='question-progress-bar__bar'>
        <div className='progress-bar'>
          <div
            className='progress-bar__fill'
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <img
          className='progress-bar__decoration'
          src={PlanetDecoration}
          alt=''
        />
      </div>

      <div className='question-progress-bar__counter'>{` ${currentQuestion} ${t(
        'quiz_of'
      )} ${totalQuestions}`}</div>
    </div>
  );
};

export default QuestionProgressBar;
