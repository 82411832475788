import React from 'react';
import Icon from '../../atoms/icon';

const AxisLabel = ({ iconName, label }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Icon type={iconName} format='outline' />{' '}
      <span
        style={{
          fontWeight: 600,
          fontSize: '0.8rem',
          marginLeft: '0.5rem'
        }}
      >
        {label}
      </span>
    </div>
  );
};

export default AxisLabel;
