import { localStorageKeys } from '../localStorageKeys';
import { tryJsonParse } from './tryJsonParse';

export const getBBTeacherUserLanguage = () => {
  const bbUserLanguage = localStorage.getItem(localStorageKeys.language);
  const bbCurrentCourse = tryJsonParse(
    localStorage.getItem(localStorageKeys.currentCourse)
  );
  const currentCourseLang = bbCurrentCourse?.program_lang;
  const navigatorLang = navigator.language.split('-')[0];

  const defaultLanguage =
    bbUserLanguage && bbUserLanguage !== 'null'
      ? bbUserLanguage
      : currentCourseLang || navigatorLang;

  return defaultLanguage;
};
