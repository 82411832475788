import { useActivityContext } from '../../../context/ActivityContext/useActivityContext'

// Este es nuestro custom hook
export function useActivityFooter({ setIsFeedbackVisible, onActivityEvent }) {
  const {
    isAnswerCorrect,
    isHintUsed,
    isSolutionShowed,
    setIsSolutionShowed,
    activityStore,
    setIsHintUsed
  } = useActivityContext()

  const toogleHint = () => {
    if (!activityStore.hints.isVisible) {
      setIsHintUsed(true)

      activityStore.hints.showHint()

      onActivityEvent({ name: 'Hint Clicked' })
    } else {
      activityStore.hints.hideHint()
    }
  }

  const toogleSolutions = () => {
    const showSolutionActive = !activityStore.showSolution

    if (showSolutionActive) {
      setIsSolutionShowed(true)

      onActivityEvent({
        name: 'Show Solution Clicked',
        properties: {
          result: isAnswerCorrect,
          hint_used: isHintUsed
        }
      })
    } else {
      onActivityEvent({
        name: 'See My Answer Clicked',
        properties: {
          result: isAnswerCorrect,
          hint_used: isHintUsed
        }
      })
    }

    activityStore.showSolutions(showSolutionActive)
    setIsFeedbackVisible(false)
  }

  const continuePractice = () => {
    onActivityEvent({ name: 'Continue Clicked' })
  }

  const shouldUserViewSolution = () => {
    return !isAnswerCorrect && !isSolutionShowed
  }

  return {
    toogleHint,
    toogleSolutions,
    continuePractice,
    shouldUserViewSolution
  }
}
