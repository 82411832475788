import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { AnalysisSegment } from '../../../utils/analysis';
import { CourseContext } from '../../../utils/contexts/CourseContext';
import { useLocalStorage } from '../../../utils/hooks/useLocalStorage';
import { localStorageKeys } from '../../../utils/localStorageKeys';

const useClassProgress = () => {
  const { pathname } = useLocation();
  const { selectedCourse } = useContext(CourseContext);
  const { t } = useTranslation();
  const { getItem, removeItem } = useLocalStorage();

  const getHref = (path, key) => {
    let filter = getItem(path);

    if (filter) {
      try {
        filter = JSON.parse(filter);
      } catch (e) {
        filter = {};
      }
      return `${path}?${key}=${filter[key]}`;
    }
    return path;
  };

  const UNIT_TAB = {
    name: t('units'),
    href: '/class-progress/units',
    link: '/class-progress/units',
    filter: 'unit'
  };

  const STANDARD_TAB = {
    name: t('Standards'),
    href: '/class-progress/standards',
    link: '/class-progress/standards',
    filter: 'standard'
  };

  const TIMELINE_TAB = {
    name: t('timeline'),
    href: '/class-progress/timeline',
    link: '/class-progress/timeline',
    filter: 'timeline'
  };

  const [currentTab, setCurrentTab] = useState(UNIT_TAB.name);
  const [tabs, setTabs] = useState([]);
  const [isWaitingForTabManagement, setIsWaitingForTabManagement] =
    useState(true);

  useEffect(() => {
    if (!selectedCourse?.guid && !isWaitingForTabManagement) return;

    setTabs([]);
    setIsWaitingForTabManagement(true);
  }, [selectedCourse]);

  useEffect(() => {
    if (!selectedCourse) return;

    const isFromLogin =
      localStorage.getItem(localStorageKeys.traceIsLoginHome) || false;

    AnalysisSegment.sendSegmentTrackEvent(
      AnalysisSegment.SEGMENT_EVENTS.Home_Page_Viewed,
      {
        class: selectedCourse?.guid,
        program_id: selectedCourse?.program_guid,
        after_login: isFromLogin ? 'y' : 'n',
        students: selectedCourse?.users?.length
      }
    );

    removeItem(localStorageKeys.traceIsLoginHome);
  }, [selectedCourse]);

  useEffect(() => {
    if (!selectedCourse?.guid) return;

    let unitTab = {
      ...UNIT_TAB,
      href: getHref('/class-progress/units', 'unit')
    };
    let standardTab = {
      ...STANDARD_TAB,
      href: getHref('/class-progress/standards', 'standard')
    };

    if (selectedCourse.standard_blueberry === 1) {
      setTabs([unitTab, standardTab]);
    } else {
      setTabs([unitTab]);
    }

    if (isWaitingForTabManagement) {
      setIsWaitingForTabManagement(false);
    }
  }, [selectedCourse, currentTab, pathname]);

  const onChangeTab = (selectedTab) => {
    setCurrentTab(selectedTab);
  };

  return {
    tabs,
    currentTab,
    onChangeTab
  };
};

export default useClassProgress;
