import PropTypes from 'prop-types';
import React from 'react';

import './Typography.scss';

const Typography = ({ variant, className, children }) => {
  switch (variant) {
    case 'h1':
      return <h1 className={`typography--h1 ${className}`}>{children}</h1>;
    case 'h2':
      return <h2 className={`typography--h1 ${className}`}>{children}</h2>;
    case 'h3':
      return <h3 className={`typography--h3 ${className}`}>{children}</h3>;
    case 'h4':
      return <h4 className={`typography--h4 ${className}`}>{children}</h4>;
    case 'h5':
      return <h5 className={`typography--h5 ${className}`}>{children}</h5>;
    case 'h6':
      return <h6 className={`typography--h6 ${className}`}>{children}</h6>;
    case 'subtitle1':
      return (
        <h6 className={`typography--subtitle1 ${className}`}>{children}</h6>
      );
    case 'subtitle2':
      return (
        <h6 className={`typography--subtitle2 ${className}`}>{children}</h6>
      );
    case 'body1':
      return <p className={`typography--body1 ${className}`}>{children}</p>;
    case 'body2':
      return <p className={`typography--body2 ${className}`}>{children}</p>;
    case 'button':
      return (
        <button className={`typography--button ${className}`}>
          {children}
        </button>
      );
    case 'caption':
      return <p className={`typography--caption ${className}`}>{children}</p>;
    case 'overline':
      return <p className={`typography--overline ${className}`}>{children}</p>;
    default:
      return (
        <div className={`typography--default ${className}`}>{children}</div>
      );
  }
};

Typography.propTypes = {
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'button',
    'caption',
    'overline'
  ]),
  children: PropTypes.node.isRequired
};

Typography.defaultProps = {
  variant: 'body1'
};

export default Typography;
