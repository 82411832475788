const useStudentsDataParsers = () => {
  const calculatePercentage = (progress, progressTotal) => {
    if (progressTotal === 0) {
      return 0;
    }
    const percentage = parseFloat(
      ((progress / progressTotal) * 100).toFixed(1)
    );
    return percentage % 1 === 0
      ? parseFloat(percentage.toFixed(0))
      : percentage;
  };

  // Parse data for cells
  const getUserEngagementData = (
    userEngagementData,
    numberOfLastDays,
    hasEngagementHours
  ) => {
    const DAILY_GOAL_TIME_IN_SECONDS = 15 * 60;

    const progressBarTotalInSeconds =
      DAILY_GOAL_TIME_IN_SECONDS * numberOfLastDays;

    const userTimePercent = calculatePercentage(
      userEngagementData?.totalTimeInSeconds,
      progressBarTotalInSeconds
    );

    const userTimeHours = Math.floor(
      userEngagementData?.totalTimeInSeconds / 3600
    );

    const userTimeMinutes = Math.floor(
      (userEngagementData?.totalTimeInSeconds % 3600) / 60
    );
    const userTimeSeconds = userEngagementData?.totalTimeInSeconds % 60;

    const _engagementData = {
      totalActivities: userEngagementData?.activities?.total,
      hasEngagementHours: hasEngagementHours,
      userTimeHours: userTimeHours,
      userTimeMinutes: userTimeMinutes,
      userTimeSeconds: userTimeSeconds,
      userTimePercent: userTimePercent
    };

    return _engagementData;
  };

  const getUserPerformanceData = (userPerformanceData) => {
    const correctPercent = calculatePercentage(
      userPerformanceData?.ok,
      userPerformanceData?.total
    );

    const incorrectPercent = calculatePercentage(
      userPerformanceData?.ko,
      userPerformanceData?.total
    );
    const abandonedPercent = calculatePercentage(
      userPerformanceData?.unfinished,
      userPerformanceData?.total
    );

    const _performanceData = {
      total: userPerformanceData?.total,
      correct: userPerformanceData?.ok,
      incorrect: userPerformanceData?.ko,
      abandoned: userPerformanceData?.unfinished,
      correctPercent: correctPercent,
      incorrectPercent: incorrectPercent,
      abandonedPercent: abandonedPercent
    };

    return _performanceData;
  };

  const getUserMasteredContentData = (userMasteredContentData, numberOfKcs) => {
    const masteredKcs = userMasteredContentData?.masteredKcs || 0;
    const masteredKcsInLastDays =
      userMasteredContentData?.masteredKcsInLastDays || 0;
    const forgottenKcs = userMasteredContentData?.forgottenKcs || 0;

    const masteredKcsPercent = calculatePercentage(masteredKcs, numberOfKcs);
    const masteredKcsInLastDaysPercent = calculatePercentage(
      masteredKcsInLastDays,
      numberOfKcs
    );

    const restOfMasteredKcsPercent = calculatePercentage(
      masteredKcs - masteredKcsInLastDays,
      numberOfKcs
    );

    const forgottenKcsPercent = calculatePercentage(forgottenKcs, numberOfKcs);

    const _masteredContentData = {
      masteredKcsPercent: masteredKcsPercent,
      masteredKcsInLastDaysPercent: masteredKcsInLastDaysPercent,
      restOfMasteredKcsPercent: restOfMasteredKcsPercent,
      totalKcs: numberOfKcs,
      forgottenKcsPercent: forgottenKcsPercent
    };

    return _masteredContentData;
  };

  return {
    getUserEngagementData,
    getUserPerformanceData,
    getUserMasteredContentData
  };
};

export default useStudentsDataParsers;
