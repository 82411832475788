import { localStorageKeys } from '../localStorageKeys';

let token = '';

export function getToken() {
	return localStorage.getItem(localStorageKeys.token) || token;
}

export function setToken(value) {
	localStorage.setItem(localStorageKeys.token, value);
	token = value;
}

export function clearToken() {
	localStorage.removeItem(localStorageKeys.token);
	token = '';
}
