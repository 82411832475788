export const isIosDevice = () => {
  // El user agent se fuerza porque a veces los iPad tiene userAgent de Mac
  //  se añade la comprobación adicional de si es táctil
  //  (mejor algún falso positivo que muchos falsos negativos en este caso)
  const userAgent = navigator.userAgent.toLowerCase()
  const isIOSUserAgent = /ipad|iphone|mac\sos/i.test(userAgent)
  const isTouchDevice = navigator.maxTouchPoints > 1

  return isIOSUserAgent && isTouchDevice
}

export const isAndroidDevice = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  return /android/i.test(userAgent)
}

export const getDeviceType = () => {
  const ua = navigator.userAgent.toLowerCase()
  let deviceType = 'desktop'

  // Las tablets iPad pueden usar como user agent el de un mac por eso añadimos este punto
  if (isIosDevice()) {
    if (ua.includes('iphone')) {
      deviceType = 'phone'
    } else {
      deviceType = 'tablet'
    }
  }
  // Si no es un dispositivo iOS se usa el método standar
  else if (
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      ua
    )
  ) {
    deviceType = 'tablet'
  } else if (
    /(mobi|ipod|phone|blackberry|opera mini|fennec|minimo|symbian|psp|nintendo ds|archos|skyfire|puffin|blazer|bolt|gobrowser|iris|maemo|semc|teashark|uzard)/.test(
      ua
    )
  ) {
    deviceType = 'phone'
  }

  return deviceType
}
