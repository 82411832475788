import { Button, CircularProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as GenerateIcon } from '../../../../../../assets/icons/ai.svg';
import QuizBadge from '../../../viewQuiz/components/quizBadge/QuizBadge';
import ManageTopicsDialog from '../../manageTopicsDialog/ManageTopicsDialog';
import './CreateQuizFooterBar.scss';

const CreateQuizFooterBar = ({
  selectedLessons = [],
  onRemoveLesson = () => {},
  onRemoveAllLessons = () => {},
  onCreateQuiz = () => {},
  isCreatingQuiz = false
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const onCancel = () => {
    navigate('/quizzes');
  };

  const onRemoveAllLessonsAndClose = () => {
    onRemoveAllLessons();
    setIsPopupOpen(false);
  };

  useEffect(() => {
    console.log('selectedLessons footer', selectedLessons);
  }, [selectedLessons]);

  return (
    <div className='create-quiz-footer-bar'>
      <div className='create-quiz-footer-bar__container'>
        <div className='create-quiz-footer-bar__selected-lessons'>
          {selectedLessons?.length > 0 ? (
            <>
              {selectedLessons.slice(0, 3).map((lesson) => (
                <QuizBadge
                  key={lesson?.guid}
                  label={lesson?.name}
                  tooltip={lesson?.name}
                  badge={lesson?.educationYear}
                  onDelete={() => onRemoveLesson(lesson.guid)}
                />
              ))}
              {selectedLessons.length > 3 && (
                <div
                  className='create-quiz-footer-bar__more-topics'
                  onClick={() => setIsPopupOpen(true)}
                >
                  {`+ ${selectedLessons.length - 3} ${
                    selectedLessons.length - 3 > 1 ? t('Topics') : t('Topic')
                  }`}
                </div>
              )}
            </>
          ) : (
            <Typography variant='subtitle1'>
              {t('Select one or more topics')}
            </Typography>
          )}
        </div>
        <div className='create-quiz-footer-bar__actions'>
          <Button
            variant='contained'
            color='grey'
            disableElevation
            onClick={onCancel}
            disabled={isCreatingQuiz}
          >
            {t('cancel')}
          </Button>

          <Button
            sx={{ minWidth: '214px', marginLeft: '8px' }}
            variant='contained'
            startIcon={<GenerateIcon />}
            disableElevation
            onClick={onCreateQuiz}
            disabled={isCreatingQuiz}
          >
            {isCreatingQuiz ? <CircularProgress /> : t('generate_practice')}
          </Button>
        </div>
      </div>
      <ManageTopicsDialog
        isPopupOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        selectedLessons={selectedLessons}
        onRemoveLesson={onRemoveLesson}
        onRemoveAllLessonsAndClose={onRemoveAllLessonsAndClose}
      />
    </div>
  );
};

CreateQuizFooterBar.propTypes = {
  selectedLessons: PropTypes.array.isRequired,
  onRemoveLesson: PropTypes.func.isRequired,
  onRemoveAllLessons: PropTypes.func.isRequired,
  onCreateQuiz: PropTypes.func.isRequired,
  isCreatingQuiz: PropTypes.bool.isRequired
};

export default CreateQuizFooterBar;
