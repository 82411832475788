import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import './TDLinkTabs.scss';

const TDLinkTabs = ({ links, current, onChange }) => {
  const location = useLocation();

  useEffect(() => {
    if (links.length > 0) {
      const currentLink = links.filter(
        (link) => link.href.indexOf(location.pathname) > -1
      )[0];
      onChange && onChange(currentLink.name);
    }
  }, [links]);

  return (
    <div className='link-tabs__container'>
      {links.length > 1 &&
        links.map((link) => {
          return (
            <Link
              key={link.name}
              to={link.href}
              state={{ links }}
              onClick={() => onChange && onChange(link.name)}
              className={`link-tabs__link ${
                current
                  ? current === location.pathname
                    ? 'link-tabs__link--active'
                    : ''
                  : link.href.indexOf(location.pathname) > -1
                  ? 'link-tabs__link--active'
                  : ''
              }`}
            >
              {link.name}
            </Link>
          );
        })}
    </div>
  );
};

export default TDLinkTabs;
