import PropTypes from 'prop-types';

import './Card.scss';

const Card = ({ variant = 'elevated', children, className, ...props }) => {
  return (
    <div className={`card card--${variant} ${className}`} {...props}>
      {children}
    </div>
  );
};

Card.propTypes = {
  variant: PropTypes.oneOf(['outlined', 'elevated']),
  children: PropTypes.node
};

export default Card;
