import { List, MenuItem, Popover } from '@mui/material';
import { useTranslation } from 'react-i18next';

const QuizDropdownOptions = ({
  anchorEl,
  open = false,
  onClose = () => {},
  onEditNameClick = () => {},
  onDeleteClick = () => {}
}) => {
  const { t } = useTranslation();

  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <List>
        <MenuItem
          disableRipple
          onClick={onEditNameClick}
          sx={{ fontWeight: '700' }}
        >
          {t('edit_quiz_name_button')}
        </MenuItem>
        <MenuItem
          disableRipple
          onClick={onDeleteClick}
          sx={{ fontWeight: '700', color: '#F02959' }}
        >
          {t('delete')}
        </MenuItem>
      </List>
    </Popover>
  );
};

export default QuizDropdownOptions;
