import { PropTypes } from 'prop-types';
import React from 'react';
import './Button.scss';

export const Button = ({
	color,
	disabled,
	icon,
	iconPosition,
	loading,
	onClick,
	size,
	text,
	to,
	variant,
	fullWidth,
	className,
	component,
	isAttachedOnTheLeft,
	...props
}) => {
	const Component = component || 'button';

	return (
		<Component
			className={`button button--${variant} button--${size} button--${color} ${
				fullWidth && 'button--full-width'
			} ${isAttachedOnTheLeft && 'button--attached-left'}  ${
				disabled && `button--disabled`
			} ${className}`}
			disabled={disabled || loading}
			{...(to && { to })}
			{...(onClick && { onClick })}
			{...props}
		>
			{icon && (
				<span
					className={`button__icon button__icon--${iconPosition}`}
					role="img"
					aria-label={text}
				>
					{icon}
				</span>
			)}
			{text && <span className="button__text">{text}</span>}
		</Component>
	);
};

Button.propTypes = {
	color: PropTypes.oneOf([
		'primary',
		'secondary',
		'error',
		'success',
		'warning',
		'gray'
	]),
	disabled: PropTypes.bool,
	icon: PropTypes.string,
	iconPosition: PropTypes.oneOf(['left', 'right']),
	loading: PropTypes.bool,
	onClick: PropTypes.func,
	size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl']),
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	to: PropTypes.string,
	variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
	fullWidth: PropTypes.bool,
	className: PropTypes.string,
	component: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType])
};

Button.defaultProps = {
	color: 'primary',
	disabled: false,
	icon: '',
	iconPosition: 'left',
	loading: false,
	onClick: () => {},
	size: 'md',
	text: '',
	to: '',
	variant: 'contained',
	fullWidth: false,
	className: '',
	component: 'button'
};

export default Button;
