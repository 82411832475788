import { fetchWithToken } from '../helpers/fetcher';

export const getOrganization = async () => {
  let response = await fetchWithToken({
    method: 'GET',
    path: 'organization'
  });

  return response.data;
};
