import React from 'react';
import './gameLoading.scss';

const GameLoading = () => {
  return (
    <div className='game-loading'>
      <div className='loader'></div>
    </div>
  );
};

export default GameLoading;
