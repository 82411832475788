import { useFeatureFlagEnabled } from 'posthog-js/react';
import PropTypes from 'prop-types';

export const useFeatureFlag = ({ flag }) => {
  const isProductionLocalStorage = parseInt(
    localStorage.getItem('bb_is_production')
  );

  const shouldUseFlag =
    isNaN(isProductionLocalStorage) || isProductionLocalStorage;

  const isFlagEnabled = useFeatureFlagEnabled(flag);

  return {
    isFlagEnabled: shouldUseFlag ? isFlagEnabled : true
  };
};

useFeatureFlag.propTypes = {
  flag: PropTypes.string.isRequired
};

useFeatureFlag.defaultProps = {
  flag: ''
};
