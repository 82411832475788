export const ACTIVITY_ACTIONS = {
  SET_ACTIVITY_TYPE: 'SET_ACTIVITY_TYPE',
  SET_ACTIVITY: 'SET_ACTIVITY',
  SET_ACTIVITY_STORE: 'SET_ACTIVITY_STORE',

  INITIALIZE_ACTIVITY: 'INITIALIZE_ACTIVITY',
  SET_IS_CHECK_BUTTON_ENABLED: 'SET_IS_CHECK_BUTTON_ENABLED',
  SET_IS_ANSWER_CORRECT: 'SET_IS_ANSWER_CORRECT',
  SET_IS_SOLUTION_SHOWED: 'SET_IS_SOLUTION_SHOWED',
  SET_IS_HINT_USED: 'SET_IS_HINT_USED',
  SET_IS_DISABLED_ACTIVITY: 'SET_IS_DISABLED_ACTIVITY',

  SET_IS_SCAFFOLD: 'SET_IS_SCAFFOLD',
  SET_SCAFFOLD_CURRENT_QUESTION: 'SET_SCAFFOLD_CURRENT_QUESTION',
  SET_SCAFFOLD_NEXT_FUNCTION: 'SET_SCAFFOLD_NEXT_FUNCTION',
  SET_SCAFFOLD_IS_COMPLETED: 'SET_SCAFFOLD_IS_COMPLETED',

  SET_DEMO_MODE: 'SET_DEMO_MODE'
}

export const ACTIVITY_TYPES = {
  PRACTICE: 'PRACTICE',
  PREVIEW: 'PREVIEW',
  QUIZ: 'QUIZ'
}

export const testActivity = {
  title: '',
  type: 'mcq',
  data: {
    type: 'mcq',
    options: [
      {
        label: 'El hombre del sombrero es el 1.º.',
        value: 0,
        feedback: ''
      },
      {
        label: 'La mujer del bolso marrón es la 1.ª.',
        value: 1,
        feedback: '<p>La mujer con el bolso marrón está en la 2.ª posición.</p>'
      },
      {
        label: 'La mujer del vestido azul es la 2.ª.',
        value: 2,
        feedback: '<p>La mujer con el vestido azul está en la 4.ª posición.</p>'
      }
    ],
    stimulus:
      '<p>¿Qué puesto tienen estas personas en la cola? Selecciona la afirmación correcta.</p><div style="display:flex; justify-content:center;"><img src="https://blueberry-assets.oneclick.es/M2_NyO_15a_2.svg" width="600"></img></div>',
    shuffle_options: true,
    validation: {
      scoring_type: 'exactMatch',
      immediateFeedback: false,
      alt_responses: [],
      valid_response: {
        score: 1,
        value: ['0']
      }
    },
    ui_style: {
      type: 'Multiple choice – standard',
      columns: 1,
      labelType: '',
      customClass: '',
      inlineFeedback: false
    },
    isEvaluable: true,
    attempts: {
      attempts: 0
    },
    feedback: {
      successMessage: '',
      errorMessages: [
        '<p>Los números ordinales indican la posición que ocupa algo de forma ordenada.</p>'
      ],
      responseMessages: [],
      withFeedbackPanel: true,
      config: {
        visibility: true,
        sequence: 'stack',
        hasSpecificFeedback: true
      }
    },
    showCheckIcon: true,
    hints: [
      'Los números ordinales indican la posición que ocupa algo de forma ordenada.'
    ]
  },
  metadata: {
    name: 'Multiple choice – standard'
  },
  reference: '74846249-81ca-47a2-9b5b-23aebd3d1eda'
}

export const testScaffold = {
  id: '6d378982-ff1c-411d-a1bb-3d50983c4c5f',
  type: 'scaffold',
  questions: [
    {
      title: '',
      type: 'clozeformula',
      metadata: {
        name: 'Cloze math'
      },
      data: {
        type: 'clozeformula',
        stimulus:
          '<p>Em uma feira de livros ficaram sem vender 90 livros de viagem, 43 de contos e 46 de culinária. Quantos livros ao todo não foram vendidos?</p>',
        template: '<p>Não foram vendidos {{response}} livros.</p>',
        validation: {
          scoring_type: 'exactMatch',
          immediateFeedback: false,
          alt_responses: [],
          valid_response: {
            score: 1,
            value: [
              {
                method: 'equivLiteral',
                options: {
                  decimalPlaces: 3
                },
                feedback: '',
                value: '179'
              }
            ]
          }
        },
        feedback: {
          successMessage: '',
          errorMessages: [],
          responseMessages: [],
          withFeedbackPanel: true,
          config: {
            visibility: true,
            sequence: 'stack',
            hasSpecificFeedback: true
          }
        },
        isEvaluable: true,
        attempts: {
          attempts: 0
        },
        ui_style: {
          type: '',
          customClass: '',
          keyboard: {
            type: 'NUMERICAL',
            isFloating: true
          }
        },
        responses: [
          {
            feedback: ''
          }
        ],
        hints: [],
        feedback_responses: [
          {
            feedback: ''
          }
        ]
      },
      reference: '813ed74c-78cf-4a6c-863a-6f72b0424760'
    },
    {
      title: '',
      type: 'clozedropdown',
      metadata: {
        name: 'Cloze with drop down'
      },
      data: {
        possible_responses: [
          ['12', '9'],
          ['2', '1', 'nenhum']
        ],
        response_container: {
          pointer: 'left'
        },
        stimulus:
          '<p>Complete a frase a seguir para adicionar as unidades.</p><div style="display:flex; justify-content:space-evenly;"><div class="lemo-fixed-to-responsive" style="max-width: 100px;max-height: 80px;position: relative;width: 100%;display: inline-block;"><img src="https://blueberry-assets.oneclick.es/transparente2.png" alt="" tabindex="0"></img><div class="lemo-graphie-container" style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;"><div class="lemo-graphie" style="position: relative; width: 100%; height: 100%;"><span class="lemo-graphie-label" style="position: absolute; left: 20%; top: 20%;">+</span><span class="lemo-graphie-label" style="position: absolute; right: 30%; top: 0%;">9<span style="color: #E3360C">0</span></span><span class="lemo-graphie-label" style="position: absolute; right: 30%; top: 21%;">4<span style="color: #E3360C">3</span></span><span class="lemo-graphie-label" style="position: absolute; right: 30%; top: 42%;">4<span style="color: #E3360C">6</span></span><span class="lemo-graphie-label" style="position: absolute; right: 30%; top: 70%;">...</span><span class="lemo-graphie-label" style="position: absolute; left: 25%; top: 54%;"><span style="letter-spacing: -1px">———</span></span></div></div></div></div>',
        template:
          '<p>0 + 3 + 6 é {{response}}.</p><p>Então você transporta {{response}}.</p>',
        type: 'clozedropdown',
        case_sensitive: true,
        validation: {
          scoring_type: 'exactMatch',
          immediateFeedback: false,
          alt_responses: [],
          valid_response: {
            score: 1,
            value: ['9', 'nenhum']
          }
        },
        title: '',
        isEvaluable: true,
        attempts: {
          attempts: 0
        },
        feedback: {
          successMessage: '',
          errorMessages: [],
          responseMessages: [],
          withFeedbackPanel: true,
          config: {
            visibility: true,
            sequence: 'stack',
            hasSpecificFeedback: true
          }
        },
        ui_style: {
          type: '',
          customClass: ''
        },
        hints: [],
        feedback_responses: [
          {
            feedback: ''
          },
          {
            feedback: ''
          }
        ]
      },
      reference: '589a1962-6453-4c60-b686-0349c06bfb6a'
    },
    {
      title: '',
      type: 'clozedropdown',
      metadata: {
        name: 'Cloze with drop down'
      },
      data: {
        possible_responses: [['16', '17']],
        response_container: {
          pointer: 'left'
        },
        stimulus:
          '<p>Agora, escolha uma das opções para adicionar as dezenas.</p><div style="display:flex; justify-content:space-evenly;"><div class="lemo-fixed-to-responsive" style="max-width: 100px;max-height: 80px;position: relative;width: 100%;display: inline-block;"><img src="https://blueberry-assets.oneclick.es/transparente2.png" alt="" tabindex="0"></img><div class="lemo-graphie-container" style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;"><div class="lemo-graphie" style="position: relative; width: 100%; height: 100%;"><span class="lemo-graphie-label" style="position: absolute; left: 20%; top: 20%;">+</span><span class="lemo-graphie-label" style="position: absolute; right: 30%; top: 0%;"><span style="color: #E3360C">9</span>0</span><span class="lemo-graphie-label" style="position: absolute; right: 30%; top: 21%;"><span style="color: #E3360C">4</span>3</span><span class="lemo-graphie-label" style="position: absolute; right: 30%; top: 42%;"><span style="color: #E3360C">4</span>6</span><span class="lemo-graphie-label" style="position: absolute; right: 30%; top: 70%;">...9</span><span class="lemo-graphie-label" style="position: absolute; left: 25%; top: 54%;"><span style="letter-spacing: -1px">———</span></span></div></div></div></div>',
        template: '<p>9 + 4 + 4 é {{response}}.</p>',
        type: 'clozedropdown',
        case_sensitive: true,
        validation: {
          scoring_type: 'exactMatch',
          immediateFeedback: false,
          alt_responses: [],
          valid_response: {
            score: 1,
            value: ['17']
          }
        },
        title: '',
        isEvaluable: true,
        attempts: {
          attempts: 0
        },
        feedback: {
          successMessage: '',
          errorMessages: [],
          responseMessages: [],
          withFeedbackPanel: true,
          config: {
            visibility: true,
            sequence: 'stack',
            hasSpecificFeedback: true
          }
        },
        ui_style: {
          type: '',
          customClass: ''
        },
        hints: [],
        feedback_responses: [
          {
            feedback: ''
          }
        ]
      },
      reference: '57c5cc94-b683-4314-b24f-db5315d39262'
    }
  ]
}

export const testData = {
  phase: 'Evocar',
  node_department: 'Geometría',
  node_name: 'Polígonos',
  learning_objective_name: 'Lados del polígono'
}
