import PropTypes from 'prop-types';
import React from 'react';
import { flexRender } from '@tanstack/react-table';
import BBTooltip from '../../../atoms/tooltip/BBTooltip';

function TableHead({ header }) {
  let tableHead = (
    <th
      colSpan={header?.colSpan}
      className={`${header.column.id === 'isSelected' ? 'is-selectable' : ''}`}
    >
      {header.isPlaceholder ? null : (
        <div
          {...{
            className: `${header.column.getCanSort() ? 'is-sortable' : ''}`,
            onClick: (e) => {
              if (header.column.getCanSort()) {
                header.column.toggleSorting(
                  header.column.getIsSorted() === 'asc',
                  e.shiftKey
                );
              }
            }
          }}
        >
          {flexRender(header.column.columnDef.header, header.getContext())}
          {
            {
              asc: ' 🔼',
              desc: ' 🔽'
            }[header.column.getIsSorted()]
          }
        </div>
      )}
    </th>
  );

  const headerTooltip = header.getContext().column.columnDef.headerTooltip;

  if (headerTooltip) {
    tableHead = <BBTooltip text={headerTooltip}>{tableHead}</BBTooltip>;
  }

  return tableHead;
}

TableHead.propTypes = {
  column: PropTypes.object
};

export default TableHead;
