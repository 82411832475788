import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  CircularProgress,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import YearSelector from '../yearSelector/YearSelector';
import './listUnits.scss';

const ListUnits = ({
  isLoading,
  totalUnits,
  onSelectUnit,
  selectedUnit,
  unitsAndLessons,
  educationYearName,
  onChangeLanguage,
  programs,
  onChangeProgram,
  selectedProgram,
  quizLanguage
}) => {
  const { t } = useTranslation();

  return (
    <div className='list-units'>
      <List>
        <div className='list-units__selectors'>
          <div className='list-units__selectors-title'>
            <Typography variant='caption'>
              {t('language_and_educational_level')}
            </Typography>
          </div>
          <div className='list-units__selectors-selects'>
            <div className='list-units__selectors-educational-level'>
              {!selectedProgram?.program_guid ? (
                <div>
                  <CircularProgress style={{ height: '20px', width: '20px' }} />
                </div>
              ) : (
                <YearSelector
                  programs={programs}
                  selectedProgram={selectedProgram}
                  onChangeProgram={onChangeProgram}
                />
              )}
            </div>
            <div className='list-units__selectors-language'>
              {quizLanguage && (
                <Select
                  onChange={({ target: { value } }) => onChangeLanguage(value)}
                  placeholder='Language'
                  defaultValue={quizLanguage}
                  IconComponent={KeyboardArrowDownIcon}
                  fullWidth
                >
                  <MenuItem value='es'>{t('Spanish')}</MenuItem>
                  <MenuItem value='en'>{t('English')}</MenuItem>
                  <MenuItem value='pt'>{t('Portuguese')}</MenuItem>
                </Select>
              )}
            </div>
          </div>
        </div>

        <div className='list-units__title'>
          <Typography variant='caption'>{`${totalUnits} ${t(
            'topics'
          )}`}</Typography>
        </div>

        {isLoading && (
          <div className='list-units__unit'>
            <ListItemButton className={`list-units__unit-button`} disabled>
              <ListItemText primary={<CircularProgress />} />
            </ListItemButton>
          </div>
        )}

        {!isLoading &&
          unitsAndLessons.map((unit) => (
            <div key={unit.guid} className='list-units__unit'>
              <ListItemButton
                className={`list-units__unit-button ${
                  selectedUnit?.guid === unit?.guid ? 'is-selected' : ''
                }`}
                onClick={() => onSelectUnit(unit)}
              >
                <ListItemText
                  disableTypography={true}
                  className='list-units__unit-button--text'
                  primary={unit.name}
                />
              </ListItemButton>
            </div>
          ))}
      </List>
    </div>
  );
};

export default ListUnits;
