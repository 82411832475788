import React, { useEffect } from 'react'
import {
  observer,
  Scaffold,
  ScaffoldSeparator,
  useScaffoldApi
} from 'react-lemonade-editor'
import { useActivityContext } from '../../../context/ActivityContext/useActivityContext'

function ScaffoldContainer({
  currentExercise,
  handleUserChange,
  autoFocus,
  disabled
}) {
  const { setScaffoldCurrentQuestion, setScaffoldNext } = useActivityContext()
  const { scaffoldApi, next, currentQuestion } = useScaffoldApi()

  useEffect(() => {
    setScaffoldCurrentQuestion(currentQuestion)
  }, [currentQuestion])

  useEffect(() => {
    setScaffoldNext(next)
  }, [scaffoldApi, next])

  return (
    <Scaffold initialStep={0} data={currentExercise} api={scaffoldApi}>
      <RenderScaffold
        handleUserChange={handleUserChange}
        autoFocus={autoFocus}
        disabled={disabled}
      />
    </Scaffold>
  )
}

const RenderScaffold = ({
  questionApi,
  Question,
  currentQuestion,
  totalQuestions,
  handleUserChange,
  autoFocus,
  disabled
}) => {
  const { setActivityStore } = useActivityContext()

  useEffect(() => {
    setActivityStore(questionApi)
  }, [questionApi])

  return (
    <>
      <ScaffoldSeparator
        questionIdx={currentQuestion.index}
        totalQuestions={totalQuestions}
      />
      <Question
        changed={handleUserChange}
        autoFocus={autoFocus}
        disabled={disabled}
      />
    </>
  )
}

export default observer(ScaffoldContainer)
