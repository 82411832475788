import { useEffect, useState } from 'react';
import { formatNumericDateWithTime } from '../../../../../utils/helpers/dateFormatters';

const useViewActivity = (t, attempt) => {
  const [showActivity, setShowActivity] = useState(false);
  const [htmlNameActivity, setHtmlNameActivity] = useState(null);
  const [timeActivity, setTimeActivity] = useState(null);
  const [dateAttempt, setDateAttempt] = useState(null);
  const [totalTimeOfActivityInSeconds, setTotalTimeOfActivityInSeconds] =
    useState(0);

  const parseAttemptData = () => {
    // Primero, parseamos el HTML
    const parser = new DOMParser();
    const stimulusOfAttempt = attempt?.question?.data?.stimulus || '';
    const htmlDoc = parser.parseFromString(stimulusOfAttempt, 'text/html');

    // Luego, seleccionamos el primer elemento <p>
    const firstParagraph = htmlDoc.querySelector('p');

    // En este punto, podríamos extraer solo el texto del párrafo con firstParagraph.textContent,
    // pero ya que parece que quieres el HTML, usamos innerHTML
    const firstParagraphHTML = firstParagraph?.innerHTML || stimulusOfAttempt;

    setHtmlNameActivity(firstParagraphHTML);

    const timeActivityObj = convertSecondsToMinutes(
      attempt?.elapsedTimeInSeconds
    );

    setTotalTimeOfActivityInSeconds(attempt?.elapsedTimeInSeconds);

    setTimeActivity(timeActivityObj);

    const date = new Date(attempt?.start_at);
    const formattedDateTime = formatNumericDateWithTime(date);

    setDateAttempt(formattedDateTime);
  };

  useEffect(() => {
    parseAttemptData();
  }, []);

  const convertSecondsToMinutes = (seconds = 0) => {
    const totalSeconds = Math.floor(seconds);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const remainingSeconds = totalSeconds % 60;

    return {
      hours: hours,
      minutes: minutes,
      seconds: remainingSeconds
    };
  };

  return {
    showActivity,
    setShowActivity,
    htmlNameActivity,
    timeActivity,
    dateAttempt,
    totalTimeOfActivityInSeconds
  };
};

export default useViewActivity;
