import QuadrantDescription from './QuadrantDescription';
import ScatterPlot from './ScatterPlot';
import XAxis from './XAxis';
import YAxis from './YAxis';

import { useTranslation } from 'react-i18next';
import AxisLabel from './AxisLabel';
import './xRayChart.scss';

const XRayChart = ({
  data,
  progressRange,
  effortRange,
  onClickStudent,
  onClickStudentDetail
}) => {
  const { t } = useTranslation();

  return (
    <div className='x-ray-chart__container'>
      <QuadrantDescription
        position='top-right'
        label={t('deserve_praise')}
        description={t('deserve_praise_description')}
      />
      <QuadrantDescription
        position='top-left'
        label={t('need_commitment')}
        description={t('need_commitment_description')}
      />
      <QuadrantDescription
        position='bottom-right'
        label={t('need_help')}
        description={t('need_help_description')}
      />
      <QuadrantDescription
        position='bottom-left'
        label={t('need_motivation')}
        description={t('need_motivation_description')}
      />

      <XAxis
        leftLabel={<AxisLabel iconName={'minus'} label={t('effort')} />}
        rightLabel={<AxisLabel iconName={'plus'} label={t('effort')} />}
      />
      <YAxis
        topLabel={<AxisLabel iconName={'plus'} label={t('progress')} />}
        bottomLabel={<AxisLabel iconName={'minus'} label={t('progress')} />}
      />

      <ScatterPlot
        items={data}
        progressRange={progressRange}
        effortRange={effortRange}
        onClickStudent={onClickStudent}
        onClickStudentDetail={onClickStudentDetail}
      />
    </div>
  );
};

export default XRayChart;
