import React from 'react'
import { ReactComponent as SvgFire } from '../../../assets/icons/fire.svg'
import './feedbackButton.scss'

/*
  icon         -> ReactComponent Icono del botón (puede tener texto también)
  action       -> function       Acción a realizar
  disabled     -> boolean        Deshabilita el botón  
*/
const FeedbackButton = ({ action, disabled, buttonText }) => {
  const handleClick = () => {
    if (!disabled) {
      if (action) {
        action()
      }
    }
  }

  return (
    <div
      className={
        'feedback-button ' +
        (buttonText ? ' with-text' : '') +
        (disabled ? ' disabled' : '')
      }
      onClick={handleClick}
    >
      <div>
        <SvgFire />
      </div>
      {buttonText && <div>{buttonText}</div>}
    </div>
  )
}

export default FeedbackButton
