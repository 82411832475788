import { useContext, useEffect, useState } from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CellStatusChip from '../../../components/table/cell/cellTypes/CellStatusChip';
import CellWithAvatarAndName from '../../../components/table/cell/cellTypes/CellWithAvatarAndName';
import { CourseContext } from '../../../utils/contexts/CourseContext';
import { getCourseUnitByStudentWithStatus } from '../../../utils/crud/content';
import useUserSettings from '../../../utils/hooks/useUserSettings';

const useUnitDetails = () => {
  const { lessonGuid } = useParams();
  const columnHelper = createColumnHelper();
  const { t } = useTranslation();
  const { selectedCourse } = useContext(CourseContext);

  const [isLoading, setIsLoading] = useState(true);
  const [students, setStudents] = useState({});
  const [knowledgeComponents, setKnowledgeComponents] = useState([]);
  const [learningObjectives, setLearningObjectives] = useState([]);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [lesson, setLesson] = useState({});
  const { getPreviewLanguageSettings } = useUserSettings();

  const getStudentsForLesson = async () => {
    setIsLoading(true);

    const studentResponse = await getCourseUnitByStudentWithStatus({
      courseGuid: selectedCourse.guid,
      lessonGuid,
      lang: getPreviewLanguageSettings()
    });

    setData(
      studentResponse.students.map((student) => ({
        firstName: student.firstname,
        lastName: student.lastname,
        avatar: student.avatar,
        guid: student.studentGuid,
        ...student.kcs
          .map((kc) => ({
            [kc.kcName]: kc.status,
            ['data of ' + kc.kcName]: kc
          }))
          .reduce((prev, curr) => ({ ...prev, ...curr }), {})
      }))
    );

    setColumns([
      columnHelper.accessor('student', {
        header: t('Student'),
        id: 'student',
        enableSorting: true,
        sortingFn: 'alphanumeric',
        cell: (info) => (
          <CellWithAvatarAndName
            firstName={info.row.original.firstName}
            lastName={info.row.original.lastName}
          />
        )
      }),
      ...studentResponse.students[0].kcs.map((kc) =>
        columnHelper.accessor(kc.kcName, {
          header: kc.kcName,
          id: kc.kcName,
          enableSorting: true,
          sortingFn: 'cellWithStatus',
          cell: (info) => (
            <CellStatusChip
              status={info.row.original[kc.kcName]}
              LOData={info.row.original['data of ' + kc.kcName].los}
            />
          )
        })
      )
    ]);

    setLesson({
      lessonGuid,
      lessonName: studentResponse.lessonName,
      kcs: studentResponse.students[0]?.kcs[0]?.kcName
    });

    setIsLoading(false);
  };

  function identifyLang(testLang) {
    const storedLanguage = window.localStorage.getItem('bb_custom_language');
    if (storedLanguage && storedLanguage !== 'default') {
      return storedLanguage;
    }

    const testLangCode = testLang.split('-')[0].toLowerCase(); // Debería ser lowercase por defecto, pero no cuesta prevenir

    switch (testLangCode) {
      case 'es':
      case 'ca':
      case 'eu':
      case 'gl':
        return 'es';

      case 'pt':
        return 'pt';

      default:
        return 'en';
    }
  }

  useEffect(() => {
    if (!lessonGuid || !selectedCourse?.guid) return;
    getStudentsForLesson();
  }, [lessonGuid, selectedCourse?.guid]);

  return {
    students,
    knowledgeComponents,
    learningObjectives,
    lesson,
    isLoading,
    data,
    columns
  };
};

export default useUnitDetails;
