import katex from 'katex';
import 'katex/dist/katex.min.css';

const MathRenderer = ({ htmlContent }) => {
  const cleanLatexExpression = (latex) => {
    // Elimina los delimitadores \(\) y \[\]
    return latex
      .replace(/\\\(/g, '') // Elimina \(
      .replace(/\\\)/g, '') // Elimina \)
      .replace(/\\\[/g, '') // Elimina \[
      .replace(/\\\]/g, '') // Elimina \]
      .trim(); // Elimina espacios en blanco al principio y al final
  };

  const renderMath = (content) => {
    const element = document.createElement('div');
    element.innerHTML = content;

    const mathElements = element.querySelectorAll(
      '.fr-math, .fr-math-v2, .fr-math-v3'
    );

    mathElements.forEach((el) => {
      try {
        katex.render(cleanLatexExpression(el.textContent), el, {
          throwOnError: false,
          displayMode: el.tagName === 'DIV'
        });
      } catch (err) {
        console.error('KaTeX render error:', err);
      }
    });

    return { __html: element.innerHTML };
  };

  return <div dangerouslySetInnerHTML={renderMath(htmlContent)} />;
};

export default MathRenderer;
