import { ThemeProvider } from '@emotion/react';
import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  Container,
  Grid,
  Skeleton,
  Stack,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import QuizCard from '../../../../components/QuizCard/QuizCard';
import generateAssetURL from '../../../../utils/helpers/generateAssetURL';
import QuizConfirmDeleteDialog from '../components/QuizConfirmDeleteDialog';
import QuizConfirmPlayDialog from '../components/QuizConfirmPlayDialog';
import QuizEditNameDialog from '../components/QuizEditNameDialog';
import quizTheme from '../components/QuizTheme';
import useListQuizzes from './useListQuizzes';

const ListQuizzes = () => {
  const { t } = useTranslation();

  const {
    quizzes,
    isLoading,
    selectedQuiz,
    isEditingName,
    isDeletingQuiz,
    selectedQuizGuid,
    isDeleteDialogOpen,
    isEditNameDialogOpen,
    isPlayDialogOpen,
    onDeleteQuiz,
    onConfirmDeleteQuiz,
    onCancelDeleteQuiz,
    onPlayQuiz,
    onConfirmPlayQuiz,
    onCancelPlayQuiz,
    onEditName,
    onConfirmEditName,
    onCancelEditName
  } = useListQuizzes();

  const noQuizzesImage = generateAssetURL('avatar_love.png');

  if (isLoading) {
    return (
      <ThemeProvider theme={quizTheme}>
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
              >
                <Skeleton variant='text' width={200} height={60} />
                <Skeleton variant='text' width={200} height={60} />
              </Stack>
            </Grid>
            {new Array(6).fill(0).map((_, index) => (
              <Grid item sm={6} lg={4} xs={12} key={index}>
                <Skeleton variant='rectangular' height={300} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </ThemeProvider>
    );
  }

  if (!quizzes || !quizzes.length) {
    return (
      <ThemeProvider theme={quizTheme}>
        <Container maxWidth={false}>
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            columns={1}
          >
            <Grid item container mt='60px' justifyContent='center'>
              <img src={noQuizzesImage} alt={t('no_quizzes')} width={200} />
            </Grid>

            <Grid item container mt='24px' mb='48px' justifyContent='center'>
              <Typography variant='h3' textAlign='center'>
                {t('no_quizzes')}
              </Typography>
            </Grid>

            <Grid item container justifyContent='center'>
              <Button
                variant='contained'
                LinkComponent={Link}
                to='/quizzes/create'
                startIcon={<AddIcon />}
                disableElevation
                size='lg'
              >
                {t('New quiz')}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={quizTheme}>
      <div>
        <Container maxWidth={false}>
          <Grid container spacing={3} columns={6}>
            <Grid item xs={6} mb='16px' mt='16px'>
              <Grid container spacing={3} justifyContent='space-between'>
                <Grid item>
                  <Typography variant='subtitle1'>
                    {`${quizzes.length} ${t('Quizzes_created', {
                      count: quizzes.length
                    })}`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    LinkComponent={Link}
                    to='/quizzes/create'
                    startIcon={<AddIcon />}
                    disableElevation
                    size='lg'
                  >
                    {t('New quiz')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            {quizzes.map((quiz) => (
              <Grid item sm={6} md={3} lg={2} key={quiz.guid}>
                <QuizCard
                  guid={quiz?.guid}
                  name={quiz?.name}
                  lang={quiz?.lang}
                  numberOfActivities={quiz?.numberOfActivities}
                  onDeleteQuiz={onDeleteQuiz}
                  isDeletingQuiz={isDeletingQuiz}
                  selectedQuizGuid={selectedQuizGuid}
                  onEditName={onEditName}
                  hasResults={quiz?.hasResults}
                  onPlayQuiz={onPlayQuiz}
                />
              </Grid>
            ))}
          </Grid>
        </Container>

        <QuizEditNameDialog
          isOpen={isEditNameDialogOpen}
          onCancel={onCancelEditName}
          onSave={onConfirmEditName}
          quizGuid={selectedQuizGuid}
          initialName={selectedQuiz?.name}
        />

        <QuizConfirmDeleteDialog
          isOpen={isDeleteDialogOpen}
          onCancel={onCancelDeleteQuiz}
          onDelete={onConfirmDeleteQuiz}
          title={t('delete_quiz_dialog_title')}
          description={t('delete_quiz_dialog_description')}
        />

        <QuizConfirmPlayDialog
          isOpen={isPlayDialogOpen}
          onCancel={onCancelPlayQuiz}
          onPlay={onConfirmPlayQuiz}
          title={t('play_quiz_dialog_title')}
          description={t('play_quiz_dialog_description')}
        />
      </div>
    </ThemeProvider>
  );
};

export default ListQuizzes;
