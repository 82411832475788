import React from 'react';
import './QuizResults.scss';
import HeaderTabs from '../../../../../../components/headerTabs/HeaderTabs';
import useQuizResults from './useQuizResults';
import QuizResultsTable from '../quizResultsTable/QuizResultsTable';
import { CircularProgress } from '@mui/material';
import DialogDetailedView from '../dialogDetailedView/DialogDetailedView';

const QuizResults = ({ questions, lastGameGuid, quizName, startsWithValuesStandards, standardsByProgram}) => {
  const {
    TABS,
    VIEWS,
    selectedTab,
    studentsResults,
    activitiesResults,
    topicsResults,
    standardsResults,
    dialogTabs,
    loading,
    openDetailedView,
    dialogViewDetailTitle,
    dialogSelectedTab,
    dialogSelectedItem,
    educationYear,
    onChangeDialogSelectedTab,
    onChangeTab,
    onOpenDetailedView,
    onCancelDetailedView
  } = useQuizResults({
    quizName,
    questions,
    lastGameGuid,
    startsWithValuesStandards,
    standardsByProgram
  });
  return (
    <div className='quiz-results'>
      <div className='quiz-results__container'>
        <div className='quiz-results__header'>
          <HeaderTabs
            tabs={TABS}
            selectedTab={selectedTab}
            onChangeTab={onChangeTab}
            type={'pill'}
          />
        </div>
        {loading ? (
          <div className='quiz-results__loader'>
            <CircularProgress />
          </div>
        ) : (
          <div className='quiz-results__content'>
            {selectedTab === VIEWS.STUDENTS && (
              <QuizResultsTable
                results={studentsResults}
                onOpenDetailedView={onOpenDetailedView}
              />
            )}
            {selectedTab === VIEWS.ACTIVITIES && (
              <QuizResultsTable
                results={activitiesResults}
                onOpenDetailedView={onOpenDetailedView}
              />
            )}
            {selectedTab === VIEWS.TOPICS && (
              <QuizResultsTable
                results={topicsResults}
                onOpenDetailedView={onOpenDetailedView}
              />
            )}
            {selectedTab === VIEWS.BNCC && (
              <QuizResultsTable
                results={standardsResults}
                onOpenDetailedView={onOpenDetailedView}
              />
            )}
          </div>
        )}
        <DialogDetailedView
          isOpen={openDetailedView}
          onCancelDialog={onCancelDetailedView}
          title={dialogViewDetailTitle}
          tabs={dialogTabs}
          onChangeTab={onChangeDialogSelectedTab}
          dialogSelectedTab={dialogSelectedTab}
          selectedItem={dialogSelectedItem}
          educationYear={educationYear}
          typeView={selectedTab}
          types={VIEWS}
          startsWithValuesStandards={startsWithValuesStandards}
        />
      </div>
    </div>
  );
};

export default QuizResults;
