import { formatNumericDateWithTime } from './helpers/dateFormatters';
import { localStorageKeys } from './localStorageKeys';

export const AnalysisSegment = {
  // Segment ------------------------------------------------------
  /* eslint-disable */
  SEGMENT_EVENTS: {
    Login_Completed: 'Login Completed',
    Error_Page_Viewed: 'Error Page Viewed',
    Home_Page_Viewed: 'Home Page Viewed',
    Content_Page_Viewed: 'Content Page Viewed',
    Students_Page_Viewed: 'Students Page Viewed',
    Student_Profile_Viewed: 'Student Profile Viewed',
    Student_Profile_Button_Clicked: 'Student Profile Button Clicked',
    XRay_Page_Viewed: 'XRay Page Viewed',
    XRay_Student_Detail_Viewed: 'XRay Student Detail Viewed',
    Practice_Page_Viewed: 'Practice Page Viewed'
  },
  /* eslint-enable */

  sendSegmentTrackEvent: (eventName, eventProperties) => {
    const isProductionEnvironment =
      localStorage.getItem('bb_is_production') || false;
    const user = localStorage.getItem('bb_user_whoami')
      ? JSON.parse(localStorage.getItem('bb_user_whoami'))
      : null;

    const providerLogin =
      localStorage.getItem(localStorageKeys.providerLogin) || '';

    // Obtén el ancho de la pantalla
    const width = screen.width;

    // Obtén la altura de la pantalla
    const height = screen.height;

    // formateamos la fecha de creacción del usuario
    const createDateUser = new Date(user?.created_at);
    const formattedDateTimeUser = formatNumericDateWithTime(createDateUser);

    const propsDefault = {
      user_guid: user?.guid,
      role: user?.role_name,
      username: user?.email,
      tenant: window.location.hostname,
      account_type: 'School',
      school_name:
        user?.schools && user?.schools.length > 0 ? user?.schools[0].name : '',
      education_year: '',
      is_demo: user?.is_demo,
      signup_date: formattedDateTimeUser,
      screen_resolution: `${width}x${height}`,
      provider: providerLogin,
      user_agent: navigator.userAgent
    };

    const mergedProps = {
      ...propsDefault,
      ...eventProperties
    };

    analytics.track(eventName, mergedProps);

    try {
      if (!user?.is_demo && isProductionEnvironment) {
        // eslint-disable-next-line no-undef
        // analytics.track(eventName, mergedProps);
      } else {
        console.log('Segment track [' + eventName + ']', mergedProps);
      }
    } catch (segmentError) {
      console.error(segmentError);
    }
  }
};
