import React from 'react';
import './QuizResultsTableRow.scss';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const QuizResultsTableRow = ({
  index,
  indexStyle,
  left,
  center,
  right,
  end
}) => {
  return (
    <div className='quiz-results-table-row'>
      <div className='quiz-results-table-row__left'>
        {index && (
          <div
            className={clsx(
              'quiz-results-table-row__index',
              `quiz-results-table-row__index-${indexStyle}`
            )}
          >
            {index}
          </div>
        )}
        {left && <div className='quiz-results-table-row__info'>{left}</div>}
      </div>

      {center && <div className='quiz-results-table-row__center'>{center}</div>}
      {right && <div className='quiz-results-table-row__right'>{right}</div>}
      {end && <div className='quiz-results-table-row__end'>{end}</div>}
    </div>
  );
};

QuizResultsTableRow.defaultProps = {
  indexStyle: 'default'
};

QuizResultsTableRow.propTypes = {
  indexStyle: PropTypes.oneOf(['default', 'bullet'])
};

export default QuizResultsTableRow;
