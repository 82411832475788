import { TextField, styled } from '@mui/material';

const EDInput = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: theme.shape.borderRadius * 2,
    borderColor: theme.palette.grey[500],
    borderWidth: '1px',
    '&.Mui-focused': {
      borderColor: theme.palette.grey[500]
    }
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.grey[300]
    },
    '&:hover fieldset': {
      borderColor: theme.palette.grey[500]
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.grey[500]
    },
    '&.Mui-disabled': {
      borderColor: theme.palette.grey[300],
      color: theme.palette.grey[200],
      backgroundColor: theme.palette.grey[100]
    }
  },
  '& .MuiInputBase-input': {
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
    fontSize: '0.875rem',
    lineHeight: 1.4375,
    color: theme.palette.grey[700]
  },
  '& .MuiInputBase-multiline': {
    padding: 0,
    fontSize: '0.875rem',
    lineHeight: 1.4375,
    color: theme.palette.grey[700]
  },
  '& .MuiInputPlaceholder-root': {
    color: theme.palette.grey[200]
  }
}));

export default EDInput;
