import { createContext, useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { getCourses } from '../crud/course';
import { tryJsonParse } from '../helpers/tryJsonParse';

const CourseContext = createContext({
  selectedCourse: undefined,
  availableCourses: [],
  setAvailableCourses: () => {},
  setSelectedCourse: () => {}
});

const CourseProvider = () => {
  const [selectedCourse, setSelectedCourse] = useState(undefined);
  const [availableCourses, setAvailableCourses] = useState([]);

  const initialize = async () => {
    let tempCourse = localStorage.getItem('bb_current_course');
    if (tempCourse) setSelectedCourse(tryJsonParse(tempCourse)); // TODO: si se borra la clase, puede dar problemas esto, al quedar como seleccionada una clase que no existe

    const courses = await getCourses();
    if (Array.isArray(courses)) {
      setAvailableCourses(courses);

      if (!tempCourse && courses.length > 0) {
        setSelectedCourse(courses[0]);
      }
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (selectedCourse) {
      localStorage.setItem('bb_current_course_guid', selectedCourse.guid);
      localStorage.setItem('bb_current_course', JSON.stringify(selectedCourse));
    }
  }, [selectedCourse]);

  return (
    <CourseContext.Provider
      value={{
        selectedCourse,
        setSelectedCourse,
        availableCourses,
        setAvailableCourses
      }}
    >
      <Outlet />
    </CourseContext.Provider>
  );
};

// Hook para usar este contexto, el contexto no se puede exponer directamente, para eso está el hook
const useCourse = () => {
  const { availableCourses, selectedCourse, setSelectedCourse } =
    useContext(CourseContext);

  return {
    availableCourses,
    setSelectedCourse,
    selectedCourse
  };
};

// TODO: FRAN: "no es buena práctica exponer el contexto, si el día de mañna decidimos usar otra tecnología para gestionar el estado global que hacemos?
// para eso están los hooks que es de la propia tecnología React"
export { CourseContext, CourseProvider, useCourse };
