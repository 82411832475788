import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import enTexts from './en.json'
import esTexts from './es.json'
import ptTexts from './pt.json'

const i18nBBSubmoduleInstance = i18n.createInstance()

i18nBBSubmoduleInstance
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources: {
      en: { translation: enTexts },
      es: { translation: esTexts },
      pt: { translation: ptTexts }
    },
    keySeparator: false,
    debug: false,
    interpolation: {
      escapeValue: false
    }
  })

export default i18nBBSubmoduleInstance
