import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './lobbyRotatingText.scss';

const LobbyRotatingText = () => {
  const { t } = useTranslation();

  const TIME_INTERVAL = 5000; // ms
  const TEXTS = [
    t('quiz_waiting_message_1'),
    t('quiz_waiting_message_2'),
    t('quiz_waiting_message_3')
  ];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % TEXTS.length);
    }, TIME_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return <div className='lobby-rotating-text'>{TEXTS[index]}</div>;
};

export default LobbyRotatingText;
