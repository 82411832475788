import { Avatar, AvatarGroup } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import StudentXrayPopup from './StudentXrayPopup';

const colors = [
  'rgb(29, 186, 228)',
  'rgb(204, 138, 34)',
  'rgb(88, 134, 160)',
  'rgb(0, 70, 67)',
  'rgb(206, 15, 72)',
  'rgb(56, 176, 124)',
  'rgb(145, 173, 36)',
  'rgb(240, 132, 132)',
  'rgb(120, 51, 139)',
  'rgb(119, 111, 178)'
];

const ScatterPlot = ({
  items,
  progressRange,
  effortRange,
  onClickStudent,
  onClickStudentDetail
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStudents, setSelectedStudents] = useState([]);

  // function to check the items that have the same effort and progress or within 5% of each other
  // function to join those items into a single item with a count of how many students are in that group and array of those students
  const [groupedItems, setGroupedItems] = useState([]);

  function groupCoordinatesWithStudents(coordinates) {
    const groups = {};
    for (const { x, y, student } of coordinates) {
      const key = `${x},${y}`;
      if (key in groups) {
        groups[key].students.push(student);
      } else {
        groups[key] = { x, y, students: [student] };
      }
    }
    return Object.values(groups);
  }

  useEffect(() => {
    setGroupedItems(groupCoordinatesWithStudents(items));
  }, [items]);

  const handleClickOnGroup = (event, data) => {
    if (event?.currentTarget) {
      setSelectedStudents(data);
      setAnchorEl(event.currentTarget);
      onClickStudent(data);
    } else {
      setSelectedStudents([]);
      setAnchorEl(null);
    }
  };

  return (
    <div className='x-ray-chart__scatter-plot'>
      {groupedItems.map((item, index) => {
        return (
          <div
            key={index}
            className='x-ray__scatter-plot__item'
            style={{
              position: 'absolute',
              left: `calc(${item.x * 100}% - 32px)`,
              top: `calc(${(1 - item.y) * 100}% - 32px)`
            }}
          >
            <AvatarGroup
              max={3}
              onClick={(event) => handleClickOnGroup(event, item.students)}
            >
              {item.students.map((student) => (
                <Avatar
                  key={student.person_guid}
                  sizes='xs'
                  sx={{
                    bgcolor: student.color,
                    width: 34,
                    height: 34,
                    fontSize: '0.85rem',
                    cursor: 'pointer'
                  }}
                >
                  {student.name.substring(0, 1).toUpperCase() +
                    student.lastname.substring(0, 1).toUpperCase()}
                </Avatar>
              ))}
            </AvatarGroup>
          </div>
        );
      })}

      <StudentXrayPopup
        progressRange={progressRange}
        effortRange={effortRange}
        anchorEl={anchorEl}
        selectedStudents={selectedStudents}
        onClose={handleClickOnGroup}
        onClickStudentDetail={onClickStudentDetail}
      />
    </div>
  );
};

ScatterPlot.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      effort: PropTypes.number,
      progress: PropTypes.number
      // student: PropTypes.string
    })
  )
};

ScatterPlot.defaultProps = {
  items: []
};

export default ScatterPlot;
