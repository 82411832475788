import { useEffect, useState } from 'react'

export default function useClickOnce(onClick, isOnce, delayTime = 3) {
  const [locked, setLocked] = useState(Date.now())
  const isReady = () => locked < Date.now()
  const addDelay = () => setLocked(Date.now() + delayTime * 1000)

  useEffect(() => {
    setLocked(Date.now())
  }, [])

  const handleClick = (e) => {
    if (!isOnce) {
      return onClick(e)
    }

    if (isReady()) {
      addDelay()
      return onClick(e)
    }

    return false
  }

  return handleClick
}
