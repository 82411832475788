import React from 'react';
import PropTypes from 'prop-types';
import './CellWithTwoFontWeights.scss';
import clsx from 'clsx';

const CellWithTwoFontWeights = ({ regular, bold, align, singleLine }) => {
  return (
    <div
      className={clsx(
        'cell__container cell--two-font-weights',
        `cell__container cell--two-font-weights__${align}`,
        `cell__container cell--two-font-weights__${
          singleLine ? 'single-line' : 'multiline'
        }`
      )}
    >
      <span className='cell--bold'>{bold}</span>
      <span> </span>
      <span className='cell--regular'>{regular}</span>
    </div>
  );
};

CellWithTwoFontWeights.propTypes = {
  regular: PropTypes.string,
  bold: PropTypes.string,
  align: PropTypes.oneOf(['row', 'column'])
};

export default CellWithTwoFontWeights;
