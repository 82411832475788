import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useQuizEditNameDialog from './useQuizEditNameDialog';

const QuizEditNameDialog = ({
  quizGuid = '',
  isOpen = false,
  initialName = '',
  onSave = () => {},
  onCancel = () => {}
}) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const [newName, setNewName] = useState(initialName);

  const { isLoading, onEditName } = useQuizEditNameDialog({
    quizGuid: quizGuid,
    callbackOnSuccess: onSave,
    callbackOnError: () => {}
  });

  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth='sm' fullWidth>
      <DialogContent>
        <Stack spacing={3}>
          <Stack spacing={2}>
            <Typography variant='h3' color={theme.palette.grey[200]}>
              {t('edit_quiz_name_dialog_title')}
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Typography variant='body2' color={theme.palette.grey[200]}>
              {t('edit_quiz_name_dialog_label')}
            </Typography>
            <TextField
              fullWidth
              variant='outlined'
              color='primary'
              defaultValue={initialName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </Stack>
          <Stack spacing={3} direction='row' justifyContent='flex-end'>
            <Button onClick={onCancel} variant='text' color='info'>
              {t('cancel')}
            </Button>
            <Button
              onClick={() =>
                onEditName({
                  name: newName
                })
              }
              variant='contained'
              color='info'
              disableElevation
              disabled={
                isLoading ||
                !newName ||
                newName === initialName ||
                newName.length < 3
              }
            >
              {isLoading ? <CircularProgress size={24} /> : t('save')}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

QuizEditNameDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  quizGuid: PropTypes.string.isRequired,
  initialName: PropTypes.string
};

export default QuizEditNameDialog;
