import { Avatar } from '@mui/material';
import React from 'react';
import './CellWithAvatarAndName.scss';

const CellWithAvatarAndName = ({ firstName, lastName }) => {
  return (
    <div className='cell__container cell--with-avatar-and-name'>
      <div className='cell--avatar'>
        <Avatar>
          {firstName.substring(0, 1).toUpperCase()}
          {lastName.substring(0, 1).toUpperCase()}
        </Avatar>
      </div>
      <div className='cell--name'>
        <div className='cell--first-name'>{firstName}</div>
        <div className='cell--last-name'>{lastName}</div>
      </div>
    </div>
  );
};

export default CellWithAvatarAndName;
