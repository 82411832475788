import './FullpageLayout.scss';

const FullPageLayout = ({
  left,
  title,
  subtitle,
  right,
  children,
  footerLeft,
  footerCenter,
  footerRight,
  fullWidth,
  fullScreenModal,
  type
}) => {
  return (
    <div
      className={`full-page-layout__container full-page-layout--${
        type ? 'teacher' : 'default'
      } ${
        footerLeft || footerCenter || footerRight
          ? 'full-page-layout--with-footer'
          : ''
      }${fullScreenModal ? 'full-page-layout--fullscreen-modal' : ''}`}
    >
      <div className={`header__container`}>
        <div
          className={`header__content ${
            fullWidth ? 'header__content--full-width' : ''
          }`}
        >
          <div className='header__left'>{left}</div>
          <div className='header__center'>
            <div className='header__title'>{title}</div>
            <div className='header__subtitle'>{subtitle}</div>
          </div>
          <div className='header__right'>{right}</div>
        </div>
      </div>
      <div
        className={`content__container ${
          fullWidth ? 'content__container--full-width' : ''
        }`}
      >
        <div className='content'>{children}</div>
      </div>
      {(footerLeft || footerCenter || footerRight) && (
        <div className='content__footer'>
          <div className='content__footer--left'>{footerLeft}</div>
          <div className='content__footer--center'>{footerCenter}</div>
          <div className='content__footer--right'>{footerRight}</div>
        </div>
      )}
    </div>
  );
};

export default FullPageLayout;
