import React from 'react';
import BBTooltip from '../../../../atoms/tooltip/BBTooltip';
import './CellWithTitleAndSubtitle.scss';

const CellWithTitleAndSubtitle = ({ title, subtitle, aboveTitle }) => {
  return (
    <div className='cell__container cell--title-and-subtitle'>
      {aboveTitle && (
        <div className='cell__above-title'>
          <BBTooltip placement='bottom' text={aboveTitle} arrow>
            <div>{aboveTitle}</div>
          </BBTooltip>
        </div>
      )}
      {title && (
        <div className='cell__title'>
          <BBTooltip placement='bottom' text={title} arrow>
            <div>{title}</div>
          </BBTooltip>
        </div>
      )}
      {subtitle && (
        <div className='cell__subtitle'>
          <BBTooltip placement='bottom' text={subtitle} arrow>
            <div>{subtitle}</div>
          </BBTooltip>
        </div>
      )}
    </div>
  );
};

export default CellWithTitleAndSubtitle;
