import { ThemeProvider } from '@emotion/react';
import quizTheme from '../components/QuizTheme';
import CreateQuizFooterBar from './components/createQuizFooterBar/CreateQuizFooterBar';
import CreateQuizTopBar from './components/createQuizTopBar/CreateQuizTopBar';
import ListUnits from './components/listUnits/ListUnits';
import './createQuiz.scss';
import ListLessons from './listLessons/ListLessons';
import useCreateQuiz from './useCreateQuiz';

const CreateQuiz = () => {
  const {
    quizName,
    isLoading,
    standards,
    totalUnits,
    totalLessons,
    selectedUnit,
    quizLanguage,
    lessonsToList,
    canCreateQuiz,
    selectedCourse,
    isCreatingQuiz,
    unitsAndLessons,
    selectedLessons,
    educationYearName,
    educationYear,
    setQuizName,
    onCreateQuiz,
    onSelectUnit,
    onRemoveLesson,
    onRemoveAllLessons,
    onSelectLesson,
    onChangeQuizName,
    onChangeLanguage,
    onFilterAndSearch,
    onSelectAllLessons,
    onChangeNumberOfQuestions,
    programs,
    onChangeProgram,
    selectedProgram,
    standardsByProgram
  } = useCreateQuiz();

  return (
    <ThemeProvider theme={quizTheme}>
      <div className='create-quiz'>
        <div className='create-quiz__header'>
          <CreateQuizTopBar
            standards={standards}
            quizName={quizName}
            onChangeQuizName={onChangeQuizName}
            onFilterAndSearch={onFilterAndSearch}
            onChangeNumberOfQuestions={onChangeNumberOfQuestions}
            standardsByProgram={standardsByProgram}
          />
        </div>
        <div className='create-quiz__content'>
          <div className='create-quiz__left'>
            <ListUnits
              isLoading={isLoading}
              onChangeLanguage={onChangeLanguage}
              totalUnits={totalUnits}
              totalLessons={totalLessons}
              selectedCourse={selectedCourse}
              unitsAndLessons={unitsAndLessons}
              educationYearName={educationYearName}
              onSelectUnit={onSelectUnit}
              selectedUnit={selectedUnit}
              programs={programs}
              onChangeProgram={onChangeProgram}
              selectedProgram={selectedProgram}
              quizLanguage={quizLanguage}
            />
          </div>
          <div className='create-quiz__right'>
            <ListLessons
              isLoading={isLoading}
              lessons={lessonsToList}
              onSelect={onSelectLesson}
              onSelectAll={onSelectAllLessons}
              selectedLessons={selectedLessons}
              standardsByProgram={standardsByProgram}
            />
          </div>
        </div>
        <div className='create-quiz__footer'>
          <CreateQuizFooterBar
            onCreateQuiz={onCreateQuiz}
            selectedLessons={selectedLessons}
            onRemoveLesson={onRemoveLesson}
            onRemoveAllLessons={onRemoveAllLessons}
            isCreatingQuiz={isCreatingQuiz}
            canCreateQuiz={canCreateQuiz}
            educationYearName={educationYearName}
          />
        </div>
      </div>
    </ThemeProvider>
  );
};

export default CreateQuiz;
