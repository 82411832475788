import { useEffect, useState } from 'react';
import { useCourse } from '../../../utils/contexts/CourseContext';
// import dummyJsonData from './course-insights.json';
import { getReportCourse } from '../../../utils/crud/report';

const useListReports = (t) => {
  const [report, setReport] = useState([]);
  const { selectedCourse } = useCourse();
  const [isLoading, setIsLoading] = useState(true);

  const [daysReportSelect, setdaysReportSelect] = useState([
    { value: '10', label: t('reports_last_x_days', { daysDiff: 10 }) },
    { value: '15', label: t('reports_last_x_days', { daysDiff: 15 }) },
    { value: '30', label: t('reports_last_x_days', { daysDiff: 30 }) }
  ]);

  const [selectedDayReport, setSelectedDayReport] = useState(10);

  const fetchReportCourse = async () => {
    setIsLoading(true);
    const reportResponseApi = await getReportCourse({
      courseGuid: selectedCourse?.guid,
      days: selectedDayReport
    });

    if (!reportResponseApi) {
      setIsLoading(false);
      setReport([]);
      return;
    }

    let reportResponse = calculatePercentage(reportResponseApi);

    // order by student last name
    reportResponse = {
      ...reportResponse,
      students: sortStudentsByLastName(reportResponse.students)
    };

    setReport(reportResponse);
    setIsLoading(false);
  };

  const sortStudentsByLastName = (data = []) => {
    return data.sort((a, b) => a?.lastname.localeCompare(b?.lastname));
  };

  const calculatePercentage = (data) => {
    const studentsArray = data?.students || [];

    const totalActivities = studentsArray.reduce(
      (total, student) => total + student?.number_activities,
      0
    );
    const totalStudents = studentsArray.length || 1; // Avoid division by zero
    const averageActivities = totalActivities / totalStudents;

    let studentsWithPercentage = studentsArray.map((student) => ({
      ...student,
      percentage:
        totalActivities === 0
          ? 0
          : (
              ((student?.number_activities - averageActivities) /
                averageActivities) *
              100
            ).toFixed(2)
    }));

    return { ...data, students: studentsWithPercentage };
  };

  useEffect(() => {
    if (selectedCourse) {
      fetchReportCourse();
    }
  }, [selectedCourse, selectedDayReport]);

  return {
    report,
    isLoading,
    selectedCourse,
    daysReportSelect,
    selectedDayReport,
    setSelectedDayReport
  };
};

export default useListReports;
