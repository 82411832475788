import { fetchWithToken } from '../helpers/fetcher';

export const getCourseDepartments = async ({ courseGuid, lang }) => {
  let response = await fetchWithToken({
    method: 'GET',
    path: `blueberry/teacher/${courseGuid}/thematic-units?lang=${lang}`
  });

  return response.data;
};

export const getCourseUnits = async ({ courseGuid, lang }) => {
  let response = await fetchWithToken({
    method: 'GET',
    path: `blueberry/teacher/${courseGuid}/units?lang=${lang}`
  });

  return response.data;
};

export const getCourseUnitByStudentWithStatus = async ({
  courseGuid,
  lessonGuid,
  lang
}) => {
  let response = await fetchWithToken({
    method: 'GET',
    path: `blueberry/teacher/${courseGuid}/content/unit?lessonGuid=${lessonGuid}&lang=${lang}`
  });

  return response.data;
};

export const getCourseUnitsWithStatus = async ({ courseGuid, unitGuid }) => {
  let response = await fetchWithToken({
    method: 'GET',
    path: `blueberry/teacher/${courseGuid}/content/unit${
      unitGuid ? `?unitGuid=${unitGuid}` : ''
    }`
  });

  return response.data;
};

export const getCourseStandardsWithStatus = async ({
  courseGuid,
  standardGuid
}) => {
  let response = await fetchWithToken({
    method: 'GET',
    path: `blueberry/teacher/${courseGuid}/content/standard${
      standardGuid ? `?thematicUnitGuid=${standardGuid}` : ''
    }`
  });

  return response.data;
};

export const getCourseStandardByStudentWithStatus = async ({
  courseGuid,
  standardGuid
}) => {
  let response = await fetchWithToken({
    method: 'GET',
    path: `blueberry/teacher/${courseGuid}/content/standard?standardGuid=${standardGuid}`
  });

  return response.data;
};
