import {
  getConfig,
  setConfig,
  decimalSeparatorTypes,
  thousandsSeparatorTypes
} from 'react-lemonade-editor'

export function setLemonadeDefaultConfig(mathRenderEngine) {
  setConfig({
    mathRenderEngine: mathRenderEngine,
    lang: 'es',
    individualFeedbackTooltip: false,
    showFeedback: true,
    chartsEnable: true,
    graphsHelpMarks: false,
    autoFocus: 'enabled',
    showAutoFeedback: false,
    useOrderListInMobileMode: true,
    useMatchListInMobileMode: true,
    mathFieldIcon: true,
    decimalSeparator: decimalSeparatorTypes.POINT,
    thousandsSeparator: thousandsSeparatorTypes.SPACE,
    showSampleAnswer: true
  })

  window.lemoConfig = getConfig()
}

export function setLemonadeCustomConfig(courseLemonadeSettings) {
  getConfig().setDecimalSeparator(courseLemonadeSettings.decimal_separator)
  getConfig().setThousandsSeparator(courseLemonadeSettings.thousands_separator)
}

export { decimalSeparatorTypes, thousandsSeparatorTypes }
// export function setLemonadeCustomLanguage() {
//   getConfig().setLang(getCurrentLangString())
// }
