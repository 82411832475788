import { useEffect, useRef, useState } from 'react';

export function useIconImport({ type, format }) {
  const importedIconRef = useRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setLoading(true);
    // dynamically import the mentioned svg icon name in props
    const importSvgIcon = async () => {
      // please make sure all your svg icons are placed in the same directory
      // if we want that part to be configurable then instead of type we will send iconPath as prop
      try {
        importedIconRef.current = (await import(`./svg/${format}/${type}.svg`)).ReactComponent; // svgr provides ReactComponent for given svg path
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    importSvgIcon();
  }, [type, format]);

  return { error, loading, SvgIcon: importedIconRef.current };
}
