export const data = [
	{
		name: 'Localização espacial',
		mastered: 1,
		inProgress: 0,
		needHelp: 0,
		needMotivation: 2
	},
	{
		name: 'Números naturais de um algarismo',
		mastered: 2,
		inProgress: 3,
		needHelp: 4,
		needMotivation: 5
	},
	{
		name: 'Agrupamentos e comparações',
		mastered: 0,
		inProgress: 0,
		needHelp: 0,
		needMotivation: 0
	},
	{
		name: 'Unidades e dezenas',
		mastered: 0,
		inProgress: 0,
		needHelp: 0,
		needMotivation: 0
	},
	{
		name: 'Adição de números naturais',
		mastered: 1,
		inProgress: 0,
		needHelp: 0,
		needMotivation: 0
	},
	{
		name: 'Subtração de números naturais',
		mastered: 0,
		inProgress: 0,
		needHelp: 0,
		needMotivation: 0
	},
	{
		name: 'Classificações e sequências',
		mastered: 0,
		inProgress: 0,
		needHelp: 0,
		needMotivation: 0
	},
	{
		name: 'Polígonos e poliedros',
		mastered: 0,
		inProgress: 0,
		needHelp: 0,
		needMotivation: 0
	},
	{
		name: 'Medidas de comprimento, massa e capacidade',
		mastered: 0,
		inProgress: 0,
		needHelp: 0,
		needMotivation: 0
	},
	{
		name: 'O dinheiro e a centena',
		mastered: 0,
		inProgress: 0,
		needHelp: 0,
		needMotivation: 0
	},
	{
		name: 'Medidas de tempo',
		mastered: 0,
		inProgress: 0,
		needHelp: 0,
		needMotivation: 0
	},
	{
		name: 'Estatística e probabilidade',
		mastered: 0,
		inProgress: 0,
		needHelp: 0,
		needMotivation: 0
	}
];
