import {
  Checkbox,
  CircularProgress,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import generateAssetURL from '../../../../../utils/helpers/generateAssetURL';
import LessonCard from '../components/lessonCard/LessonCard';
import './listLessons.scss';

const ListLessons = ({
  onSelect,
  onSelectAll,
  lessons = [],
  selectedLessons = [],
  isLoading = false,
  variant = 'default',
  standardsByProgram
}) => {
  const [groupedLessons, setGroupedLessons] = useState([]);
  const { t } = useTranslation();
  const [startsWithValuesStandards, setStartsWithValuesStandards] = useState(
    []
  );

  const notFoundImage = generateAssetURL('avatar_not_found.png');

  useEffect(() => {
    // group by unit name
    const grouped = lessons?.reduce((acc, lesson) => {
      const unitName = lesson.unitName;
      if (!acc[unitName]) {
        acc[unitName] = [];
      }
      acc[unitName].push(lesson);
      return acc;
    }, {});

    const groupedArray = Object.keys(grouped).map((key) => {
      return {
        unitName: key,
        unitGuid: grouped[key][0].unitGuid,
        lessons: grouped[key]
      };
    });

    setGroupedLessons(groupedArray);
  }, [lessons]);

  useEffect(() => {
    if (standardsByProgram && standardsByProgram === 'BNCC') {
      setStartsWithValuesStandards((prevState) => [...prevState, 'EF']);
    }

    if (standardsByProgram && standardsByProgram === 'CC') {
      setStartsWithValuesStandards((prevState) => [...prevState, 'CCSS']);
    }
  }, [standardsByProgram]);

  if (isLoading) {
    return (
      <div className='list-lessons '>
        <div className='list-lessons__not-found'>
          <CircularProgress />
        </div>
      </div>
    );
  }

  if (groupedLessons.length === 0 || lessons.length === 0) {
    return (
      <div className='list-lessons'>
        <div className='list-lessons__not-found'>
          <img src={notFoundImage} alt={t('no_results_topic_search')} />
          <Typography variant='caption'>
            {t('no_results_topic_search')}
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`list-lessons ${
        variant === 'addActivity' ? 'list-lessons--add-activity' : ''
      }`}
    >
      {variant !== 'addActivity' && (
        <div className='list-lessons__header'>
          <Typography variant='caption'>
            {lessons.length} {t('Topics')}
          </Typography>

          <div className='list-lessons__select-all'>
            <Checkbox
              checked={
                groupedLessons.length > 0 &&
                groupedLessons.reduce((prev, curr) => {
                  return (
                    prev && curr.lessons.every((lesson) => lesson.isSelected)
                  );
                }, true)
              }
              onClick={onSelectAll}
            />{' '}
            <div className='list-lessons__select-all-label'>
              {t('Select all')}
            </div>
          </div>
        </div>
      )}

      {groupedLessons.map((unit) => (
        <Grid item xs={12} key={unit.unitGuid} sx={{ marginBottom: '16px' }}>
          <Stack spacing={2}>
            <Stack spacing={1}>
              {unit.lessons.length > 0 && (
                <Typography variant='h6'>{unit.unitName}</Typography>
              )}
              {unit.lessons.length > 0 && (
                <Typography variant='caption'>{`${unit.lessons.length} ${t(
                  'topic',
                  {
                    count: unit.lessons.length
                  }
                )}`}</Typography>
              )}
            </Stack>
            <Stack spacing={1}>
              {unit.lessons.map((lesson) => (
                <LessonCard
                  key={lesson.guid}
                  onSelect={onSelect}
                  lessonGuid={lesson.guid}
                  lessonName={lesson.name}
                  isSelected={lesson.isSelected}
                  numberOfActivities={lesson.numberOfActivities}
                  standards={lesson.standards.filter((standard) =>
                    startsWithValuesStandards.some((value) =>
                      standard.name.startsWith(value)
                    )
                  )}
                  variant={variant}
                />
              ))}
            </Stack>
          </Stack>
        </Grid>
      ))}
    </div>
  );
};

ListLessons.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  lessons: PropTypes.array,
  selectedLessons: PropTypes.array,
  isLoading: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'addActivity'])
};

export default ListLessons;
