import { tryJsonParse } from '../helpers/tryJsonParse';
import { localStorageKeys } from '../localStorageKeys';
import { useLocalStorage } from './useLocalStorage';

const useUserSettings = () => {
  const { getItem, setItem } = useLocalStorage();

  // Table custom configurations
  const hasTableSettings = (name) => {
    const settings = JSON.parse(getItem(localStorageKeys.userSettings));
    return settings && settings[name + 'Table'] ? true : false;
  };

  const setTableSettings = (name, value) => {
    let settings = JSON.parse(getItem(localStorageKeys.userSettings));
    if (!settings) {
      settings = {};
    }
    settings[name + 'Table'] = value;
    setItem(localStorageKeys.userSettings, JSON.stringify(settings));
  };

  const getTableSettings = (name) => {
    const settings = JSON.parse(getItem(localStorageKeys.userSettings));
    return settings && settings[name + 'Table']
      ? settings[name + 'Table']
      : undefined;
  };

  // Multilanguage configuration
  const setPreviewLanguageSettings = (language) => {
    // Retrieve the current settings from localStorage
    const currentSettings =
      JSON.parse(getItem(localStorageKeys.userSettings)) || {};

    // Update the previewLanguage property
    currentSettings.previewLanguage = language;

    // Store the updated settings back in localStorage
    setItem(localStorageKeys.userSettings, JSON.stringify(currentSettings));
  };

  const getPreviewLanguageSettings = () => {
    const fullLanguageNavigator = navigator.language || navigator.userLanguage;
    const languageNavigator = fullLanguageNavigator.split('-')[0];

    // Se añade más control para coger el idioma si existe y si no poder pasar a la siguiente propiedad que pueda tenerlo
    const userSettings = getItem(localStorageKeys.userSettings);
    const userSettingsLanguage = tryJsonParse(userSettings)?.previewLanguage;

    let languageSetting = getItem(localStorageKeys.language);
    if (
      !languageSetting ||
      languageSetting === 'null' ||
      languageSetting === 'undefined'
    ) {
      languageSetting = undefined;
    }

    const settings =
      userSettingsLanguage || languageSetting || languageNavigator || undefined;
    return settings;
  };

  return {
    setTableSettings,
    getTableSettings,
    hasTableSettings,
    setPreviewLanguageSettings,
    getPreviewLanguageSettings
  };
};

export default useUserSettings;
