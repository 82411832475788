import { fetchWithToken } from '../helpers/fetcher';

export const getXRay = async ({ courseGuid }) => {
	let response = await fetchWithToken({
		method: 'GET',
		path: `blueberry/teacher/${courseGuid}/x-ray`
	});

	return response.data;
};
