import { useEffect } from 'react';
// import usePlayQuizRanking from './usePlayQuizRanking';
import { Flipped, Flipper } from 'react-flip-toolkit';
import { useTranslation } from 'react-i18next';
import generateAssetURL from '../../../../../utils/helpers/generateAssetURL';
import ImageCup from '../assets/ranking_cup.png';
import GameQuizButton from '../components/gameQuizButton/GameQuizButton';
import GameQuizHeader from '../components/gameQuizHeader/GameQuizHeader';
import GameQuizTitle from '../components/gameQuizTitle/GameQuizTitle';
import QuestionAnswerCounter from '../components/questionAnswerCounter/QuestionAnswerCounter';
import './playQuizRanking.scss';

const PlayQuizRanking = ({
  quizGuid,
  quizName,
  currentQuestion,
  setCurrentQuestion,
  numberOfQuestions,
  gotoQuestionView,
  gotoPodium,
  playerList,
  setPlayerList,
  totalPlayers,
  totalAnswers,
  playSoundEffect,
  closeGame
}) => {
  const DEFAULT_AVATAR = generateAssetURL('default_single_avatar.png');

  const { t } = useTranslation();

  const SORTING_TIME = 1000;

  useEffect(() => {
    const timer = setTimeout(() => {
      const sortedPlayers = [...playerList].sort((a, b) => b.points - a.points);
      setPlayerList(sortedPlayers);
    }, SORTING_TIME);

    return () => clearTimeout(timer);
  }, []);

  const handleNextButton = () => {
    if (currentQuestion + 1 < numberOfQuestions) {
      setCurrentQuestion(currentQuestion + 1);
      gotoQuestionView();
      console.log('gotoQuestionView currentQuestion ----- ', currentQuestion);
      if (currentQuestion + 1 > 0) {
        playSoundEffect('openLootBoxSound5');
      }
    } else {
      gotoPodium();
    }
  };

  return (
    <div className='play-quiz-ranking'>
      <div className='play-quiz-ranking__header'>
        <GameQuizHeader
          quizGuid={quizGuid}
          closeGame={closeGame}
          leftComponent={
            <div className='play-quiz-ranking__answers-count'>
              <QuestionAnswerCounter
                totalAnswers={totalAnswers}
                totalPlayers={totalPlayers}
                isTimeFinished={true}
              />
            </div>
          }
          centerComponent={<GameQuizTitle title={quizName} />}
          rightComponent={
            <GameQuizButton
              text={
                currentQuestion + 1 < numberOfQuestions
                  ? t('quiz_next_button')
                  : t('quiz_finish_button')
              }
              onClick={handleNextButton}
            />
          }
        />
      </div>

      <div className='play-quiz-ranking__content'>
        <div className='play-quiz-ranking__ranking-wrapper'>
          <div className='play-quiz-ranking__ranking-title'>
            <img src={ImageCup} alt='RANKING' />
            RANKING
          </div>
          <Flipper
            className='play-quiz-ranking__ranking-list'
            flipKey={playerList.map((player) => player.user_guid).join(',')}
          >
            {playerList.map((player, index) => (
              <Flipped key={player.user_guid} flipId={player.user_guid}>
                <div
                  className={`ranking-list__item ${
                    player.is_online
                      ? 'ranking-list__item--enabled'
                      : 'ranking-list__item--disabled'
                  }`}
                  key={player.user_guid}
                >
                  <div className='ranking-list__position'>{index + 1}</div>
                  <div className='ranking-list__name'>{player.name}</div>
                  <div className='ranking-list__points'>
                    {`${player.points} ${t('quiz_points')}`}
                  </div>
                  <div className='ranking-list__avatar'>
                    <img
                      src={player.avatar || DEFAULT_AVATAR}
                      alt={player.name}
                    />
                  </div>
                </div>
              </Flipped>
            ))}
          </Flipper>
        </div>
      </div>
    </div>
  );
};

export default PlayQuizRanking;
