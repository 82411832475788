import React from 'react'
import useClickOnce from '../../../hooks/useClickOnce'
import './activitySimpleButton.scss'

function ActivitySimpleButton({
  onClick,
  text,
  variant,
  size,
  color,
  display,
  disabled,
  isOnce = false
}) {
  const handleClick = useClickOnce(onClick, isOnce)
  return (
    <button
      className={`activity-simple-button button-variant--${variant} button--display-${display} button--color-${color} button--size-${size} ${
        disabled ? 'disabled' : ''
      }`}
      role="button"
      onClick={handleClick}
      disabled={disabled}
    >
      <div className="activity-simple-button__wrapper">
        <span className="activity-simple-button__text">{text}</span>
      </div>
    </button>
  )
}

export default ActivitySimpleButton
