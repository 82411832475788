import React from 'react';
import Table from '../../../components/table/Table';
import FullPageLayout from '../../../layouts/dashboardFullPage/FullPageLayout';
import useUnitDetails from './useUnitDetails';
import { IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

const UnitDetails = () => {
  const { data, columns, lesson, isLoading } = useUnitDetails();

  return (
    <FullPageLayout
      type='teacher'
      left={
        <IconButton variant='transparent' onClick={() => history.back()}>
          <ArrowBack sx={{ color: 'white' }} />
        </IconButton>
      }
      title={lesson.lessonName}
      subtitle={lesson.kc}
      right={<div></div>}
    >
      <Table
        data={data}
        columns={columns}
        isLoading={isLoading}
        isFirstColumnSticky={true}
      />
    </FullPageLayout>
  );
};

export default UnitDetails;
