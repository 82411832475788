import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useProfile from './useProfile.js';

import Card from '../../../atoms/card/Card';
import Typography from '../../../atoms/typography/Typography';
import FullPageLayout from '../../../layouts/dashboardFullPage/FullPageLayout';
import ArrowBack from '@mui/icons-material/ArrowBack';

import { Button, IconButton, TextField } from '@mui/material';
import EDAutocompleteWithLabel from '../../../atoms/EDAutocomplete/EDAutocompleteWithLabel';
import EDInput from '../../../atoms/EDInput/EDInput';

import './Profile.scss';

const Profile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    userProfile,
    isLoading,
    hasUpdatesToSave,
    setUserProfile,
    user,
    LANGUAGE_OPTIONS,
    languageCodeToOption,
    handleSave
  } = useProfile();

  const bbUserLanguage = localStorage.getItem('bb_user_language');

  return (
    <div className='profile'>
      <FullPageLayout
        type='teacher'
        left={
          <IconButton onClick={() => navigate(-1)} variant='transparent'>
            <ArrowBack sx={{ color: 'white' }} />
          </IconButton>
        }
        title={t('my_profile')}
        footerRight={
          <Button
            onClick={handleSave}
            variant='contained'
            disabled={isLoading || !hasUpdatesToSave}
            className='profile__save-button'
          >
            {t('save')}
          </Button>
        }
      >
        <Card className='profile__card'>
          <div className='profile__card-title-container'>
            <Typography variant='h3'>{t('details')}</Typography>
          </div>
          <div className='profile__input-row'>
            <div className='profile__input-container'>
              <Typography className='profile__input-title'>
                {t('name')}
              </Typography>
              <TextField
                fullWidth
                disabled={isLoading}
                placeholder={t('name')}
                value={userProfile?.name || ''}
                className='profile__input-field'
                onChange={({ target: { value } }) => {
                  setUserProfile({ ...userProfile, name: value.trim() });
                }}
              />
            </div>
            <div className='profile__input-container'>
              <Typography className='profile__input-title'>
                {t('lastname')}
              </Typography>
              <TextField
                fullWidth
                disabled={isLoading}
                placeholder={t('lastname')}
                value={userProfile?.lastname || ''}
                className='profile__input-field'
                onChange={({ target: { value } }) => {
                  setUserProfile({ ...userProfile, lastname: value.trim() });
                }}
              />
            </div>
          </div>
          <div className='profile__input-row'>
            <div className='profile__input-container'>
              <Typography className='profile__input-title'>
                {t('email')}
              </Typography>
              <TextField
                fullWidth
                placeholder={t('email')}
                value={user?.email || ''}
                className='profile__input-field'
                disabled
              />
            </div>
            <div className='profile__input-container'>
              <Typography className='profile__input-title'>
                {t('school')}
              </Typography>
              <TextField
                fullWidth
                placeholder={t('school')}
                value={user?.schools[0].name || ''}
                className='profile__input-field'
                disabled
              />
            </div>
          </div>

          <div className='profile__input-row'>
            <div className='profile__input-container'>
              <Typography className='profile__input-title'>
                {t('language_label')}
              </Typography>

              <EDAutocompleteWithLabel
                forcePopupIcon
                disableClearable
                defaultValue={languageCodeToOption[bbUserLanguage]}
                options={LANGUAGE_OPTIONS}
                freeSolo
                onChange={(e, value) => {
                  setUserProfile({ ...userProfile, lang_id: value.code });
                }}
                renderInput={(params) => (
                  <EDInput
                    {...params}
                    variant='outlined'
                    placeholder={t('language_placeholder')}
                  />
                )}
              />
            </div>
          </div>
        </Card>
      </FullPageLayout>
    </div>
  );
};

export default Profile;
