import { Link, useLocation } from 'react-router-dom';
import { useFeatureFlag } from '../../utils/hooks/useFeatureFlag';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../atoms/icon';
import BBTooltip from '../../atoms/tooltip/BBTooltip';

const DashboardMenuItem = ({ name, action, href, pageKey, icon, flag }) => {
  const { t } = useTranslation();

  const { isFlagEnabled } = useFeatureFlag({ flag });
  const location = useLocation();

  const [isCurrentTab, setIsCurrentTab] = useState(false);

  const checkIsCurrentTab = (key) => {
    // key can be string or array
    if (typeof key === 'string') {
      return location.pathname.split('/').includes(key);
    } else if (Array.isArray(key)) {
      return key.some((k) => location.pathname.split('/').includes(k));
    }
  };

  useEffect(() => {
    setIsCurrentTab(checkIsCurrentTab(pageKey));
  }, [location.pathname]);

  if (flag && !isFlagEnabled) {
    return null;
  }

  if (action) {
    return (
      <div>
        <BBTooltip text={t(name)} placement='bottom'>
          <div
            className='dashboard-with-topbar-menu__navigation-item'
            sx={{ padding: 0 }}
            onClick={() => onClickForAction(action)}
          >
            <Icon type={icon} format='outline' />
          </div>
        </BBTooltip>
      </div>
    );
  }

  return (
    <div>
      <Link
        to={href}
        className={`dashboard-with-topbar-menu__navigation-item ${
          isCurrentTab
            ? 'dashboard-with-topbar-menu__navigation-item--active'
            : ''
        }`}
      >
        {t(name)}
      </Link>
    </div>
  );
};

export default DashboardMenuItem;
