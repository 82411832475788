import React from 'react';
import { useTranslation } from 'react-i18next';

const QuadrantDescription = ({ position, label, description }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`x-ray-chart__quadrant-description x-ray-chart__quadrant-description--${position}`}
    >
      <div
        style={{
          fontWeight: 'bold',
          padding: '4px 8px',
          fontSize: '12px',
          marginBottom: '7px',
          display: 'inline-block',
          borderRadius: '4px',
          backgroundColor:
            label === t('need_commitment')
              ? 'rgba(69, 219, 244, 0.1)'
              : label === t('need_motivation')
              ? 'rgba(255, 61, 48, 0.1)'
              : label === t('need_help')
              ? 'rgba(255, 174, 86, 0.1)'
              : 'rgba(0, 225, 154, 0.1)',
          color:
            label === t('need_commitment')
              ? 'rgba(31, 185, 210, 1)'
              : label === t('need_motivation')
              ? 'rgba(255, 61, 48, 1)'
              : label === t('need_help')
              ? 'rgba(255, 174, 86, 1)'
              : 'rgba(0, 225, 154, 1)'
        }}
      >
        {label}
      </div>
      <div style={{ fontSize: '12px', color: 'rgba(140, 153, 173, 1)' }}>
        {description}
      </div>
    </div>
  );
};

export default QuadrantDescription;
