import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import TDDepartmentsFilter from '../../../components/filters/TDDepartmentsFilter';
import TDUnitsFilter from '../../../components/filters/TDUnitsFilter';
import TDLinkTabs from '../../../components/linkTabs/TDLinkTabs';
import PageTitleWithTooltip from '../../../components/pageTitleWithTooltip/PageTitleWithTooltip';
import PageWithHeader from '../../../layouts/pageWithHeader/PageWithHeader';
import useClassProgress from './useClassProgress';

const ClassProgress = () => {
  const { t } = useTranslation();
  const { tabs, currentTab, onChangeTab } = useClassProgress();

  return (
    <PageWithHeader
      left={
        <PageTitleWithTooltip
          title={t('class_progress')}
          tooltip={t('class_progress_info')}
        />
      }
      center={<TDLinkTabs links={tabs} onChange={onChangeTab} />}
      right={
        currentTab === tabs[0]?.name ? (
          <TDUnitsFilter />
        ) : (
          <TDDepartmentsFilter />
        )
      }
    >
      <Outlet />
    </PageWithHeader>
  );
};

export default ClassProgress;
