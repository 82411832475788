import { DOMAIN_MAP } from '../constants/domainMap';

export const goToGame = () => {
  // get access token
  const accessToken = localStorage.getItem('bb_user_token');
  // get game id
  const courseGuid = localStorage.getItem('bb_current_course_guid');
  // redirect to blueberry game

  const domainMap = DOMAIN_MAP;

  console.log('');
  window.open(
    domainMap[window.location.host] +
      '?token=' +
      accessToken +
      '&courseGuid=' +
      courseGuid,
    '_blank'
  );
};
