import PropTypes from 'prop-types';
import './PageWithHeader.scss';

// This is a component that has a header with three sections: left, center, and right.
// It also has a tooltip that can be displayed on the left section.
const PageWithHeader = ({
  left = null,
  center = null,
  right = null,
  children = null
}) => {
  return (
    <div className='page-with-header__container'>
      <div className='page-with-header__header'>
        <div className='page-with-header__header--left'>{left}</div>
        <div className='page-with-header__header--center'>{center}</div>
        <div className='page-with-header__header--right'>{right}</div>
      </div>
      <div className='page-with-header__content'>{children}</div>
    </div>
  );
};

PageWithHeader.propTypes = {
  left: PropTypes.node,
  center: PropTypes.node,
  right: PropTypes.node,
  children: PropTypes.node.isRequired
};

export default PageWithHeader;
