import { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import { DOMAIN_MAP } from '../../../../utils/constants/domainMap';

const useQuizSocket = () => {
  const [gameSocket, setGameSocket] = useState(null);
  const [socketConnectedUsers, setSocketConnectedUsers] = useState([]);

  const [receivedAnswer, setReceivedAnswer] = useState([]);
  const [socketAnswerEvents, setSocketAnswerEvents] = useState([]);

  const [socketDisconnectedUsers, setSocketDisconnectedUsers] = useState([]);
  const [gameGuid, setGameGuid] = useState('');
  const [checkedStatusGame, setCheckedStatusGame] = useState(false);
  // const [quizGuid, setQuizGuid] = useState('');

  const domainMap = DOMAIN_MAP;

  let server = 'https://blueberry.tangerine-dev1.oneclicklabs.es';
  const ROOM = 'personal-notification';

  if (window.location.hostname !== 'localhost') {
    server = domainMap[window.location.host];
  }

  // let socket;
  const socket = useRef(null);

  const connectSocket = () => {
    const token = localStorage.getItem('bb_user_token') || '';

    socket.current = io(server, {
      path: '/api/socket.io',
      transports: ['websocket'],
      query: { token }
    });

    setGameSocket(socket);

    console.log('>>> Connected to Socket: socket', socket);

    socket.current.on(ROOM, (message) => {
      console.log('TEACHER SOCKET ON - ', message);
      if (message.event === 'start') {
        setGameGuid(message?.gameGuid);
        joinGame(message?.gameGuid);
      }
    });

    socket.current.on('quiz:user:connected', function (users) {
      console.log('SOCKET >> quiz:user:connected users: ', users);
      setSocketConnectedUsers(users);
    });

    socket.current.on('quiz:user:disconnected', function (users) {
      console.log('SOCKET >> quiz:user:disconnected users: ', users);
      setSocketDisconnectedUsers(users);
    });

    socket.current.on('sendAnswer', function (event) {
      console.log('SOCKET >> quiz:game:sendAnswer: ', event);
      setReceivedAnswer(event);
    });

    socket.current.on('statusGame', function (event) {
      console.log('SOCKET >> quiz:game:statusJoin: ', event);
      setCheckedStatusGame(true);
    });
  };

  const disconnectSocket = () => {
    if (socket.current) socket.current.disconnect();
  };

  const joinGame = (gameGuid) => {
    socket.current.emit(
      'quiz:game:join',
      { game_guid: gameGuid },
      function (response) {
        console.log('SOCKET >> quiz:game:join callback!', response);
      }
    );
  };

  const closeGame = (gameGuid) => {
    if (socket?.current) {
      socket.current.emit(
        'quiz:game:finish',
        { game_guid: gameGuid },
        function (response) {
          console.log('SOCKET >> quiz:game:finish callback!', response);
        }
      );
    }
    disconnectSocket();
  };

  const sendShowQuestion = (
    quizGuid,
    gameGuid,
    currentQuestion,
    secondsToAnswer,
    syncTimestamp
  ) => {
    if (socket.current)
      socket.current.emit(
        'quiz:game:event',
        {
          event: 'showQuestion',
          game_guid: gameGuid,
          quiz_guid: quizGuid,
          numberQuestion: currentQuestion,
          secondsToAnswer: secondsToAnswer,
          syncTimestamp: syncTimestamp
        },
        function (response) {
          console.log('SOCKET >> quiz:game:showQuestion callback!', response);
        }
      );
  };

  const sendShowSolution = () => {
    console.log('sendShowSolution payload', {
      event: 'showSolution',

      game_guid: gameGuid,
      // quiz_guid: quizGuid,
      showSolution: true
    });

    if (socket.current)
      socket.current.emit(
        'quiz:game:event',
        {
          event: 'showSolution',
          game_guid: gameGuid,
          // quiz_guid: quizGuid,
          showSolution: true
        },
        function (response) {
          console.log('SOCKET >> quiz:game:showSolution callback!', response);
        }
      );
  };

  const sendShowRanking = () => {
    console.log('sendShowRanking payload', {
      event: 'showRanking',
      game_guid: gameGuid,
      // quiz_guid: quizGuid,
      showRanking: true
    });

    if (socket.current)
      socket.current.emit(
        'quiz:game:event',
        {
          event: 'showRanking',
          game_guid: gameGuid,
          // quizGuid: quizGuid,
          showRanking: true
        },
        function (response) {
          console.log('SOCKET >> quiz:game:showRanking callback!', response);
        }
      );
  };

  const sendEndPractice = (playerList) => {
    console.log('sendEndPractice payload', {
      game_guid: gameGuid,
      playerList: playerList,
      showPodium: true
    });

    if (socket.current)
      socket.current.emit(
        'quiz:game:event',
        {
          event: 'endPractice',
          game_guid: gameGuid,
          playerList: playerList,
          showPodium: true
        },
        function (response) {
          console.log('SOCKET >> quiz:game:endPractice callback!', response);
        }
      );
  };

  const sendStatusGame = (payload) => {
    console.log('sendEndPractice payload', {
      game_guid: gameGuid,
      status: payload.status,
      numberQuestion: payload.numberQuestion,
      timer: payload.timer,
      playerList: payload.playerList
    });

    if (socket.current)
      socket.current.emit(
        'quiz:game:event',
        {
          event: 'responseStatusGame',
          game_guid: gameGuid,
          status: payload.status,
          numberQuestion: payload.numberQuestion,
          timer: payload.timer,
          playerList: payload.playerList
        },
        function (response) {
          console.log(
            'SOCKET >> quiz:game:responseStatusGame callback!',
            response
          );
        }
      );
  };

  const resetAnswerEvents = () => {
    setSocketAnswerEvents([]);
  };

  useEffect(() => {
    let _socketAnswerEvents = [...socketAnswerEvents, receivedAnswer];
    setSocketAnswerEvents(_socketAnswerEvents);

    console.log('NEW ANSWER:', receivedAnswer);
    console.log('_socketAnswerEvents', _socketAnswerEvents);
    console.log('_socketAnswerEvents.length', _socketAnswerEvents.length);
  }, [receivedAnswer]);

  return {
    socket,
    connectSocket,
    disconnectSocket,

    socketConnectedUsers,
    socketDisconnectedUsers,

    socketAnswerEvents,
    resetAnswerEvents,

    gameGuid,
    checkedStatusGame,
    sendShowQuestion,
    sendShowSolution,
    sendShowRanking,
    sendEndPractice,
    sendStatusGame,
    setCheckedStatusGame,

    closeGame
  };
};

export default useQuizSocket;
