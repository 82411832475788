import PropTypes from 'prop-types';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { editQuizName } from '../../../../utils/crud/quiz';
import QuizToast from '../viewQuiz/components/quizToast/QuizToast';

const useQuizEditNameDialog = ({
  quizGuid,
  callbackOnSuccess,
  callbackOnError
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onEditName = async ({ name }) => {
    setIsLoading(true);

    const toastSettings = {
      position: 'bottom-left',
      autoClose: 3000,
      hideProgressBar: true
    };

    const response = await editQuizName({
      quizGuid: quizGuid,
      newQuizName: name
    });
    if (!response) {
      toast(<QuizToast variant='quiz-name-update-failed' />, toastSettings);
      callbackOnError();
      return;
    }

    toast(
      <QuizToast variant='quiz-name-updated-successfully' />,
      toastSettings
    );

    callbackOnSuccess({ name });
    setIsLoading(false);
  };

  return {
    isLoading,
    onEditName
  };
};

// propTypes
useQuizEditNameDialog.propTypes = {
  quizGuid: PropTypes.string.isRequired,
  callbackOnSuccess: PropTypes.func.isRequired,
  callbackOnError: PropTypes.func.isRequired
};

export default useQuizEditNameDialog;
