import PropTypes from 'prop-types';
import Icon from '../../atoms/icon';

const XAxis = ({
  leftLabel = <Icon type='minus' format='outline' />,
  rightLabel = <Icon type='plus' format='outline' />
}) => {
  return (
    <div className='x-axis'>
      <div className='x-axis__label--left'>{leftLabel}</div>
      <div className='x-axis__line' />
      <div className='x-axis__label--right'>{rightLabel}</div>
    </div>
  );
};

XAxis.propTypes = {
  leftLabel: PropTypes.element,
  rightLabel: PropTypes.element
};

export default XAxis;
