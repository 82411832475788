import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { AnalysisSegment } from '../../../utils/analysis';
import { CourseContext } from '../../../utils/contexts/CourseContext';
import { useLocalStorage } from '../../../utils/hooks/useLocalStorage';

const useContentsPage = () => {
  const { pathname } = useLocation();
  const { selectedCourse } = useContext(CourseContext);
  const { t } = useTranslation();
  const { getItem } = useLocalStorage();

  const getHref = (path, key) => {
    let filter = getItem(path);

    if (filter) {
      try {
        filter = JSON.parse(filter);
      } catch (e) {
        filter = {};
      }
      return `${path}?${key}=${filter[key]}`;
    }
    return path;
  };

  const UNIT_TAB = {
    name: t('units'),
    href: '/content/units',
    link: '/content/units',
    filter: 'unit'
  };

  const STANDARD_TAB = {
    name: t('Standards'),
    href: '/content/standards',
    link: '/content/standards',
    filter: 'standard'
  };

  const [currentTab, setCurrentTab] = useState(UNIT_TAB.name);
  const [tabs, setTabs] = useState([]);
  const [isWaitingForTabManagement, setIsWaitingForTabManagement] =
    useState(true);

  useEffect(() => {
    if (!selectedCourse?.guid && !isWaitingForTabManagement) return;

    setTabs([]);
    // setSearchParams({});
    setIsWaitingForTabManagement(true);
  }, [selectedCourse]);

  useEffect(() => {
    if (!selectedCourse) return;
    AnalysisSegment.sendSegmentTrackEvent(
      AnalysisSegment.SEGMENT_EVENTS.Content_Page_Viewed,
      {
        class: selectedCourse?.guid,
        program_id: selectedCourse?.program_guid,
        students: selectedCourse?.users?.length
      }
    );
  }, [selectedCourse]);

  useEffect(() => {
    if (!selectedCourse?.guid) return;

    let unitTab = { ...UNIT_TAB, href: getHref('/content/units', 'unit') };
    let standardTab = {
      ...STANDARD_TAB,
      href: getHref('/content/standards', 'standard')
    };

    if (selectedCourse.standard_blueberry === 1) {
      setTabs([unitTab, standardTab]);
    } else {
      setTabs([unitTab]);
    }

    if (isWaitingForTabManagement) {
      setIsWaitingForTabManagement(false);
    }
  }, [selectedCourse, currentTab, pathname]);

  const onChangeTab = (selectedTab) => {
    setCurrentTab(selectedTab);
  };

  return {
    tabs,
    currentTab,
    onChangeTab
  };
};

export default useContentsPage;
