import { useEffect, useState } from 'react';

const useUserReport = (user) => {
  const [losNeedsHelp, setLosNeedsHelp] = useState(null);
  const [losIsMastered, setLosIsMastered] = useState(null);
  const [losIsMasteredRecently, setLosIsMasteredRecently] = useState(null);

  const [showAllLosNeedsHelp, setShowAllLosNeedsHelp] = useState(false);
  const [displayedNeedsHelp, setDisplayedNeedsHelp] = useState(null);

  const [showAllLosIsMastered, setShowAllLosIsMastered] = useState(false);
  const [displayedIsMastered, setDisplayedIsMastered] = useState(null);

  const [showAllLosIsMasteredRecently, setShowAllLosIsMasteredRecently] =
    useState(false);
  const [displayedIsMasteredRecently, setDisplayedIsMasteredRecently] =
    useState(null);

  const [isDetailLoDialogOpen, setIsDetailLoDialogOpen] = useState(false);

  const [currentLo, setCurrentLo] = useState(null);

  useEffect(() => {
    setLosNeedsHelp(
      user?.kcs?.reduce((acc, curr) => {
        const losWithNeedsHelp = curr.los.filter((lo) => lo.insights.needsHelp);
        return [...acc, ...losWithNeedsHelp];
      }, [])
    );
    setLosIsMastered(
      user?.kcs?.reduce((acc, curr) => {
        const losWithIsMastered = curr.los.filter(
          (lo) => lo.insights.isMastered
        );
        return [...acc, ...losWithIsMastered];
      }, [])
    );
    setLosIsMasteredRecently(
      user?.kcs?.reduce((acc, curr) => {
        const losWithIsMasteredRecently = curr.los.filter(
          (lo) => lo.insights.isMasteredRecently
        );
        return [...acc, ...losWithIsMasteredRecently];
      }, [])
    );
  }, []);

  useEffect(() => {
    setDisplayedNeedsHelp(
      showAllLosNeedsHelp ? losNeedsHelp : losNeedsHelp?.slice(0, 6)
    );
  }, [showAllLosNeedsHelp, losNeedsHelp]);

  useEffect(() => {
    setDisplayedIsMastered(showAllLosIsMastered ? losIsMastered : []);
  }, [showAllLosIsMastered, losIsMastered]);

  useEffect(() => {
    setDisplayedIsMasteredRecently(
      showAllLosIsMasteredRecently ? losIsMasteredRecently : []
    );
  }, [showAllLosIsMasteredRecently, losIsMasteredRecently]);

  const onOpenDetailLoDialog = (lo) => {
    setIsDetailLoDialogOpen(true);
    setCurrentLo(lo);
  };

  const onCloseDetailLoDialog = () => {
    setIsDetailLoDialogOpen(false);
    setCurrentLo(null);
  };

  return {
    losNeedsHelp,
    losIsMastered,
    losIsMasteredRecently,
    displayedIsMastered,
    setShowAllLosIsMastered,
    showAllLosIsMastered,
    displayedNeedsHelp,
    setShowAllLosNeedsHelp,
    showAllLosNeedsHelp,
    displayedIsMasteredRecently,
    setShowAllLosIsMasteredRecently,
    showAllLosIsMasteredRecently,
    onOpenDetailLoDialog,
    onCloseDetailLoDialog,
    isDetailLoDialogOpen,
    currentLo
  };
};

export default useUserReport;
