import { Pagination, styled } from '@mui/material';

const TablePagination = styled(Pagination)(({ theme }) => ({
  '.MuiPaginationItem-rounded': {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[500],
    border: '1px solid #F0F3F8',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
      opacity: '0.6'
    }
  },
  '.MuiPaginationItem-root.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.6'
    }
  },
  '.MuiPaginationItem-ellipsis': {
    backgroundColor: 'transparent',
    border: 'none',
    color: theme.palette.grey[500],
    '&:hover': {
      backgroundColor: 'transparent',
      border: 'none',
      color: theme.palette.grey[500]
    }
  },
  '.MuiPaginationItem-previousNext.Mui-disabled': {
    backgroundColor: theme.palette.grey[100],
    border: '1px solid #F0F3F8',
    color: theme.palette.action.disabled,
    opacity: '1'
  }
}));

export default TablePagination;
