// Set the parameters that we will need inside the submodule
// Create a set function for each new parameter

function setDevMode(value) {
  window.BBPracticeConfig = {
    ...window.BBPracticeConfig,
    devMode: value
  }
}

function setTranslationFunction(translate) {
  window.BBPracticeConfig = {
    ...window.BBPracticeConfig,
    translate: translate
  }
}

function setLanguage(value) {
  window.BBPracticeConfig = {
    ...window.BBPracticeConfig,
    language: value
  }
}

const DEFAULT_CONFIG = {
  setTranslationFunction,
  setLanguage,
  setDevMode,
  language: 'es',
  devMode: 0
}

export function setBBPracticeDefaultConfig() {
  window.BBPracticeConfig = DEFAULT_CONFIG
}
