import { useContext, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { CourseContext } from '../../../utils/contexts/CourseContext';
import { getCourseStandardByStudentWithStatus } from '../../../utils/crud/content';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import CellWithAvatarAndName from '../../../components/table/cell/cellTypes/CellWithAvatarAndName';
import CellStatusChip from '../../../components/table/cell/cellTypes/CellStatusChip';

const useStandardDetails = () => {
  const { standardGuid } = useParams();
  const columnHelper = createColumnHelper();
  const { t } = useTranslation();
  const { selectedCourse } = useContext(CourseContext);

  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [standard, setStandard] = useState({});

  const getStudentsForStandard = async () => {
    setIsLoading(true);

    const studentResponse = await getCourseStandardByStudentWithStatus({
      courseGuid: selectedCourse.guid,
      standardGuid
    });

    setData(
      studentResponse.students.map((student) => ({
        firstName: student.firstname,
        lastName: student.lastname,
        avatar: student.avatar,
        guid: student.studentGuid,
        ...student.kcs
          .map((kc) => ({
            [kc.kcName]: kc.status,
            ['data of ' + kc.kcName]: kc
          }))
          .reduce((prev, curr) => ({ ...prev, ...curr }), {})
      }))
    );

    setColumns([
      columnHelper.accessor('student', {
        header: t('Student'),
        id: 'student',
        enableSorting: true,
        sortingFn: 'alphanumeric',
        cell: (info) => (
          <CellWithAvatarAndName
            firstName={info.row.original.firstName}
            lastName={info.row.original.lastName}
          />
        )
      }),
      ...studentResponse.students[0].kcs.map((kc) =>
        columnHelper.accessor(kc.kcName, {
          header: kc.kcName,
          id: kc.kcName,
          enableSorting: true,
          sortingFn: 'cellWithStatus',
          cell: (info) => (
            <CellStatusChip
              status={info.row.original[kc.kcName]}
              LOData={info.row.original['data of ' + kc.kcName].los}
            />
          )
        })
      )
    ]);

    setStandard({
      standardGuid,
      standardName: studentResponse.standardName
    });

    setIsLoading(false);
  };

  useEffect(() => {
    if (standardGuid && selectedCourse?.guid) {
      getStudentsForStandard();
    }
  }, [standardGuid, selectedCourse]);

  return {
    standard,
    isLoading,
    data,
    columns
  };
};

export default useStandardDetails;
