import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts';

import {
  CircularProgress,
  ClickAwayListener,
  Fade,
  Popper
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { data as dummyData } from '../../components/classProgressBarChart/dummyData';

import CustomizedAxisTick from './CustomizedAxisTick';
import StudentListPopup from './StudentListPopup';

import './ClassProgressBarChart.scss';

const ClassProgressBarChart = ({ data = dummyData }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [studentsInCategory, setStudentsInCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  const { t } = useTranslation();

  const xAxisInfo = data.map((item, index) => ({
    name: item.name,
    label: item.label,
    isEmpty: false
  }));

  const handleClick = (data, index, event) => {
    // event from bar
    if (event?.currentTarget) {
      setStudentsInCategory(data[data.tooltipPayload[0].dataKey + 'Students']);
      setSelectedCategory(data.tooltipPayload[0].dataKey);
      setAnchorEl(event.currentTarget);
      setOpen(true);
    } else {
      setAnchorEl(null);
      setOpen(false);
    }
  };

  return (
    <div className='class-progress-bar-chart'>
      {data.length === 0 && <CircularProgress />}
      {data.length > 0 && (
        <>
          <span className='class-progress-bar-chart__y-axis-label'>
            {t('students')}
          </span>
          <ResponsiveContainer width={'100%'} height={400}>
            <BarChart
              data={data}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5
              }}
              barSize={20}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis
                tickMargin={10}
                height={81}
                allowDecimals={false}
                tick={<CustomizedAxisTick data={xAxisInfo} />}
              />
              <YAxis allowDecimals={false} />
              <Bar
                isAnimationActive={false}
                dataKey='needMotivation'
                className='class-progress-bar-chart__bar'
                stackId='a'
                fill='#FF3D30'
                onClick={handleClick}
                cursor={'pointer'}
              />
              <Bar
                isAnimationActive={false}
                dataKey='needHelp'
                className='class-progress-bar-chart__bar'
                stackId='a'
                fill='#FFAE56'
                onClick={handleClick}
                cursor={'pointer'}
              />
              <Bar
                isAnimationActive={false}
                dataKey='inProgress'
                stackId='a'
                className='class-progress-bar-chart__bar'
                fill='#45DBF4'
                onClick={handleClick}
                cursor={'pointer'}
              />
              <Bar
                isAnimationActive={false}
                dataKey='mastered'
                className='class-progress-bar-chart__bar'
                stackId='a'
                fill='#00E19A'
                onClick={handleClick}
                cursor={'pointer'}
              />
            </BarChart>
          </ResponsiveContainer>
        </>
      )}
      {open && (
        <ClickAwayListener
          onClickAway={(event) => {
            if (
              ![...event?.target?.classList].includes(
                'class-progress-bar-chart__bar'
              )
            ) {
              handleClick();
            }
          }}
        >
          <Popper
            id={open && Boolean(anchorEl) ? 'simple-popper' : undefined}
            open={open}
            disablePortal={false}
            placement='auto-start'
            anchorEl={anchorEl}
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, 16]
                }
              },
              {
                name: 'preventOverflow',
                options: {
                  padding: { top: 5, bottom: 5, left: 70, right: 5 }
                }
              }
            ]}
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <div>
                  <StudentListPopup
                    title={t(selectedCategory)}
                    hint={t(selectedCategory + '_description')}
                    students={studentsInCategory}
                  />
                </div>
              </Fade>
            )}
          </Popper>
        </ClickAwayListener>
      )}
    </div>
  );
};

ClassProgressBarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      mastered: PropTypes.number.isRequired,
      inProgress: PropTypes.number.isRequired,
      needHelp: PropTypes.number.isRequired,
      needMotivation: PropTypes.number.isRequired
    })
  ).isRequired
};

export default ClassProgressBarChart;
