import { Avatar, MenuItem, Select, Skeleton } from '@mui/material';
import React from 'react';
import TDLinkTabs from '../linkTabs/TDLinkTabs';

import './StudentProfileCard.scss';
import { useTranslation } from 'react-i18next';

const StudentProfileCard = ({ student, isLoading }) => {
  const { t } = useTranslation();

  return (
    <div className='student-profile-card__container'>
      <div className='student-profile-card__header'>
        <div className='student-profile-card__header--left'>
          {t('student_profile')}
        </div>
        <div className='student-profile-card__header--center'></div>
        <div className='student-profile-card__header--right'></div>
      </div>
      <div className='student-profile-card__content'>
        <div className='student-profile-card__content-profile-pic'>
          {isLoading ? (
            <Skeleton variant='circular' width={100} height={100} />
          ) : (
            <Avatar
              src={student?.profile_pic}
              alt={`${student.name} ${student.lastname}`}
              size='large'
              sx={{
                width: 84,
                height: 84,
                fontSize: '2.5rem'
              }}
            >
              {`${student.name.charAt(0)}${student.lastname.charAt(0)}`}
            </Avatar>
          )}
        </div>
        <div className='student-profile-card__content-details'>
          <div className='student-profile-card__content-details-grade'>
            {isLoading ? (
              <Skeleton variant='text' sx={{ fontSize: '1.5rem' }} />
            ) : (
              `${student.school}`
            )}
          </div>
          <div className='student-profile-card__content-details-name'>
            {isLoading ? (
              <Skeleton variant='text' sx={{ fontSize: '1.5rem' }} />
            ) : (
              `${student.name} ${student.lastname}`
            )}
          </div>
          <div className='student-profile-card__content-details-container'>
            <div className='student-profile-card__content-details-container--single'>
              <div className='student-profile-card__content-details--label'>
                {t('registered')}
              </div>
              {isLoading ? (
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
              ) : (
                <div className='student-profile-card__content-details--value'>
                  {Intl.DateTimeFormat('es-ES', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit'
                  }).format(new Date(student?.registered))}
                </div>
              )}
            </div>
            <div className='student-profile-card__content-details-container--single'>
              <div className='student-profile-card__content-details--label'>
                {t('last_login')}
              </div>
              {isLoading ? (
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
              ) : (
                <div className='student-profile-card__content-details--value'>
                  {student?.last_login
                    ? Intl.DateTimeFormat('es-ES', {
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit'
                      }).format(new Date(student?.last_login))
                    : '-'}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentProfileCard;
