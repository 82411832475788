import Card from '../../../../atoms/card/Card';
import ClassProgressBarChart from '../../../../components/classProgressBarChart/ClassProgressBarChart';
import useClassProgressStandards from './useClassProgressStandards';

const ClassProgressStandards = () => {
  const { data, isLoading } = useClassProgressStandards();

  return (
    <Card variant='outlined'>
      <ClassProgressBarChart data={data} isLoading={isLoading} />
    </Card>
  );
};

export default ClassProgressStandards;
