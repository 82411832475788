import { createContext, useState } from 'react';

export const AuthContext = createContext({
  isLoggedIn: '',
  user: '',
  setUser: () => {}
});

export const AuthProvider = ({ children }) => {
  let tempUser = localStorage.getItem('bb_user_whoami');
  if (tempUser) {
    try {
      tempUser = JSON.parse(tempUser);
    } catch (e) {
      tempUser = null;
    }
  }
  const [user, setUser] = useState(tempUser);
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem('bb_user_token')
  );

  return (
    <AuthContext.Provider value={{ user, setUser, isLoggedIn, setIsLoggedIn }}>
      {children}
    </AuthContext.Provider>
  );
};
