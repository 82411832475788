import Check from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './selectStudentsOrder.scss';

const SelectStudentsOrder = ({ selectedOrderBy, onChange }) => {
  const { t } = useTranslation();
  return (
    selectedOrderBy && (
      <div className='select-students-order'>
        <Select
          size='small'
          onChange={({ target: { value } }) => onChange(value)}
          value={selectedOrderBy}
          IconComponent={KeyboardArrowDownIcon}
          className='select-students-order__select'
          fullWidth
        >
          <div className='select-students-order__title'>{t('order_by')}</div>
          <MenuItem
            className='select-students-order__select-item'
            value={'lastname'}
          >
            {t('alphabetical_order')}{' '}
            {selectedOrderBy === 'lastname' && <Check fontSize='small' />}
          </MenuItem>
          <MenuItem
            className='select-students-order__select-item'
            value={'engagement'}
          >
            {t('engagement')}{' '}
            {selectedOrderBy === 'engagement' && <Check fontSize='small' />}
          </MenuItem>
          <MenuItem
            className='select-students-order__select-item'
            value={'masteredContent'}
          >
            {t('mastered_content')}{' '}
            {selectedOrderBy === 'masteredContent' && (
              <Check fontSize='small' />
            )}
          </MenuItem>
        </Select>
      </div>
    )
  );
};

export default SelectStudentsOrder;
