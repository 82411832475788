import useAuth from '../hooks/useAuth';

const generateAssetURL = (asset) => {
  // check if schoolpath exists on user
  const { schoolAssetsPath } = useAuth();

  if (schoolAssetsPath) {
    return `/assets/graphics/${schoolAssetsPath}/${asset}`;
  }
  return `/assets/graphics/${asset}`;
};

export default generateAssetURL;
