import React from 'react';
import StudentProfileCard from '../../../components/studentProfileCard/StudentProfileCard';
import FullPageLayout from '../../../layouts/dashboardFullPage/FullPageLayout';
import useStudentDetails from './useStudentDetails';
import { IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import StudentInsights from '../../../components/studentInsights/StudentInsights';

const StudentDetails = () => {
  const { student, isLoading, insights } = useStudentDetails();

  return (
    <FullPageLayout
      type='teacher'
      left={
        <IconButton variant='transparent' onClick={() => history.back()}>
          <ArrowBack sx={{ color: 'white' }} />
        </IconButton>
      }
      title={student.fullName}
      right={<></>}
    >
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <StudentProfileCard isLoading={isLoading} student={student} />
        <StudentInsights isLoading={isLoading} insights={insights} />
      </div>
    </FullPageLayout>
  );
};

export default StudentDetails;
