import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

const BBTooltip = ({ children, text, ...props }) => {
  return (
    <Tooltip {...props} title={text} enterTouchDelay={0}>
      {children}
    </Tooltip>
  );
};

Tooltip.propTypes = {
  arrow: PropTypes.bool,
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right'])
};

Tooltip.defaultProps = {
  arrow: true,
  placement: 'bottom',
  text: ''
};

export default BBTooltip;
