export const activityStorage = (() => {
  const get = (key, defaultValue = null) => {
    try {
      const value = window.localStorage.getItem(key)
      return value ? JSON.parse(value) : defaultValue
    } catch (error) {
      console.error(`Error reading '${key}' from activityStorage`, error)
      return defaultValue
    }
  }

  const set = (key, value) => {
    try {
      window.localStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
      console.error(`Error writing '${key}' to activityStorage`, error)
    }
  }

  const remove = (key) => {
    try {
      window.localStorage.removeItem(key)
    } catch (error) {
      console.error(`Error deleting '${key}' from activityStorage`, error)
    }
  }

  const clear = () => {
    try {
      window.localStorage.clear()
    } catch (error) {
      console.error(`Error clearing activityStorage`, error)
    }
  }

  return {
    get,
    set,
    remove,
    clear
  }
})()

// Ejemplo de uso:
//  activityStorage.get('key', [])
//  activityStorage.set('key', data)
//  activityStorage.remove('key')
//  activityStorage.clear()
