import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CloseIcon } from '../../../../../../assets/icons/x.svg';
import BBTooltip from '../../../../../../atoms/tooltip/BBTooltip';
import './gameQuizHeader.scss';

const GameQuizHeader = ({
  leftComponent,
  centerComponent,
  rightComponent,
  quizGuid,
  closeGame
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onCloseQuiz = () => {
    closeGame();
    window.history.back();
  };

  return (
    <div className='game-quiz-header'>
      <div className='game-quiz-header__left'>{leftComponent}</div>
      <div className='game-quiz-header__center'>{centerComponent}</div>
      <div className='game-quiz-header__right'>
        {rightComponent}
        <BBTooltip text={t('close')} placement='bottom'>
          <div className='quiz-game-header__close-icon' onClick={onCloseQuiz}>
            <CloseIcon />
          </div>
        </BBTooltip>
      </div>
    </div>
  );
};

export default GameQuizHeader;
