import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as QuizDeleteFilled } from '../../../../../../assets/icons/quiz-delete-filled.svg';
import { ReactComponent as QuizErrorFilled } from '../../../../../../assets/icons/quiz-error-filled.svg';
import { ReactComponent as SvgLightning } from '../../../../../../assets/icons/quiz-lightning.svg';

const QuizToast = ({ variant, text, icon }) => {
  const { t } = useTranslation();

  const variants = {
    'regenerate-success': {
      icon: <SvgLightning />,
      text: t('quiz_regenerate_toast')
    },
    'delete-quiz-activity-success': {
      icon: <QuizDeleteFilled />,
      text: t('delete_quiz_activity_success_toast')
    },
    'add-activity-success': {
      icon: <SvgLightning />,
      text: t('add_activity_to_quiz_success_toast')
    },
    'quiz-deleted-successfully': {
      icon: <QuizDeleteFilled />,
      text: t('quiz_deleted_successfully_toast')
    },
    'add-activities-success': {
      icon: <SvgLightning />,
      text
    },
    'quiz-no-lessons-selected': {
      icon: <QuizErrorFilled />,
      text: t('quiz_no_lessons_selected_toast')
    },
    'quiz-no-questions-selected': {
      icon: <QuizErrorFilled />,
      text: t('quiz_no_questions_selected_toast')
    },
    'quiz-no-name': {
      icon: <QuizErrorFilled />,
      text: t('quiz_no_name_toast')
    },
    'generate-traces-success': {
      icon: <SvgLightning />,
      text: t('button_generate_traces_toast_success')
    },
    'generate-traces-warning': {
      icon: <QuizErrorFilled />,
      text: t('button_generate_traces_toast_warning')
    },
    'quiz-name-updated-successfully': {
      icon: <SvgLightning />,
      text: t('quiz_name_updated_successfully_toast')
    },
    'quiz-name-update-failed': {
      icon: <QuizErrorFilled />,
      text: t('quiz_name_update_failed_toast')
    },
    'quiz-generic-error': {
      icon: <QuizErrorFilled />,
      text: t('quiz_error_has_occurred_toast')
    }
  };

  return (
    <Stack direction='row' spacing={3} alignItems='center'>
      {variants[variant].icon}
      <Typography>{variants[variant].text}</Typography>
    </Stack>
  );
};

export default QuizToast;
