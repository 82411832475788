import React, { useCallback } from 'react'
import ActivitySimpleButton from '../../atoms/ActivitySimpleButton/ActivitySimpleButton'
import './dummyActivity.scss'

function DummyActivity({ data, sendResponse, thumbsDisabled }) {
  const sendSuccess = useCallback(() => {
    sendResponse(true)
  }, [sendResponse])

  const sendError = useCallback(() => {
    sendResponse(false)
  }, [sendResponse])

  const { phase, nodeDepartment, nodeName, learningObjectiveName } = data

  return (
    <div className="dummy-activity">
      <div className="dummy-activity__content">
        <div className="dummy-activity__titles">
          <ul>
            <li>
              KC: <b>{nodeDepartment}</b>
            </li>
            <li>
              LO: <b>{nodeName}</b>
            </li>
            <li>
              Fase: <b>{phase}</b>
            </li>
            <li>
              Standard Code: <b>{learningObjectiveName}</b>
            </li>
          </ul>
        </div>

        <div className="controls">
          <ActivitySimpleButton
            display="inline"
            color="green"
            text="👍🏻"
            onClick={sendSuccess}
            disabled={thumbsDisabled}
          />
          <ActivitySimpleButton
            display="inline"
            color="red"
            text="👎🏻"
            onClick={sendError}
            disabled={thumbsDisabled}
          />
        </div>
      </div>
    </div>
  )
}

export default DummyActivity
