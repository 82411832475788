import { useTranslation } from 'react-i18next';
import PageTitleWithTooltip from '../../../components/pageTitleWithTooltip/PageTitleWithTooltip';
import Table from '../../../components/table/Table';
import PageWithHeader from '../../../layouts/pageWithHeader/PageWithHeader';
import SelectStudentsOrder from './components/SelectStudentsOrder/SelectStudentsOrder';
import SelectStudentsTimeRange from './components/SelectStudentsTimeRange/SelectStudentsTimeRange';
import './studentsPage.scss';
import useStudentsPage from './useStudentsPage';

const StudentsPage = ({ isMock = false }) => {
  const { t } = useTranslation();
  const {
    columns,
    usersData,
    isLoading,
    selectedOrderBy,
    selectedTimeRange,
    setSelectedOrderBy,
    setSelectedTimeRange
  } = useStudentsPage(isMock);

  return (
    <div className='students-page'>
      <PageWithHeader
        left={
          <PageTitleWithTooltip
            title={t('students_analysis')}
            tooltip={t('students_analysis_info')}
          />
        }
        right={
          <div className='students-page__filters-container'>
            <SelectStudentsOrder
              selectedOrderBy={selectedOrderBy}
              onChange={setSelectedOrderBy}
            />
            <SelectStudentsTimeRange
              selectedTimeRange={selectedTimeRange}
              onChange={setSelectedTimeRange}
            />
          </div>
        }
      >
        <Table data={usersData} columns={columns} isLoading={isLoading} />
      </PageWithHeader>
    </div>
  );
};

export default StudentsPage;
