import './AccordionBB.scss';
const AccordionBB = ({ title, description }) => {
  return (
    <div className='accordion-component'>
      <details>
        <summary>{title}</summary>
        <div className='accordion-component__content'>{description}</div>
      </details>
    </div>
  );
};

export default AccordionBB;
