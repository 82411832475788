import React from 'react';
import PropTypes from 'prop-types';
import './QuizBadge.scss';
import { Tooltip } from '@mui/material';
import { Cancel } from '@mui/icons-material';

const QuizBadge = ({ label, tooltip, badge, onDelete, fullWidth }) => {
  return (
    <div className={`quiz-badge ${fullWidth ? 'quiz-badge--full-width' : ''}`}>
      <div className='quiz-badge__container'>
        <Tooltip title={tooltip}>
          <div className='quiz-badge__content'>
            {badge && (
              <span className='quiz-badge__badge'>{badge.split(' ')[0]}</span>
            )}
            <div className='quiz-badge__label'>{label}</div>
            {onDelete && (
              <div className='quiz-badge__delete' onClick={onDelete}>
                <Cancel />
              </div>
            )}
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

QuizBadge.defaultProps = {
  label: '1st Grade',
  badge: '',
  tooltip: ''
};

QuizBadge.propTypes = {
  label: PropTypes.string,
  badge: PropTypes.string,
  tooltip: PropTypes.string
};

export default QuizBadge;
