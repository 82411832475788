import { fetchWithToken } from '../helpers/fetcher';

export const getReportCourse = async ({ courseGuid, days }) => {
  let response = await fetchWithToken({
    method: 'GET',
    path: `blueberry/course/${courseGuid}?days=${days}`
  });

  return response.data;
};

export const getDetailReportCourse = async ({
  courseGuid,
  userGuid,
  loGuid
}) => {
  let response = await fetchWithToken({
    method: 'GET',
    path: `blueberry/course/${courseGuid}/students/${userGuid}/?learningOutcome=${loGuid}`
  });

  return response.data;
};
