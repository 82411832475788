import React, { createContext, useReducer } from 'react'
import {
  ACTIVITY_ACTIONS,
  ACTIVITY_TYPES
} from '../../constants/ActivityConstants'

const initialState = {
  activityType: ACTIVITY_TYPES.PRACTICE, // Por defecto la actividad es 'PRACTICE'
  isPracticeType: true,
  activity: null,
  activityStore: null,
  isCheckButtonEnabled: false,
  isAnswerCorrect: false,
  isSolutionShowed: false,
  isHintUsed: false,
  isDisabledActivity: false,

  isScaffold: false,
  scaffoldCurrentQuestion: null,
  scaffoldNext: () => {},
  isScaffoldComplete: false,

  isDemoMode: false
}

const reducer = (state, action) => {
  const payload = action.payload

  switch (action.type) {
    case ACTIVITY_ACTIONS.SET_ACTIVITY_TYPE:
      return {
        ...state,
        activityType: payload.activityType,
        isPracticeType: payload.activityType === ACTIVITY_TYPES.PRACTICE
      }

    case ACTIVITY_ACTIONS.SET_ACTIVITY:
      return {
        ...state,
        activity: payload
      }

    case ACTIVITY_ACTIONS.SET_ACTIVITY_STORE:
      return {
        ...state,
        activityStore: payload
      }

    case ACTIVITY_ACTIONS.INITIALIZE_ACTIVITY:
      return {
        ...state,
        isCheckButtonEnabled: false,
        isAnswerCorrect: false,
        isSolutionShowed: false,
        isScaffold: payload,
        isScaffoldComplete: false,
        isHintUsed: false,
        isDisabledActivity: false
      }

    case ACTIVITY_ACTIONS.SET_IS_CHECK_BUTTON_ENABLED:
      return {
        ...state,
        isCheckButtonEnabled: payload
      }

    case ACTIVITY_ACTIONS.SET_IS_ANSWER_CORRECT:
      return {
        ...state,
        isAnswerCorrect: payload
      }

    case ACTIVITY_ACTIONS.SET_IS_SOLUTION_SHOWED:
      return {
        ...state,
        isSolutionShowed: payload
      }

    case ACTIVITY_ACTIONS.SET_IS_HINT_USED:
      return {
        ...state,
        isHintUsed: payload
      }

    case ACTIVITY_ACTIONS.SET_IS_DISABLED_ACTIVITY:
      return {
        ...state,
        isDisabledActivity: payload
      }

    case ACTIVITY_ACTIONS.SET_IS_SCAFFOLD:
      return {
        ...state,
        isScaffold: payload
      }

    case ACTIVITY_ACTIONS.SET_SCAFFOLD_CURRENT_QUESTION:
      return {
        ...state,
        scaffoldCurrentQuestion: payload
      }

    case ACTIVITY_ACTIONS.SET_SCAFFOLD_NEXT_FUNCTION:
      return {
        ...state,
        scaffoldNext: payload
      }

    case ACTIVITY_ACTIONS.SET_SCAFFOLD_IS_COMPLETED:
      return {
        ...state,
        isScaffoldComplete: payload
      }

    case ACTIVITY_ACTIONS.SET_DEMO_MODE:
      return {
        ...state,
        isDemoMode: payload
      }

    default:
      return state
  }
}

export const ActivityContext = createContext()

export const ActivityProvider = ({
  children,
  activityType = ACTIVITY_TYPES.PRACTICE,
  activity = null,
  isDemoMode = false,
  disabledQuestion = false
}) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    activityType: activityType,
    activity: activity,
    isDemoMode: isDemoMode,
    isDisabledActivity: disabledQuestion,
    isPracticeType: activityType === ACTIVITY_TYPES.PRACTICE
  })

  return (
    <ActivityContext.Provider value={{ state, dispatch }}>
      {children}
    </ActivityContext.Provider>
  )
}
