import { fetchWithToken } from '../helpers/fetcher';

export const getCourses = async () => {
  let response = await fetchWithToken({
    method: 'GET',
    path: 'front/courses?hasBlueBerry=1'
  });

  return response.data;
};
