import React from 'react';
import Card from '../../../../atoms/card/Card';
import { useTranslation } from 'react-i18next';

const ClassProgressTimeline = () => {
  const { t } = useTranslation();

  return <Card variant='outlined'>{t('coming_soon')}</Card>;
};

export default ClassProgressTimeline;
