import PropTypes from 'prop-types';
import Icon from '../../atoms/icon';

const YAxis = ({
  topLabel = <Icon type='plus' format='outline' />,
  bottomLabel = <Icon type='minus' format='outline' />
}) => {
  return (
    <div className='y-axis'>
      <div className='y-axis__label--top'>{topLabel}</div>
      <div className='y-axis__line' />
      <div className='y-axis__label--bottom'>{bottomLabel}</div>
    </div>
  );
};

YAxis.propTypes = {
  topLabel: PropTypes.element,
  bottomLabel: PropTypes.element
};

export default YAxis;
