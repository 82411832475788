import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { resendInvitations } from '../../../../../utils/crud/quiz';
import useAuth from '../../../../../utils/hooks/useAuth';

const usePlayQuizLobby = ({
  quizGuid,
  playerList,
  setPlayerList,
  playSoundEffect,
  currentGameGuid
}) => {
  const [connectedPlayers, setConnectedPlayers] = useState(0);
  const [userIsDemo, setUserIsDemo] = useState(false);
  const { user } = useAuth();
  const courseGuid = localStorage.getItem('bb_current_course_guid');
  const timeoutId = useRef(null);
  const [isLoadingResendInvitation, setIsLoadingResendInvitation] =
    useState(false);

  const navigate = useNavigate();

  const handleMockConnect = () => {
    const _newPlayerList = playerList?.map((player) => ({
      ...player,
      is_online: true
    }));
    setPlayerList(_newPlayerList);
  };

  const updateConnectedPlayers = () => {
    const _players = playerList?.filter((player) => player?.is_online);
    setConnectedPlayers(_players.length || 0);
  };

  const onResendInvitations = async () => {
    if (currentGameGuid) {
      setIsLoadingResendInvitation(true);
      // don't send more than once every 2 seconds
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        resendInvitations({ courseGuid, gameGuid: currentGameGuid });
        timeoutId.current = null;
        setIsLoadingResendInvitation(false);
      }, 2000);
    }
  };

  const onCloseQuiz = () => {
    navigate(`/quizzes/${quizGuid}`);
  };

  useEffect(() => {
    updateConnectedPlayers();
  }, [playerList]);

  useEffect(() => {
    setUserIsDemo(Boolean(user.is_demo));
    playSoundEffect('littleApprentice', true);
  }, []);

  return {
    connectedPlayers,
    handleMockConnect,
    onResendInvitations,
    onCloseQuiz,
    userIsDemo,
    isLoadingResendInvitation
  };
};

export default usePlayQuizLobby;
