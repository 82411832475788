import { fetchWithToken } from '../helpers/fetcher';

export const getStudents = async ({ courseGuid, days, lang }) => {
  let response = await fetchWithToken({
    method: 'GET',
    path: `blueberry/teacher/${courseGuid}/students?days=${days}&lang=${lang}`
  });

  return response.data;
};

export const getStudentDetails = async ({ courseGuid, studentGuid }) => {
  let response = await fetchWithToken({
    method: 'GET',
    path: `blueberry/teacher/${courseGuid}/student/${studentGuid}/detail`
  });

  return response.data;
};
