import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import QuizBadge from '../../viewQuiz/components/quizBadge/QuizBadge';
import CloseIcon from '@mui/icons-material/Close';
import './manageTopicsDialog.scss';

const ManageTopicsDialog = ({
  isPopupOpen,
  onClose,
  selectedLessons,
  onRemoveLesson,
  onRemoveAllLessonsAndClose
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isPopupOpen}
      onClose={onClose}
      className='manage-topics-dialog'
      maxWidth='md'
      fullWidth
    >
      <DialogTitle className='manage-topics-dialog__title'>
        {t('Topics')}
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          backgroundColor: 'transparent'
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className='manage-topics-dialog__content'>
        {selectedLessons.map((lesson) => (
          <QuizBadge
            key={lesson?.guid}
            label={lesson?.name}
            tooltip={lesson?.name}
            badge={lesson?.educationYear}
            onDelete={() => onRemoveLesson(lesson?.guid)}
          />
        ))}
      </DialogContent>
      <DialogActions className='manage-topics-dialog__actions'>
        <Button
          onClick={onRemoveAllLessonsAndClose}
          variant='outlined'
          color='secondary'
          disableElevation
          disableRipple
        >
          {t('remove_all_topics')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManageTopicsDialog;
