import { fetchWithToken } from '../helpers/fetcher';

export const getWhoami = async () => {
  let response = await fetchWithToken({
    method: 'GET',
    path: 'users/whoami'
  });

  return response.data;
};
