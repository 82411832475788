import { useEffect, useRef, useState } from 'react'
import { getConfig } from 'react-lemonade-editor'
import { ACTIVITY_TYPES } from '../../constants/ActivityConstants'

export function useActivityView({
  activityRef,
  activityType,
  currentActivity,
  activityContentRef,
  isFeedbackVisible,
  activityStore,
  exerciseStore,
  isScaffold,
  scaffoldCurrentQuestion,
  showAllFeedback,
  onActivityEvent,
  setActivityStore,
  initializeActivity,
  setIsFeedbackVisible,
  updateExercise,
  isResponseSend,
  exerciseLanguage
}) {
  const MINIMUN_PATHWAY_CELL_SIZE = 40

  const resizeTimer = useRef(null)
  const scrollTimer = useRef(null)
  const [activityWidth, setActivityWidth] = useState(window.innerWidth)
  const [isTopScrollShadowActive, setIsTopScrollShadowActive] = useState(false)
  const [isBottomScrollShadowActive, setIsBottomScrollShadowActive] =
    useState(false)
  const [isScrollMarkActive, setIsScrollMarkActive] = useState(false)
  const [footerHeight, setFooterHeight] = useState(0)
  const [showActivityFooter, setShowActivityFooter] = useState(true)
  const [pathwayCellSize, setPathwayCellSize] = useState(null)

  useEffect(() => {
    getConfig().setShowFeedback(showAllFeedback)
  }, [showAllFeedback])

  useEffect(() => {
    // Observer para scroll de sacaffolding steps
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes) {
          mutation.addedNodes.forEach((node) => {
            // Para scroll de sacaffolding steps
            if (
              node?.classList &&
              node.classList.contains('scaffold-separator')
            ) {
              setTimeout(() => {
                // node.scrollIntoView({ behavior: 'smooth', block: 'start' })
                node.scrollIntoView({
                  block: 'center',
                  inline: 'nearest',
                  behavior: 'smooth'
                })
                checkScrollShadows()
              }, 150)
            }
          })
        }
      })
    })

    // Start observing
    observer.observe(document.body, {
      childList: true,
      subtree: true
    })

    const _activityWidth = activityRef?.current?.offsetWidth
    setActivityWidth(_activityWidth)
    window.addEventListener('resize', handleResize)

    getFooterHeight()

    const boundCheckScrollShadows = () => {
      setIsScrollMarkActive(false)
      checkScrollShadows()
    }
    activityContentRef.current.addEventListener(
      'scroll',
      boundCheckScrollShadows
    )
    const boundRemoveScrollMark = () => {
      console.log('boundRemoveScrollMark')
      setTimeout(() => {
        setIsScrollMarkActive(false)
      }, 0)
    }
    // activityContentRef.current.addEventListener('scroll', boundRemoveScrollMark)
    // if (activityType !== ACTIVITY_TYPES.QUIZ) {
    // window.addEventListener('click', boundRemoveScrollMark)
    // window.addEventListener('touchstart', boundRemoveScrollMark)
    // window.addEventListener('wheel', boundRemoveScrollMark)
    window.addEventListener('scroll', boundRemoveScrollMark)

    checkScrollStimulus()
    checkScrollShadows()
    checkScrollMark()
    // }

    return () => {
      // activityContentRef?.current?.removeEventListener('scroll', boundRemoveScrollMark)
      // if (activityType !== ACTIVITY_TYPES.QUIZ) {
      // window.removeEventListener('click', boundRemoveScrollMark)
      // window.removeEventListener('touchstart', boundRemoveScrollMark)
      // window.removeEventListener('wheel', boundRemoveScrollMark)
      window.removeEventListener('scroll', boundRemoveScrollMark)
      // }

      activityContentRef?.current?.removeEventListener(
        'scroll',
        boundCheckScrollShadows
      )
      window.removeEventListener('resize', handleResize)
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    setActivityStore(exerciseStore)
  }, [exerciseStore])

  useEffect(() => {
    const _isScaffold = currentActivity.type === 'scaffold'

    if (activityType !== ACTIVITY_TYPES.QUIZ) {
      initializeActivity(_isScaffold)
    }

    if (currentActivity && !_isScaffold) {
      updateExercise({
        data: currentActivity,
        lang: exerciseLanguage ? exerciseLanguage.toLowerCase() : 'en'
      })
    }

    if (activityType === ACTIVITY_TYPES.QUIZ) {
      onActivityEvent({
        name: 'Analisys Practice Activity Viewed Quiz',
        properties: {}
      })
    } else {
      onActivityEvent({
        name: 'Activity Viewed',
        properties: {
          is_scaffolding: _isScaffold
        }
      })
    }

    checkScrollStimulus()
    checkScrollShadows()
    checkScrollMark()
    getFooterHeight()

    // Si es pathway se indica, para forzar a obtener tamaño de celda y actualizar en siguiente ciclo
    checkPathwayExercise()
  }, [currentActivity])

  useEffect(() => {
    if (isFeedbackVisible || isResponseSend) {
      scrollActivityContent()
    }
    checkScrollShadows()
    setIsScrollMarkActive(false)
  }, [activityStore, isFeedbackVisible, isResponseSend])

  useEffect(() => {
    if (isFeedbackVisible && (!isScaffold || scaffoldCurrentQuestion?.isMain)) {
      onActivityEvent({
        name: 'Activity Feedback Viewed',
        properties: {
          is_scaffolding: isScaffold
        }
      })
    }
  }, [isFeedbackVisible])

  useEffect(() => {
    const individualFeedbacksBoxes = document.querySelectorAll(
      '[data-testid="individualFeedback"]'
    )
    const individualFeedbacksPinNumbers = document.querySelectorAll(
      '.lemo-feedback-pin-number.lemo-error'
    )
    const individualFeedbackElements = [
      ...individualFeedbacksBoxes,
      ...individualFeedbacksPinNumbers
    ]
    setTimeout(() => {
      individualFeedbackElements.forEach((element) => {
        // Se comprueba si contiene valor lemo-text para no mostrar el feedback si no contiene valor
        // Es encesario un setTimeout porque si no no tiene el valor cargado
        const spanElement = element.querySelector('.lemo-text')

        element.style.display =
          activityStore?.isEvaluated &&
          activityStore?.showSolution === false &&
          spanElement.textContent !== ''
            ? 'flex'
            : 'none'
      })

      if (activityStore?.showSolution) {
        scrollActivityContent()
      }
    }, 100)
  }, [activityStore?.showSolution, activityStore?.isEvaluated])

  const checkScrollPosition = () => {
    const auxReference = activityContentRef.current

    if (auxReference) {
      const isScrolledToBottom =
        Math.abs(
          auxReference.scrollHeight -
            auxReference.scrollTop -
            auxReference.clientHeight
        ) < 2

      const isScrolledToTop = auxReference.scrollTop < 2

      return { isScrolledToTop, isScrolledToBottom }
    }

    return { isScrolledToTop: true, isScrolledToBottom: true }
  }

  // Para asegurar que lo primero que ve el estudiante es el enunciado
  const checkScrollStimulus = () => {
    setTimeout(() => {
      const contentElement = activityContentRef.current
      if (contentElement && contentElement.scrollTop > 0) {
        contentElement.scrollTop = 0
      }
    }, 0)
  }

  const checkScrollShadows = () => {
    if (scrollTimer.current) clearTimeout(scrollTimer.current)

    scrollTimer.current = setTimeout(() => {
      const scrollState = checkScrollPosition()
      setIsBottomScrollShadowActive(!scrollState.isScrolledToBottom)
      setIsTopScrollShadowActive(!scrollState.isScrolledToTop)
    }, 0)
  }

  const checkScrollMark = () => {
    setTimeout(() => {
      const scrollState = checkScrollPosition()
      setIsScrollMarkActive(!scrollState.isScrolledToBottom)
    }, 300)
  }

  const scrollContentAndHideScrollMark = () => {
    setIsScrollMarkActive(false)
    scrollActivityContent()
  }

  const handleResize = () => {
    if (resizeTimer.current) clearTimeout(resizeTimer.current)
    resizeTimer.current = setTimeout(() => {
      getFooterHeight()

      checkScrollShadows()

      const currentWidth = activityRef?.current?.offsetWidth
      if (currentWidth) {
        setActivityWidth(currentWidth)
      }
    }, 100)
  }

  const toogleFeedback = () => {
    setIsFeedbackVisible(!isFeedbackVisible)
  }

  const scrollActivityContent = () => {
    setTimeout(() => {
      const _activityContent = document.querySelector('.activity-view_content')
      if (_activityContent !== null && _activityContent !== undefined) {
        const scrollBehavior = _activityContent.style.scrollBehavior
        _activityContent.style.scrollBehavior = 'smooth'
        _activityContent.scrollTop = _activityContent.scrollHeight
        _activityContent.style.scrollBehavior = scrollBehavior
      }
      // setIsScrollMarkActive(false)
    }, 200)
  }

  const checkPathwayExercise = () => {
    const isPathwayExercise =
      currentActivity?.type === 'pathway' ||
      currentActivity?.type === 'reversepathway'

    if (isPathwayExercise) {
      setTimeout(() => {
        // Se obtiene el tamaño de celda en base al espacio disponible y se actualiza el ejercicio para que se tenga en cuenta
        calculatePathwayCellSize()
        updateExercise({
          data: currentActivity,
          lang: exerciseLanguage ? exerciseLanguage.toLowerCase() : 'en'
        })
      }, 100)
    } else {
      setPathwayCellSize(null)
    }
  }

  const calculatePathwayCellSize = () => {
    let auxCellSize = MINIMUN_PATHWAY_CELL_SIZE
    const contentElement = activityContentRef.current

    // Comprobar posición de las instrucciones
    let noBoardModifierWidth = 0
    let noBoardModifierHeight = 0

    const noBoardElement =
      contentElement.querySelector('.lemo-pathway-instructions') ||
      contentElement.querySelector('.lemo-user-controls')

    const pathwayNoBoard = noBoardElement.getBoundingClientRect()
    const pathwayBoard = contentElement
      .querySelector('.lemo-pathway-board-wrapper')
      .getBoundingClientRect()

    if (
      pathwayNoBoard.right < pathwayBoard.left ||
      pathwayNoBoard.left > pathwayBoard.right
    ) {
      noBoardModifierWidth = pathwayNoBoard.width
    } else {
      noBoardModifierHeight = pathwayNoBoard.height
    }

    // Obtener altura de "area dummy"
    const dummyElement = contentElement.querySelector('.dummy-activity')
    const dummyHeight = dummyElement ? dummyElement.clientHeight : 0

    // Obtener altura de la cabecera de la pregunta
    const questionHeaderHeight = contentElement.querySelector(
      '.lemo-question-header'
    ).clientHeight

    // Obtener espacio disponible para el tablero
    const availableHeight =
      contentElement.clientHeight -
      dummyHeight -
      questionHeaderHeight -
      noBoardModifierHeight

    const availableWidth = contentElement.clientHeight - noBoardModifierWidth

    const cellHeightSize = availableHeight / currentActivity.data.board.rows
    const cellWidthSize = availableWidth / currentActivity.data.board.columns

    const calculatedCellSize = Math.floor(
      Math.min(cellHeightSize, cellWidthSize) * 0.88
    )

    if (calculatedCellSize > auxCellSize) {
      auxCellSize = calculatedCellSize
    }

    setPathwayCellSize(auxCellSize)
  }

  const getFooterHeight = () => {
    setTimeout(() => {
      let auxFooterHeight = 0
      if (activityRef?.current) {
        const footerElement =
          activityRef?.current.querySelector('.activity-footer')
        if (footerElement) {
          auxFooterHeight = footerElement.clientHeight
        }
      }

      setFooterHeight(auxFooterHeight)
    }, 250)
  }

  const onKeyboardVisible = (_data) => {
    // eslint-disable-next-line no-unneeded-ternary, no-extra-boolean-cast
    setShowActivityFooter(!!_data ? false : true)
  }

  return {
    showActivityFooter,
    activityWidth,
    footerHeight,
    isTopScrollShadowActive,
    isScrollMarkActive,
    isBottomScrollShadowActive,
    pathwayCellSize,
    toogleFeedback,
    scrollContentAndHideScrollMark,
    onKeyboardVisible
  }
}
