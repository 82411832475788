import React, { useEffect, useState, useRef } from 'react';

import {
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Menu,
  MenuItem
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import './yearSelector.scss';

const YearSelector = ({ programs, onChangeProgram, selectedProgram }) => {
  useEffect(() => {
    console.log('SELECTOR programs', programs);
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (program, index) => {
    onChangeProgram(program);
    setAnchorEl(null);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const listItemRef = useRef(null);

  return (
    <div className='year-selector__container'>
      <List component='nav'>
        <ListItemButton
          id='year-selector'
          onClick={handleClickListItem}
          className='year-selector__menu-button'
          disableRipple
          ref={listItemRef}
        >
          <ListItemText
            primary={`${selectedProgram?.education_year?.name || ''} ${
              selectedProgram?.education_level?.name || ''
            }`}
          />
          <ListItemIcon>
            <KeyboardArrowDownIcon />
          </ListItemIcon>
        </ListItemButton>
      </List>
      <Menu
        id='year-selector-menu'
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        className='year-selector__menu'
      >
        {programs.map((program, index) => (
          <MenuItem
            className='year-selector__menu-item'
            key={program?.program_guid}
            selected={program?.program_guid === selectedProgram?.program_guid}
            onClick={() => handleMenuItemClick(program, index)}
            style={{
              minWidth: listItemRef.current
                ? listItemRef.current.offsetWidth - 20
                : 0
            }}
          >
            {`${program?.education_year?.name} ${program?.education_level?.name}`}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default YearSelector;
