import { flattenDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCourse } from '../../../../utils/contexts/CourseContext';
import {
  addQuestion,
  deleteQuestion,
  deleteQuiz,
  getQuizContent,
  getStandards,
  regenerateQuestion,
  reorderQuestions,
  startQuiz
} from '../../../../utils/crud/quiz';
import QuizToast from './components/quizToast/QuizToast';

const useViewQuiz = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { quizGuid } = useParams();
  const { selectedCourse, availableCourses } = useCourse();

  const [quizName, setQuizName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [educationYearName, setEducationYearName] = useState('');
  const [lang, setLang] = useState('');
  const [selectedTab, setSelectedTab] = useState('activities');
  const [topics, setTopics] = useState([]);
  const [subTopics, setSubtopics] = useState([]);
  const [canDelete, setCanDelete] = useState(false);
  const [lastGameGuid, setLastGameGuid] = useState(null);
  const [isDeletingQuestion, setIsDeletingQuestion] = useState(false);
  const [isRegeneratingQuestion, setIsRegeneratingQuestion] = useState(false);
  const [questionBeingRegenerated, setQuestionBeingRegenerated] =
    useState(null);
  const [isAddingQuestion, setIsAddingQuestion] = useState(false);
  const [standards, setStandards] = useState([]);
  const [standardsByProgram, setStandardsByProgram] = useState(null);
  const [startsWithValuesStandards, setStartsWithValuesStandards] = useState(
    []
  );
  const [isEditingNameDialogOpen, setIsEditingNameDialogOpen] = useState(false);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);
  const [hasResults, setHasResults] = useState(false);

  const [isConfirmPlayDialogOpen, setIsConfirmPlayDialogOpen] = useState(false);

  const TABS = [
    {
      name: t('Activities'),
      id: 'activities'
    },
    {
      name: t('Results'),
      id: 'results'
    }
  ];

  const toastSettings = {
    position: 'bottom-left',
    autoClose: 3000,
    hideProgressBar: true
  };

  const initialize = async () => {
    let listOfStandards = standards;

    const flattenChildren = (item) => {
      if (item.children) {
        return item.children.map((child) => {
          return flattenChildren(child);
        });
      } else {
        return item;
      }
    };

    if (listOfStandards.length === 0) {
      let responseOfStandards = await getStandards();
      listOfStandards = responseOfStandards?.standars || [];
      listOfStandards = flattenDeep(
        Object.keys(listOfStandards).map((key) =>
          flattenChildren(listOfStandards[key])
        )
      );
      setStandards(listOfStandards);
    }

    let response = await getQuizContent({ quizGuid });
    setQuizName(response?.name);
    setLastGameGuid(response?.last_game_guid);

    const filteredQuestions = (response?.questions || [])
      .map((question) => {
        const unitGuid = (response?.lessons || []).filter(
          (lesson) => lesson?.guid === question?.lesson?.guid
        )[0]?.unit_guid;

        const parentUnit = (response?.units || []).filter(
          (unit) => unit?.guid === unitGuid
        )[0];

        return {
          ...question,
          standards: (question?.standars || []).map((standard) => {
            const standardObj = listOfStandards.find(
              (std) => std?.guid === standard
            );

            return {
              guid: standardObj?.guid || standard,
              name: standardObj?.name || standard,
              description: standardObj?.skills || standard
            };
          }),
          unit: {
            name: parentUnit?.name,
            guid: parentUnit?.guid
          },
          educationYearTag: parentUnit?.educationYear?.year?.split(' ')[0]
        };
      })
      .filter((item) => item?.question?.status !== 'error')
      .map((item) => {
        if (item?.question.type === 'scaffold') {
          return { ...item, question: item?.question?.questions[0] };
        } else {
          return item;
        }
      });

    setQuestions(filteredQuestions);
    setNumberOfQuestions(filteredQuestions.length);

    setSubtopics(
      response.lessons.map((lesson) => {
        return {
          name: lesson.name,
          guid: lesson.guid,
          unitName: response.units.filter(
            (unit) => unit.guid === lesson.unit_guid
          )[0].name,
          unitGuid: lesson.unit_guid,
          educationYear: response.units
            .filter((unit) => unit.guid === lesson.unit_guid)[0]
            .educationYear.year.split(' ')[0]
        };
      })
    );
    setLang(response.lang_id);
    setCanDelete(Boolean(response.last_game_guid));
    setHasResults(Boolean(response.last_game_guid));

    setIsDeletingQuestion(false);

    setIsLoading(false);
  };

  const onRegenerateQuestion = async ({ questionGuid }) => {
    setIsRegeneratingQuestion(true);
    setQuestionBeingRegenerated(questionGuid);
    const regenerateQuestionResponse = await regenerateQuestion({
      courseGuid: selectedCourse.guid,
      questionGuid
    });

    await initialize();
    setQuestionBeingRegenerated(null);
    setIsRegeneratingQuestion(false);

    toast(<QuizToast variant='regenerate-success' />, toastSettings);
  };

  const onStartQuiz = async () => {
    await startQuiz({
      courseGuid: selectedCourse.guid,
      quizGuid
    });

    alert('Quiz Started');
  };

  const onDeleteQuestion = async ({ questionGuid }) => {
    setIsDeletingQuestion(true);
    const deleteQuestionResponse = await deleteQuestion({
      courseGuid: selectedCourse.guid,
      quizGuid,
      questionGuids: [questionGuid]
    });

    await initialize();

    toast(<QuizToast variant='delete-quiz-activity-success' />, toastSettings);
  };

  const generateToastMessage = (numberOfActivities, numberOfLessons) => {
    let translationKey = '';

    if (numberOfActivities === 1) {
      translationKey =
        numberOfLessons === 0
          ? 'single_activity_no_topic_toast'
          : 'single_activity_single_topic_toast';
    } else {
      translationKey =
        numberOfLessons === 0
          ? 'plural_activities_no_topic_toast'
          : numberOfLessons === 1
          ? 'plural_activities_single_topic_toast'
          : 'plural_activities_plural_topic_toast';
    }

    return t(translationKey, {
      count: numberOfActivities,
      topics: numberOfLessons
    });
  };

  const onAddActivities = async ({ onClose, selectedLessonsToAdd }) => {
    setIsAddingQuestion(true);
    await addQuestion({
      courseGuid: selectedCourse.guid,
      quizGuid,
      lessons: selectedLessonsToAdd.map((lesson) => ({
        guid: lesson.guid,
        number_of_questions: lesson.numberOfActivitiesToAdd
      }))
    });

    // get number of unique lessons added to quiz
    const uniqueLessons = selectedLessonsToAdd
      .filter(
        (lesson, index, self) =>
          index === self.findIndex((t) => t.guid === lesson.guid)
      )
      .filter(
        (lesson) => !subTopics.find((subTopic) => subTopic.guid === lesson.guid)
      );

    const totalQuestionsToAdd = selectedLessonsToAdd.reduce(
      (acc, lesson) => acc + lesson.numberOfActivitiesToAdd,
      0
    );

    await initialize();

    toast(
      <QuizToast
        variant={'add-activities-success'}
        text={generateToastMessage(totalQuestionsToAdd, uniqueLessons.length)}
      />,
      toastSettings
    );

    onClose();
    setIsAddingQuestion(false);
  };

  useEffect(() => {
    setIsLoading(true);
    initialize();
  }, [quizGuid]);

  useEffect(() => {
    if (!selectedCourse) return;
    setEducationYearName(
      `${selectedCourse.school_group_education_year_name} ${selectedCourse.school_group_education_level_name}`
    );
  }, [selectedCourse]);

  const onChangeTab = (tab) => {
    if (tab === 'results' && lastGameGuid == null) {
      // setIsLoading(true);
      initialize();
    }
    setSelectedTab(tab);
  };

  const onChangePositionOfQuestion = async ({ newIndex, oldIndex }) => {
    let newOrderOfQuestions = [...questions];
    newOrderOfQuestions.splice(
      newIndex,
      0,
      newOrderOfQuestions.splice(oldIndex, 1)[0]
    );
    setQuestions(newOrderOfQuestions);
    await reorderQuestions({
      courseGuid: selectedCourse.guid,
      quizGuid,
      questions: newOrderOfQuestions.map((item, index) => ({
        guid: item.guid,
        order: index
      }))
    });

    initialize();
  };

  const loadStandardsByProgram = async () => {
    // Se obtienen los standars de los programas
    const courses = availableCourses;
    let standardsByProgramApi = null;
    if (!Array.isArray(courses)) {
      standardsByProgramApi = courses?.bb_program_standard;
    } else {
      for (const courseIndex in courses) {
        standardsByProgramApi = courses[courseIndex]?.bb_program_standard;
      }
    }
    setStandardsByProgram(standardsByProgramApi);
    if (standardsByProgramApi && standardsByProgramApi === 'BNCC') {
      setStartsWithValuesStandards((prevState) => [...prevState, 'EF']);
    }

    if (standardsByProgramApi && standardsByProgramApi === 'CC') {
      setStartsWithValuesStandards((prevState) => [...prevState, 'CCSS']);
    }
  };

  const onConfirmEditName = async ({ name }) => {
    setQuizName(name);
    setIsEditingNameDialogOpen(false);
  };

  const onCancelEditingName = () => {
    setIsEditingNameDialogOpen(false);
  };

  const onEditNameClick = () => {
    setIsEditingNameDialogOpen(true);
  };

  const onDeleteQuizClick = () => {
    setIsConfirmDeleteDialogOpen(true);
  };

  const onConfirmDeleteQuiz = async () => {
    let response = await deleteQuiz({ quizGuid });

    // validate quiz was deleted
    if (!response?.guid?.includes(quizGuid)) {
      toast(<QuizToast variant='quiz-generic-error' />, toastSettings);
      setIsConfirmDeleteDialogOpen(false);
      return;
    }

    // set in localstorage a flag to show a toast in the quizzes page
    localStorage.setItem('deletedQuizFlag', 'true');

    navigate('/quizzes');
  };

  const onCancelDeleteQuiz = () => {
    setIsConfirmDeleteDialogOpen(false);
  };

  const onPlayQuizClick = () => {
    if (hasResults) {
      setIsConfirmPlayDialogOpen(true);
    } else {
      navigate(`/quizzes/play/${quizGuid}`);
    }
  };

  const onConfirmPlayQuiz = () => {
    navigate(`/quizzes/play/${quizGuid}`);
    setIsConfirmPlayDialogOpen(false);
  };

  const onCancelPlayQuiz = () => {
    setIsConfirmPlayDialogOpen(false);
  };

  useEffect(() => {
    loadStandardsByProgram();
  }, []);

  return {
    quizGuid,
    quizName,
    lastGameGuid,
    isLoading,
    questions,
    numberOfQuestions,
    educationYearName,
    lang,
    topics,
    subTopics,
    selectedTab,
    tabs: TABS,
    educationYear: selectedCourse?.education_year_name,
    canDelete,
    isDeletingQuestion,
    questionBeingRegenerated,
    isRegeneratingQuestion,
    isAddingQuestion,
    standards,
    onRegenerateQuestion,
    onStartQuiz,
    onChangeTab,
    onAddActivities,
    onChangePositionOfQuestion,
    onDeleteQuestion,
    onConfirmEditName,
    onCancelEditingName,
    isEditingNameDialogOpen,
    standardsByProgram,
    onEditNameClick,
    startsWithValuesStandards,
    isConfirmDeleteDialogOpen,
    onConfirmDeleteQuiz,
    onCancelDeleteQuiz,
    onDeleteQuizClick,
    isConfirmPlayDialogOpen,
    onConfirmPlayQuiz,
    onCancelPlayQuiz,
    onPlayQuizClick
  };
};

export default useViewQuiz;
