import XRayChart from '../../../components/xRayChart/XRayChart';
import PageWithHeader from '../../../layouts/pageWithHeader/PageWithHeader';

import { useTranslation } from 'react-i18next';
import PageTitleWithTooltip from '../../../components/pageTitleWithTooltip/PageTitleWithTooltip';
import useXRay from './useXRay';

const XRay = () => {
  const {
    xRay,
    progressRange,
    effortRange,
    onClickStudent,
    onClickStudentDetail
  } = useXRay();

  const { t } = useTranslation();

  return (
    <PageWithHeader
      left={
        <PageTitleWithTooltip title={t('x-ray')} tooltip={t('x-ray_info')} />
      }
    >
      {xRay && (
        <XRayChart
          data={xRay}
          progressRange={progressRange}
          effortRange={effortRange}
          onClickStudent={onClickStudent}
          onClickStudentDetail={onClickStudentDetail}
        />
      )}
    </PageWithHeader>
  );
};

export default XRay;
