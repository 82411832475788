import { createColumnHelper } from '@tanstack/react-table';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ColumnTitleWithTooltip from '../../../components/table/cell/cellTypes/ColumnTitleWithTooltip';
import { AnalysisSegment } from '../../../utils/analysis';
import { CourseContext } from '../../../utils/contexts/CourseContext';
import { getStudents } from '../../../utils/crud/student';
import { tryJsonParse } from '../../../utils/helpers/tryJsonParse';
import { useLocalStorage } from '../../../utils/hooks/useLocalStorage';
import CellStudentEngagement from './components/CellStudentEngagement/CellStudentEngagement';
import CellStudentMasteredContent from './components/CellStudentMasteredContent/CellStudentMasteredContent';
import CellStudentNameWithHelpIcon from './components/CellStudentNameWithHelpIcon/CellStudentNameWithHelpIcon';
import CellStudentPerformance from './components/CellStudentPerformance/CellStudentPerformance';
import StudentsToast from './components/StudentsToast/StudentsToast';
import {
  MOCK_DATA_MONTH,
  MOCK_DATA_TERM,
  MOCK_DATA_WEEK
} from './studentsMockData';
import useStudentsDataParsers from './useStudentsDataParsers';
import useStudentsSorting from './useStudentsSorting';

const useStudentsPage = (isMock) => {
  const DEFAULT_TIME_RANGE = 7;
  const DEFAULT_ORDER_BY = 'lastname';

  const location = useLocation();
  const navigate = useNavigate();
  const { setItem, getItem } = useLocalStorage();
  const { selectedCourse } = useContext(CourseContext);
  const { t } = useTranslation();

  const {
    getUserEngagementData,
    getUserPerformanceData,
    getUserMasteredContentData
  } = useStudentsDataParsers();

  const { sortData } = useStudentsSorting();

  const [isLoading, setIsLoading] = useState(false);
  const [programLanguage, setProgramLanguage] = useState('es');

  const columnHelper = createColumnHelper();

  const [usersData, setUsersData] = useState([]);
  const [numberOfKcs, setNumberOfKcs] = useState();
  const [numberOfLastDays, setNumberOfLastDays] = useState();
  const [hasEngagementHours, setHasEngagementHours] = useState(false);
  const [selectedTimeRange, setSelectedTimeRange] = useState();
  const [selectedOrderBy, setSelectedOrderBy] = useState();

  // Check if a course is selected and send Analytics
  useEffect(() => {
    if (!selectedCourse) return;
    setProgramLanguage(selectedCourse?.program_lang);

    AnalysisSegment.sendSegmentTrackEvent(
      AnalysisSegment.SEGMENT_EVENTS.Content_Page_Viewed,
      {
        class: selectedCourse?.guid,
        program_id: selectedCourse?.program_guid,
        students: selectedCourse?.users?.length
      }
    );
  }, [selectedCourse]);

  // Manage change in time range
  useEffect(() => {
    if (!selectedTimeRange) return;
    // Save filters in localstorage
    const filterKey = location.pathname;
    setItem(
      filterKey,
      JSON.stringify({
        timeRange: selectedTimeRange,
        orderBy: selectedOrderBy
      })
    );

    toast.dismiss();
    setTimeout(() => {
      toast(<StudentsToast type='timeRange' textKey={selectedTimeRange} />, {
        position: 'bottom-left',
        autoClose: 8000,
        hideProgressBar: true
      });
    }, 300);
  }, [selectedTimeRange]);

  // Manage change in order
  useEffect(() => {
    if (!selectedOrderBy) return;
    // Save filters in localstorage
    const filterKey = location.pathname;
    setItem(
      filterKey,
      JSON.stringify({
        timeRange: selectedTimeRange,
        orderBy: selectedOrderBy
      })
    );

    toast.dismiss();
    setTimeout(() => {
      toast(<StudentsToast type='orderBy' textKey={selectedOrderBy} />, {
        position: 'bottom-left',
        autoClose: 6000,
        hideProgressBar: true
      });
    }, 300);
  }, [selectedOrderBy]);

  // Go to detail
  const onClickOnStudent = (studentGuid) => {
    navigate(`/students/${studentGuid}`);
  };

  // Table definition
  const columns = [
    columnHelper.accessor('lastname', {
      id: 'Student',
      header: t('student'),
      enableSorting: false,
      cell: (props) => (
        <CellStudentNameWithHelpIcon
          name={props.row.original?.name}
          lastName={props.row.original?.lastname}
          needsHelpInfo={props.row.original?.needHelpLoPhase}
          onClickOnStudent={() => onClickOnStudent(props.row.original?.guid)}
        />
      )
    }),
    columnHelper.accessor('engagement', {
      header: (
        <ColumnTitleWithTooltip
          title={t('engagement')}
          tooltip={t('engagement_tooltip')}
        />
      ),
      enableSorting: false,
      cell: (props) => (
        <CellStudentEngagement
          selectedTimeRange={selectedTimeRange}
          studentEngagementData={getUserEngagementData(
            props.row.original,
            numberOfLastDays,
            hasEngagementHours
          )}
        />
      )
    }),
    columnHelper.accessor('performance', {
      header: (
        <ColumnTitleWithTooltip
          title={t('performance')}
          tooltip={t('performance_tooltip')}
        />
      ),
      enableSorting: false,
      cell: (props) => (
        <CellStudentPerformance
          studentPerformanceData={getUserPerformanceData(
            props.row.original?.activities
          )}
        />
      )
    }),
    columnHelper.accessor('masteredContent', {
      header: (
        <ColumnTitleWithTooltip
          title={t('mastered_content')}
          tooltip={t('mastered_content_tooltip')}
        />
      ),
      enableSorting: false,
      cell: (props) => (
        <CellStudentMasteredContent
          selectedTimeRange={selectedTimeRange}
          studentMasteredContentData={getUserMasteredContentData(
            props.row.original,
            numberOfKcs
          )}
        />
      )
    })
  ];

  // Helpers
  const apiCallSimulation = (timeRange) => {
    let apiData;
    switch (timeRange) {
      case 7:
        apiData = MOCK_DATA_WEEK;
        break;
      case 30:
        apiData = MOCK_DATA_MONTH;
        break;
      case 90:
        apiData = MOCK_DATA_TERM;
        break;
      default:
        apiData = MOCK_DATA_WEEK;
        break;
    }
    return apiData;
  };

  // Get data
  const getData = async (timeRange, orderBy) => {
    if (isLoading) return;
    setIsLoading(true);

    let tempData = await getStudents({
      courseGuid: selectedCourse?.guid,
      days: timeRange,
      lang: selectedCourse?.program_lang
    });

    let apiData;
    if (isMock) {
      apiData = apiCallSimulation(timeRange);
    } else {
      apiData = tempData || [];
    }

    console.log('🚀 getData', apiData);

    // Check if there is a user with more than an hour of activity time logged in order to show hours in engagement column
    setHasEngagementHours(
      apiData?.users?.some((user) => user?.totalTimeInSeconds > 3600)
    );
    setNumberOfLastDays(apiData?.numberOfLastDays);
    setNumberOfKcs(apiData?.program?.numberOfKcs);

    // Order users data by filter
    const sortedUsers = sortData(apiData?.users, orderBy);

    setUsersData(sortedUsers);
    setIsLoading(false);
  };

  // Get initial data
  useEffect(() => {
    if (!selectedCourse?.guid) return;

    let timeRange = DEFAULT_TIME_RANGE;
    let orderBy = DEFAULT_ORDER_BY;

    // get filters from local storage if available
    const filterKey = location.pathname;
    let filter = getItem(filterKey);

    if (filter) {
      try {
        filter = tryJsonParse(filter);
        timeRange = filter.timeRange || DEFAULT_TIME_RANGE;
        orderBy = filter.orderBy || DEFAULT_ORDER_BY;
      } catch (e) {
        filter = {};
      }
    }

    setSelectedTimeRange(timeRange);
    setSelectedOrderBy(orderBy);
    getData(timeRange, orderBy);
  }, [selectedCourse, selectedTimeRange]);

  useEffect(() => {
    // Since the ordering is local, we need to sort the data without issuing a new request.
    const sortedUsers = sortData(usersData, selectedOrderBy);
    setUsersData(sortedUsers);
  }, [selectedOrderBy]);

  useEffect(() => {
    // Hide toasts when exiting section
    return () => {
      toast.dismiss();
    };
  }, []);

  return {
    usersData,
    columns,
    isLoading: isLoading,
    onClickOnStudent,
    selectedTimeRange,
    setSelectedTimeRange,
    selectedOrderBy,
    setSelectedOrderBy
  };
};

export default useStudentsPage;
