import { Tooltip } from '@mui/material';
import './TooltipCell.scss';

const TooltipCell = ({ children, ...props }) => {
  return (
    <div className='tooltip-cell'>
      <Tooltip
        {...props}
        classes={{ popper: 'tooltip-cell__tooltip' }}
        enterTouchDelay={0}
      >
        {children}
      </Tooltip>
    </div>
  );
};

export default TooltipCell;
