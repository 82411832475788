import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import './AlertDialog.scss';

export default function AlertDialog({
  title,
  description,
  isOpen,
  onCancel,
  onConfirm,
  confirmText,
  cancelText,
  className,
  iconButtonConfirm
}) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    if (onCancel) onCancel();
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog open={open} onClose={handleClose} className={className}>
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className='alert-dialog_button alert-dialog_button-cancel'
          onClick={onCancel}
        >
          {cancelText}
        </Button>
        <Button
          className='alert-dialog_button alert-dialog_button-confirm'
          onClick={onConfirm}
          autoFocus
        >
          {iconButtonConfirm}
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
