import InfoTooltip from '../../atoms/tooltip/InfoTooltip';

import { Typography } from '@mui/material';
import './PageTitleWithTooltip.scss';

const PageTitleWithTooltip = ({ title, tooltip }) => {
  return (
    <div className='page-title-with-tooltip__container'>
      <Typography variant='h3' className='page-title-with-tooltip__title'>
        {title}
      </Typography>
      {tooltip && (
        <div className='page-title-with-tooltip__tooltip'>
          <InfoTooltip text={tooltip} />
        </div>
      )}
    </div>
  );
};

export default PageTitleWithTooltip;
