import React from 'react';
import { useTranslation } from 'react-i18next';
import './questionAnswerCounter.scss';

const PlayersIcon = () => {
  return (
    <svg
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Icon/Solid/user-group'>
        <g id='Icon'>
          <path
            d='M15.6 7.69961C15.6 9.68783 13.9882 11.2996 12 11.2996C10.0118 11.2996 8.40001 9.68783 8.40001 7.69961C8.40001 5.71138 10.0118 4.09961 12 4.09961C13.9882 4.09961 15.6 5.71138 15.6 7.69961Z'
            fill='white'
          />
          <path
            d='M21.6 10.0996C21.6 11.4251 20.5255 12.4996 19.2 12.4996C17.8745 12.4996 16.8 11.4251 16.8 10.0996C16.8 8.77413 17.8745 7.69961 19.2 7.69961C20.5255 7.69961 21.6 8.77413 21.6 10.0996Z'
            fill='white'
          />
          <path
            d='M16.8 18.4996C16.8 15.8486 14.651 13.6996 12 13.6996C9.34904 13.6996 7.20001 15.8486 7.20001 18.4996V22.0996H16.8V18.4996Z'
            fill='white'
          />
          <path
            d='M7.20001 10.0996C7.20001 11.4251 6.1255 12.4996 4.80001 12.4996C3.47453 12.4996 2.40001 11.4251 2.40001 10.0996C2.40001 8.77413 3.47453 7.69961 4.80001 7.69961C6.1255 7.69961 7.20001 8.77413 7.20001 10.0996Z'
            fill='white'
          />
          <path
            d='M19.2 22.0996V18.4996C19.2 17.2346 18.8738 16.0459 18.3009 15.0128C18.5883 14.9389 18.8896 14.8996 19.2 14.8996C21.1882 14.8996 22.8 16.5114 22.8 18.4996V22.0996H19.2Z'
            fill='white'
          />
          <path
            d='M5.69913 15.0128C5.12623 16.0459 4.80001 17.2346 4.80001 18.4996V22.0996H1.20001V18.4996C1.20001 16.5114 2.81179 14.8996 4.80001 14.8996C5.11046 14.8996 5.41174 14.9389 5.69913 15.0128Z'
            fill='white'
          />
        </g>
      </g>
    </svg>
  );
};

const QuestionAnswerCounter = ({
  totalAnswers,
  totalPlayers,
  isTimeFinished
}) => {
  const { t } = useTranslation();
  return (
    <div className='question-answer-counter'>
      <PlayersIcon />
      {isTimeFinished
        ? ` ${totalAnswers}`
        : ` ${totalAnswers} ${t('quiz_of')} ${totalPlayers}`}
    </div>
  );
};

export default QuestionAnswerCounter;
