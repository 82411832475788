import { useContext } from 'react'
import {
  ACTIVITY_ACTIONS,
  ACTIVITY_TYPES
} from '../../constants/ActivityConstants'
import { ActivityContext } from './ActivityContext'

export function useActivityContext() {
  const context = useContext(ActivityContext)

  if (!context) {
    throw new Error(
      'useActivityContext must be used within an ActivityProvider'
    )
  }

  const { state, dispatch } = context

  const setActivityType = (activityType) => {
    dispatch({
      type: ACTIVITY_ACTIONS.SET_ACTIVITY_TYPE,
      payload: {
        activityType: activityType,
        isPractice: activityType === ACTIVITY_TYPES.PRACTICE
      }
    })
  }

  const setActivity = (activity) => {
    dispatch({ type: ACTIVITY_ACTIONS.SET_ACTIVITY, payload: activity })
  }

  const setActivityStore = (activityStore) => {
    dispatch({
      type: ACTIVITY_ACTIONS.SET_ACTIVITY_STORE,
      payload: activityStore
    })
  }

  const initializeActivity = (isScaffold) => {
    dispatch({
      type: ACTIVITY_ACTIONS.INITIALIZE_ACTIVITY,
      payload: isScaffold
    })
  }

  const setIsCheckButtonEnabled = (isEnabled) => {
    dispatch({
      type: ACTIVITY_ACTIONS.SET_IS_CHECK_BUTTON_ENABLED,
      payload: isEnabled
    })
  }

  const setIsAnswerCorrect = (isCorrect) => {
    dispatch({
      type: ACTIVITY_ACTIONS.SET_IS_ANSWER_CORRECT,
      payload: isCorrect
    })
  }

  const setIsSolutionShowed = (isShowed) => {
    dispatch({
      type: ACTIVITY_ACTIONS.SET_IS_SOLUTION_SHOWED,
      payload: isShowed
    })
  }

  const setIsHintUsed = (isHintUsed) => {
    dispatch({ type: ACTIVITY_ACTIONS.SET_IS_HINT_USED, payload: isHintUsed })
  }

  const setIsScaffold = (isScaffold) => {
    dispatch({ type: ACTIVITY_ACTIONS.SET_IS_SCAFFOLD, payload: isScaffold })
  }

  const setScaffoldCurrentQuestion = (scaffoldCurrentQuestion) => {
    dispatch({
      type: ACTIVITY_ACTIONS.SET_SCAFFOLD_CURRENT_QUESTION,
      payload: scaffoldCurrentQuestion
    })
  }

  const setScaffoldNext = (scaffoldNextFunction) => {
    dispatch({
      type: ACTIVITY_ACTIONS.SET_SCAFFOLD_NEXT_FUNCTION,
      payload: scaffoldNextFunction
    })
  }

  const setIsScaffoldComplete = (isScaffoldComplete) => {
    dispatch({
      type: ACTIVITY_ACTIONS.SET_SCAFFOLD_IS_COMPLETED,
      payload: isScaffoldComplete
    })
  }

  const setIsDemoMode = (isDemoMode) => {
    dispatch({
      type: ACTIVITY_ACTIONS.SET_DEMO_MODE,
      payload: isDemoMode
    })
  }

  const setIsDisabledActivity = (isDisabled) => {
    dispatch({
      type: ACTIVITY_ACTIONS.SET_IS_DISABLED_ACTIVITY,
      payload: isDisabled
    })
  }

  return {
    activityType: state.activityType,
    isPracticeType: state.isPracticeType,
    setActivityType,
    activity: state.activity,
    setActivity,
    activityStore: state.activityStore,
    initializeActivity,
    setActivityStore,
    isCheckButtonEnabled: state.isCheckButtonEnabled,
    setIsCheckButtonEnabled,
    isAnswerCorrect: state.isAnswerCorrect,
    setIsAnswerCorrect,
    isScaffold: state.isScaffold,
    isSolutionShowed: state.isSolutionShowed,
    setIsSolutionShowed,
    isHintUsed: state.isHintUsed,
    setIsHintUsed,
    setIsScaffold,
    scaffoldCurrentQuestion: state.scaffoldCurrentQuestion,
    setScaffoldCurrentQuestion,
    scaffoldNext: state.scaffoldNext,
    setScaffoldNext,
    isScaffoldComplete: state.isScaffoldComplete,
    setIsScaffoldComplete,
    isDemoMode: state.isDemoMode,
    setIsDemoMode,
    setIsDisabledActivity,
    isDisabledActivity: state.isDisabledActivity
  }
}
