import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../utils/contexts/AuthContext';

const PublicRoute = () => {
  const { isLoggedIn } = useContext(AuthContext);

  if (isLoggedIn) {
    return <Navigate to={`/${window.location.search}`} />;
  }

  return <Outlet />;
};

export default PublicRoute;
