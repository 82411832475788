import { getBBTeacherUserLanguage } from '../../../utils/helpers/userLanguage';

const useStudentsSorting = () => {
  const DEFAULT_SORT = 'lastname';

  // Sorting functions
  const sortUsersByLastname = (users) => {
    const userLanguage = getBBTeacherUserLanguage();
    const tempUsers = users?.sort((a, b) => {
      const aLastname = a.lastname || '';
      const bLastname = b.lastname || '';

      return aLastname.localeCompare(bLastname, userLanguage, {
        sensitivity: 'base'
      });
    });

    return tempUsers || [];
  };

  const sortUsersByEngagement = (users) => {
    const tempUsers = users?.sort((a, b) => {
      const aTime = a.totalTimeInSeconds || 0;
      const bTime = b.totalTimeInSeconds || 0;
      return bTime - aTime;
    });
    return tempUsers || [];
  };

  const sortUsersByMasteredContent = (users) => {
    const tempUsers = users?.sort((a, b) => {
      const aMasteredKcs = a.masteredKcs || 0;
      const bMasteredKcs = b.masteredKcs || 0;
      return bMasteredKcs - aMasteredKcs;
    });
    return tempUsers || [];
  };

  const sortFunctions = {
    lastname: sortUsersByLastname,
    engagement: sortUsersByEngagement,
    masteredContent: sortUsersByMasteredContent
  };

  const sortData = (data, orderBy = DEFAULT_SORT) => {
    const sortFunction = sortFunctions[orderBy] || sortFunctions[DEFAULT_SORT];
    return Array.from(sortFunction(data) || []);
  };

  return {
    sortData
  };
};

export default useStudentsSorting;
