import React from 'react';
import './gameQuizButton.scss';

const GameQuizButton = ({ text, onClick, disabled, outline }) => {
  return (
    <div
      className={`game-quiz-button ${
        disabled ? 'game-quiz-button--disabled' : 'game-quiz-button--enabled'
      }${outline ? ' game-quiz-button--outline' : ''}`}
      onClick={onClick}
    >
      {text}
    </div>
  );
};

export default GameQuizButton;
