import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { CourseContext } from '../../../../utils/contexts/CourseContext';
import { getTeacherDashboardUnits } from '../../../../utils/crud/teacherDashboard';
import { getBBTeacherUserLanguage } from '../../../../utils/helpers/userLanguage';

const useClassProgressUnits = () => {
  const [isPreloading, setIsPreloading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const { selectedCourse } = useContext(CourseContext);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const getData = async () => {
    setIsLoading(true);
    let response = await getTeacherDashboardUnits({
      lang: getBBTeacherUserLanguage(),
      courseGuid: selectedCourse.guid,
      ...(searchParams.get('unit')
        ? { unitGuid: searchParams.get('unit') }
        : {})
    });

    const apiResponse = response?.detail || [];

    const parsedData = apiResponse.map((item, index) => {
      const masteredData = item.data.filter((i) => i.key === 'mastered')[0];
      const inProgressData = item.data.filter((i) => i.key === 'inProgress')[0];
      const needHelpData = item.data.filter((i) => i.key === 'needHelp')[0];
      const needMotivationData = item.data.filter(
        (i) => i.key === 'needMotivation'
      )[0];

      return {
        name: item.name,
        label:
          searchParams.get('unit') === null
            ? t('unit') + ' ' + (index + 1)
            : t('lesson') + ' ' + (index + 1),
        mastered: masteredData?.value,
        masteredStudents: masteredData?.students,
        inProgress: inProgressData?.value,
        inProgressStudents: inProgressData?.students,
        needHelp: needHelpData?.value,
        needHelpStudents: needHelpData?.students,
        needMotivation: needMotivationData?.value,
        needMotivationStudents: needMotivationData?.students
      };
    });
    setData(parsedData);
    setIsLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsPreloading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (isPreloading || !selectedCourse?.guid) return;
    getData();
  }, [isPreloading, selectedCourse, searchParams.get('unit')]);

  return {
    data,
    isLoading
  };
};

export default useClassProgressUnits;
