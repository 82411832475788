import { useEffect, useState } from 'react';

const usePlayQuizQuestion = ({
  sendShowSolution,
  setCurrentQuestion,
  currentQuestion,
  playerList,
  correctAnswers,
  playSoundEffect
}) => {
  useEffect(() => {
    if (currentQuestion === null && currentQuestion > 0) setCurrentQuestion(0);
  }, []);

  const [isTimeFinished, setIsTimeFinished] = useState(false);

  const forceTimeFinish = () => {
    sendShowSolution();
    setIsTimeFinished(true);
    playSoundEffect('powerUp3');
  };

  const handleTimeEnd = () => {
    sendShowSolution();
    setIsTimeFinished(true);
    playSoundEffect('powerUp3');
  };

  const correctPlayerList = playerList?.filter((player) =>
    correctAnswers.includes(player.user_guid)
  );

  return { isTimeFinished, forceTimeFinish, handleTimeEnd, correctPlayerList };
};

export default usePlayQuizQuestion;
