import React from 'react';
import Table from '../../../components/table/Table';
import FullPageLayout from '../../../layouts/dashboardFullPage/FullPageLayout';
import useStandardDetails from './useStandardDetails';
import { IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

const StandardDetails = () => {
  const { data, columns, standard, isLoading } = useStandardDetails();

  return (
    <FullPageLayout
      type='teacher'
      left={
        <IconButton variant='transparent' onClick={() => history.back()}>
          <ArrowBack sx={{ color: 'white' }} />
        </IconButton>
      }
      title={standard.standardName}
      subtitle={standard.standardGuid}
      right={<div></div>}
    >
      <Table
        data={data}
        columns={columns}
        isLoading={isLoading}
        isFirstColumnSticky={true}
      />
    </FullPageLayout>
  );
};

export default StandardDetails;
