import React, { useState, useEffect, useRef } from 'react';
import './questionTimer.scss';

const ClockIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18 6C11.3726 6 6 11.3726 6 18C6 24.6274 11.3726 30 18 30C24.6274 30 30 24.6274 30 18C30 11.3726 24.6274 6 18 6ZM3 18C3 9.71573 9.71573 3 18 3C26.2843 3 33 9.71573 33 18C33 26.2843 26.2843 33 18 33C9.71573 33 3 26.2843 3 18ZM18 10.5C18.8284 10.5 19.5 11.1716 19.5 12V17.3787L23.5607 21.4393C24.1464 22.0251 24.1464 22.9749 23.5607 23.5607C22.9749 24.1464 22.0251 24.1464 21.4393 23.5607L16.9393 19.0607C16.658 18.7794 16.5 18.3978 16.5 18V12C16.5 11.1716 17.1716 10.5 18 10.5Z'
        fill='#E9ECF8'
      />
    </svg>
  );
};

const QuestionTimer = ({
  onEnd,
  forceTimeFinish,
  setSeconds,
  seconds,
  syncTimestamp,
  playSoundEffect,
  currentQuestion // La primera tiene una cuenta atrás adicional
}) => {
  const SECONDS_LEFT_TO_WARNING = 10;
  const MILIS_TO_UPDATE_CLOCK = 200;

  const [isWarningActive, setIsWarningActive] = useState(false);
  const secondsRef = useRef(seconds);
  const questionCoundonwInterval = useRef(null);
  
  useEffect(() => {
    secondsRef.current = seconds;
  }, [seconds]);

  useEffect(() => {
    // La primera pregunta tiene una cuenta atrás adicional de 4s
    const secondsToEnd = seconds + (currentQuestion === 0 ? 4 : 0);
    // El timestamp teniendo en cuenta los segundos para la cuenta atrás
    const endTimestap = syncTimestamp + secondsToEnd * 1000;

    questionCoundonwInterval.current = setInterval(() => {
      let distance = endTimestap - new Date().getTime();
      if (distance <= 0) {
        distance = 0;
        clearInterval(questionCoundonwInterval.current);
        setSeconds(0);
        setIsWarningActive(false);
        onEnd();
      }

      const currentSecond = Math.ceil(distance / 1000);
      if (currentSecond < secondsRef.current) {
        setSeconds(currentSecond);
        console.log('abcd currentSecond', currentSecond, 'seconds', secondsRef.current)

        if (currentSecond <= SECONDS_LEFT_TO_WARNING && currentSecond > 0) {
          setIsWarningActive(true);
          playSoundEffect('simpleClick5');
        }
      }
    }, MILIS_TO_UPDATE_CLOCK);

    return () => clearInterval(questionCoundonwInterval.current);
  }, [syncTimestamp]);

  useEffect(() => {
    if (forceTimeFinish) {
      clearInterval(questionCoundonwInterval.current);
      setSeconds(0);
      setIsWarningActive(false);
    }
  }, [forceTimeFinish]);

  const getFormattedTime = () => {
    let formattedSeconds = seconds % 60;
    let formattedMinutes = Math.floor(seconds / 60);

    // Usando padStart para agregar ceros iniciales si es necesario
    formattedSeconds = String(formattedSeconds).padStart(2, '0');
    formattedMinutes = String(formattedMinutes).padStart(2, '0');

    return formattedMinutes + ':' + formattedSeconds;
  };

  return (
    <div
      className={
        'question-timer' + (isWarningActive ? ' question-timer_alert' : '')
      }
    >
      <ClockIcon />
      {getFormattedTime()}
    </div>
  );
};

export default QuestionTimer;
