import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Lemonade, observer, useLemonadeQuestion } from 'react-lemonade-editor'
import 'react-lemonade-editor/dist/index.css'
import { ReactComponent as SvgScrollMark } from '../../assets/icons/scroll-mark.svg'
import ExerciseAudioButton from '../../components/atoms/ExerciseAudioButton/ExerciseAudioButton'
import FeedbackBox from '../../components/atoms/FeedbackBox/FeedbackBox'
import FeedbackButton from '../../components/atoms/FeedbackButton/FeedbackButton'
import FeedbackPanelQuiz from '../../components/atoms/FeedbackPanelQuiz/FeedbackPanelQuiz'
import ActivityFooter from '../../components/molecules/ActivityFooter/ActivityFooter'
import DummyActivity from '../../components/molecules/DummyActivity/DummyActivity'
import ScaffoldContainer from '../../components/molecules/ScaffoldContainer/ScaffoldContainer'
import { ACTIVITY_TYPES } from '../../constants/ActivityConstants'
import { FEEDBACK_TYPE } from '../../constants/FeedbackConstants'
import { BREAKPOINTS } from '../../constants/uiConstants'
import { useActivityContext } from '../../context/ActivityContext/useActivityContext'
import { useActivityManager } from '../../hooks/useActivityManager'
import { getDeviceType, isIosDevice } from '../../utils/devices'
import './activityView.scss'
import { useActivityView } from './useActivityView'

function ActivityView({
  showSolutionQuestion,
  onActivityEvent,
  activityInformation,
  isHideFooter,
  studentResponse,
  showAllFeedback = true,
  finishedTimeQuestion,
  hasCheckExerciseAPiResponded,
  requiresAudioAssistance,
  hasPreviowsPopups,
  getStimulusAudio,
  getTextAudio,
  numberQuestion,
  showSolutionQuestionQuizPreview,
  mathRenderEngine,
  exerciseLanguage,
  pathAssetsSchool
}) {
  const { t } = useTranslation()

  const {
    activityType,
    activity: currentActivity,
    activityStore,
    isAnswerCorrect,
    isScaffold,
    scaffoldCurrentQuestion,
    isDemoMode,
    setActivityStore,
    initializeActivity,
    isDisabledActivity,
    isSolutionShowed
  } = useActivityContext()

  const {
    exercise: { Question, Feedback, questionApi: exerciseStore },
    updateExercise
  } = useLemonadeQuestion({
    data: currentActivity,
    lang: exerciseLanguage ? exerciseLanguage.toLowerCase() : 'en'
  })

  const [isIphone] = useState(isIosDevice() && getDeviceType() === 'phone')

  const activityRef = useRef(null)
  const activityContentRef = useRef(null)

  const {
    feedbackBoxMessage,
    feedbackIndicatorObject,
    isFeedbackVisible,
    setIsFeedbackVisible,
    showScaffoldFirstStep,
    scaffoldNext,
    checkAnswer,
    resetActivity,
    handleUserChange,
    setIsResponseSend,
    isResponseSend,
    feedbackTitle,
    feedbackSubtitle,
    feedbackIcon
  } = useActivityManager({
    onActivityEvent,
    studentResponse,
    finishedTimeQuestion,
    activityType,
    showSolutionQuestion,
    showSolutionQuestionQuizPreview,
    mathRenderEngine
  })

  const {
    showActivityFooter,
    activityWidth,
    footerHeight,
    isTopScrollShadowActive,
    isScrollMarkActive,
    isBottomScrollShadowActive,
    pathwayCellSize,
    toogleFeedback,
    scrollContentAndHideScrollMark,
    onKeyboardVisible
  } = useActivityView({
    activityRef,
    activityType,
    currentActivity,
    activityContentRef,
    isFeedbackVisible,
    activityStore,
    exerciseStore,
    isScaffold,
    scaffoldCurrentQuestion,
    showAllFeedback,
    onActivityEvent,
    setActivityStore,
    initializeActivity,
    setIsFeedbackVisible,
    updateExercise,
    isResponseSend,
    exerciseLanguage
  })

  const hasDummyEnabled = () => {
    const hasCurrentActivity = !(
      Object.keys(currentActivity).length === 0 &&
      currentActivity.constructor === Object
    )
    return (isDemoMode === 1 || isDemoMode === true) && hasCurrentActivity
  }

  const shouldShowFeedback = () => {
    const _isAnswerCorrect = isAnswerCorrect === 1 || isAnswerCorrect === true
    const isFeedbackIndicatorHidden = activityWidth < BREAKPOINTS.tablet

    const isFeedbackIncorrectNecessary =
      !_isAnswerCorrect &&
      (feedbackBoxMessage !== feedbackIndicatorObject.message ||
        isFeedbackIndicatorHidden ||
        isSolutionShowed)

    const isFeedbackCorrectNecessary =
      _isAnswerCorrect && isFeedbackIndicatorHidden

    const isFeedbackNecessary =
      isFeedbackIncorrectNecessary || isFeedbackCorrectNecessary

    return (
      isFeedbackNecessary &&
      activityStore?.isEvaluated &&
      isFeedbackVisible &&
      showAllFeedback &&
      activityType !== ACTIVITY_TYPES.QUIZ
    )
  }

  const checkShowBottomScroll = () => {
    if (activityType !== ACTIVITY_TYPES.QUIZ) {
      return isBottomScrollShadowActive
    } else {
      return (
        isBottomScrollShadowActive &&
        showActivityFooter &&
        !isHideFooter &&
        !isResponseSend
      )
    }
  }

  return (
    <div
      className={'activity-view' + (isIphone ? ' activity-view_iphone' : '')}
      ref={activityRef}
    >
      {isTopScrollShadowActive && activityType !== ACTIVITY_TYPES.QUIZ && (
        <div className="activity-view_top-scroll-shadow"></div>
      )}

      <div className="activity-view_content" ref={activityContentRef}>
        {hasDummyEnabled() && (
          <DummyActivity
            data={activityInformation}
            sendResponse={checkAnswer}
            thumbsDisabled={
              activityStore?.isEvaluated &&
              (!isScaffold || !scaffoldCurrentQuestion?.isMain)
            }
          />
        )}

        <div
          className={
            activityType !== ACTIVITY_TYPES.QUIZ
              ? 'activity-view_content__with-audio-button'
              : ''
          }
        >
          {activityType !== ACTIVITY_TYPES.QUIZ && (
            <div className="activity-view__stimulus-audio-button">
              <ExerciseAudioButton
                requiresAudioAssistance={requiresAudioAssistance}
                eventAudioType="activity_stimulus"
                hasPreviowsPopups={hasPreviowsPopups} // El popup con gif explicativo, de momento no hay otros
                getAudio={getStimulusAudio}
                onActivityEvent={onActivityEvent}
                exerciseGuid={activityInformation?.exerciseGuid}
              />
            </div>
          )}

          {activityType === ACTIVITY_TYPES.QUIZ && numberQuestion && (
            <div className="number-question">
              <span>{numberQuestion}</span>
            </div>
          )}

          <Lemonade>
            {/* Ejercicio SIN Scaffold */}
            {!isScaffold && (
              <>
                <Question
                  changed={handleUserChange}
                  onKeyboardVisible={onKeyboardVisible}
                  autoFocus={isDisabledActivity ? 'disabled' : ''}
                  disabled={isDisabledActivity}
                  initialCellSize={pathwayCellSize}
                  pathwayAutoSizeMediaQuery={1}
                />
                {activityType !== ACTIVITY_TYPES.QUIZ && <Feedback />}
              </>
            )}

            {/* Ejercicio CON Scaffold */}
            {isScaffold && (
              <ScaffoldContainer
                currentExercise={currentActivity}
                handleUserChange={handleUserChange}
                autoFocus={isDisabledActivity ? 'disabled' : ''}
                disabled={isDisabledActivity}
              ></ScaffoldContainer>
            )}
          </Lemonade>
        </div>

        <Lemonade>
          {/* Pista */}
          {!isScaffold &&
            activityStore?.hints?.isVisible &&
            showAllFeedback && (
              <FeedbackBox
                feedbackType={FEEDBACK_TYPE.NEUTRAL}
                content={activityStore.hints.currentHint}
                onActivityEvent={onActivityEvent}
                panelInformationType={'activity_hint'}
                exerciseGuid={activityInformation?.exerciseGuid}
                requiresAudioAssistance={requiresAudioAssistance}
                getTextAudio={getTextAudio}
                mathRenderEngine={mathRenderEngine}
                pathAssetsSchool={pathAssetsSchool}
              />
            )}

          {/* Feedback respuesta */}
          {shouldShowFeedback() && (
            <FeedbackBox
              feedbackType={
                isAnswerCorrect
                  ? FEEDBACK_TYPE.CORRECT
                  : FEEDBACK_TYPE.INCORRECT
              }
              content={feedbackBoxMessage}
              onActivityEvent={onActivityEvent}
              panelInformationType={'activity_feedback'}
              exerciseGuid={activityInformation?.exerciseGuid}
              requiresAudioAssistance={requiresAudioAssistance}
              getTextAudio={getTextAudio}
              mathRenderEngine={mathRenderEngine}
              pathAssetsSchool={pathAssetsSchool}
            />
          )}
          {activityType === ACTIVITY_TYPES.QUIZ &&
            showSolutionQuestion &&
            showAllFeedback && (
              <FeedbackPanelQuiz
                title={feedbackTitle}
                subtitle={feedbackSubtitle}
                content={isAnswerCorrect ? '' : feedbackBoxMessage}
                iconType={feedbackIcon || ''}
                onShowLess={null}
                onClose={isAnswerCorrect ? null : toogleFeedback}
                setIsFeedbackReady={true}
                isVisible={true}
                panelInformationType={'activity_feedback'}
                translate={t}
                mathRenderEngine={mathRenderEngine}
                pathAssetsSchool={pathAssetsSchool}
              ></FeedbackPanelQuiz>
            )}
          {activityType === ACTIVITY_TYPES.QUIZ &&
            isResponseSend &&
            !showSolutionQuestion && (
              <FeedbackPanelQuiz
                title={t('app_practice_send_answer')}
                subtitle={''}
                content={''}
                iconType={'quiz_send_answer'}
                onShowLess={null}
                onClose={isAnswerCorrect ? null : toogleFeedback}
                setIsFeedbackReady={true}
                isVisible={true}
                panelInformationType={'activity_feedback'}
                translate={t}
                mathRenderEngine={mathRenderEngine}
                pathAssetsSchool={pathAssetsSchool}
              ></FeedbackPanelQuiz>
            )}
        </Lemonade>

        {isScrollMarkActive && (
          <div
            className="activity-view__scroll-mark"
            style={{ bottom: footerHeight + 'px' }}
          >
            <SvgScrollMark onClick={scrollContentAndHideScrollMark} />
          </div>
        )}
      </div>
      {checkShowBottomScroll() && (
        <div className="activity-view_bottom-scroll-shadow"></div>
      )}
      {activityStore?.isEvaluated && !isFeedbackVisible && showAllFeedback && (
        <div className="activity-view__feedback-button-wrapper">
          <FeedbackButton
            action={toogleFeedback}
            buttonText={
              activityWidth > BREAKPOINTS.tablet
                ? t('activity_explain_button')
                : null
            }
          />
        </div>
      )}
      {showActivityFooter &&
        !isHideFooter &&
        ((!isResponseSend && activityType === ACTIVITY_TYPES.QUIZ) ||
          activityType !== ACTIVITY_TYPES.QUIZ) && (
          <ActivityFooter
            checkAnswer={
              activityType !== ACTIVITY_TYPES.QUIZ
                ? checkAnswer
                : () => setIsResponseSend(true)
            }
            showScaffoldFirstStep={showScaffoldFirstStep}
            setIsFeedbackVisible={setIsFeedbackVisible}
            resetActivity={resetActivity}
            feedbackIndicatorObject={feedbackIndicatorObject}
            onActivityEvent={onActivityEvent}
            activityWidth={activityWidth}
            hasCheckExerciseAPiResponded={hasCheckExerciseAPiResponded}
            isResponseSend={isResponseSend}
            scaffoldNext={scaffoldNext}
            pathAssetsSchool={pathAssetsSchool}
          />
        )}
    </div>
  )
}

export default observer(ActivityView)
