import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ACTIVITY_TYPES } from '../constants/ActivityConstants'
import {
  FEEDBACK_TOTAL_VARIANTS,
  FEEDBACK_TYPE
} from '../constants/FeedbackConstants'
import { useActivityContext } from '../context/ActivityContext/useActivityContext'
import { activityStorage } from '../utils/activityStorage'
import { setLemonadeDefaultConfig } from '../utils/setLemonadeConfig'

export function useActivityManager({
  onActivityEvent,
  studentResponse,
  finishedTimeQuestion,
  activityType,
  showSolutionQuestion,
  showSolutionQuestionQuizPreview,
  mathRenderEngine
}) {
  const { t } = useTranslation()

  const {
    activity,
    activityStore,
    isScaffold,
    initializeActivity,
    setIsCheckButtonEnabled,
    setIsAnswerCorrect,
    scaffoldCurrentQuestion,
    scaffoldNext: scaffoldInnerNext,
    setIsScaffoldComplete,
    setIsDisabledActivity
  } = useActivityContext()

  const [feedbackBoxMessage, setFeedbackBoxMessage] = useState('')
  const [feedbackIndicatorObject, setFeedbackIndicatorObject] = useState({
    message: '',
    variant: 0,
    type: FEEDBACK_TYPE.CORRECT
  })
  const [isFeedbackVisible, setIsFeedbackVisible] = useState(false)

  const [isResponseSend, setIsResponseSend] = useState(false)
  const isAnsweredOnTime = useRef(false)
  const [feedbackTitle, setFeedbackTitle] = useState('')
  const [feedbackSubtitle, setFeedbackSubtitle] = useState('')
  const [feedbackIcon, setFeedbackIcon] = useState(null)

  useEffect(() => {
    setLemonadeDefaultConfig(mathRenderEngine)
  }, [])

  const checkMainAnswer = (dummyAnswer) => {
    // !isScaffold ? exerciseStore : scaffoldStore;

    let isUserCorrect = null
    let userResponse = null

    // Respondida de manera normal
    if (dummyAnswer === undefined) {
      if (
        (activityType === ACTIVITY_TYPES.QUIZ && isAnsweredOnTime.current) ||
        activityType !== ACTIVITY_TYPES.QUIZ
      ) {
        activityStore.checkAnswers()
        isUserCorrect = activityStore.getResults()
        userResponse = activityStore.getUserResponse()
      }

      if (activityType === ACTIVITY_TYPES.QUIZ && !isAnsweredOnTime.current) {
        activityStore.checkAnswers()
        isUserCorrect = 0
      }
    } else {
      // Repondida con botones para pruebas
      isUserCorrect = dummyAnswer
      userResponse =
        dummyAnswer === true ? activityStore.validation.value : ['']
      activityStore.setEvaluated(true)
    }

    return { isUserCorrect, userResponse }
  }

  // Comprueba la respuesta del usuario
  const checkAnswer = (dummyAnswer) => {
    if (!isScaffold && activityStore?.hints?.isVisible) {
      activityStore.hints.hideHint()
    }

    const isDummyAnswer = dummyAnswer !== undefined
    let isUserCorrect = null
    let userResponse = null

    if (isDummyAnswer || !isScaffold) {
      const mainCheck = checkMainAnswer(dummyAnswer)
      isUserCorrect = mainCheck.isUserCorrect
      userResponse = mainCheck.userResponse

      if (isUserCorrect || dummyAnswer === false) {
        setIsScaffoldComplete(true)
      } else {
        setIsScaffoldComplete(false)
      }

      setIsAnswerCorrect(isUserCorrect)

      onActivityEvent({
        name: 'Check Answer',
        properties: {
          isUserCorrect: isUserCorrect,
          userResponse: userResponse,
          isScaffold: isScaffold,
          isMain: !isScaffold || scaffoldCurrentQuestion.isMain
        }
      })
    } else {
      if (scaffoldCurrentQuestion.isMain) {
        const mainCheck = checkMainAnswer(dummyAnswer)
        isUserCorrect = mainCheck.isUserCorrect
        userResponse = mainCheck.userResponse

        setIsScaffoldComplete(isUserCorrect)

        onActivityEvent({
          name: 'Check Answer',
          properties: {
            isUserCorrect: isUserCorrect,
            userResponse: userResponse,
            isScaffold: isScaffold,
            isMain: !isScaffold || scaffoldCurrentQuestion.isMain
          }
        })
      } else {
        activityStore.checkAnswers()
        isUserCorrect = !isDummyAnswer
          ? activityStore.getResults()
          : dummyAnswer
        userResponse = !isDummyAnswer
          ? activityStore.getUserResponse()
          : dummyAnswer === true
          ? activityStore.validation.value
          : ['']

        setIsAnswerCorrect(isUserCorrect)
        setIsScaffoldComplete(scaffoldCurrentQuestion.isLast)
      }

      setIsAnswerCorrect(isUserCorrect)
    }
    if (isUserCorrect) {
      const lowerFeedbackType = FEEDBACK_TYPE.CORRECT.toLowerCase()
      const randomFeedback = getRandomFeedback(lowerFeedbackType)

      setFeedbackIndicatorObject({
        message: randomFeedback.title,
        variant: randomFeedback.variant,
        type: lowerFeedbackType
      })

      setFeedbackBoxMessage(randomFeedback.message)
      if (activityType === ACTIVITY_TYPES.QUIZ) {
        setFeedbackTitle(
          t('activity_correct_feedback_title_' + randomFeedback.variant)
        )
        setFeedbackSubtitle(
          t('activity_correct_feedback_subtitle_' + randomFeedback.variant)
        )
        setFeedbackIcon('correct_' + randomFeedback.variant)

        onActivityEvent({
          name: 'Sound Quiz Correct Answer',
          properties: {}
        })
      }
    } else {
      const lowerFeedbackType = FEEDBACK_TYPE.INCORRECT.toLowerCase()

      // Feedback en indicador
      const randomFeedback = getRandomFeedback(lowerFeedbackType)
      if (activityType === ACTIVITY_TYPES.QUIZ && !isAnsweredOnTime.current) {
        setFeedbackIndicatorObject({
          message: `${t(
            'app_practice_incorrect_feedback_title_no_answer'
          )}  ${t('app_practice_incorrect_feedback_subtitle_no_answer')}`,
          variant: 0,
          type: lowerFeedbackType
        })

        // Feedback en caja
        const defaultFeedback = activityStore?.feedback?.errorMessages[0]
        setFeedbackBoxMessage(defaultFeedback || randomFeedback.message)

        setFeedbackTitle(t('app_practice_incorrect_feedback_title_no_answer'))
        setFeedbackSubtitle(
          t('app_practice_incorrect_feedback_subtitle_no_answer')
        )
        setFeedbackIcon('incorrect_' + randomFeedback.variant)
        // --------------------------------------------------------------
      } else {
        setFeedbackIndicatorObject({
          message: randomFeedback.title,
          variant: randomFeedback.variant,
          type: lowerFeedbackType
        })

        // Feedback en caja
        const defaultFeedback = activityStore?.feedback?.errorMessages[0]

        // Si es "multiple choice - multiple response" el mensaje debe aclarar si marcaron las necesarias
        // "getPartialMatch" genera error, podría ser algo de lemonade
        try {
          // El usuario tenía que seleccionar varias opciones correctas pero no seleccionó todas
          const questionData = !isScaffold
            ? activity
            : activity.questions[scaffoldCurrentQuestion.index]
          const template = (questionData?.metadata?.name || '').toLowerCase()
          const isMultipleResponse =
            template.indexOf('multiple choice') !== -1 &&
            template.indexOf('multiple response') !== -1

          if (isMultipleResponse) {
            const partialMatch = activityStore.getPartialMatch()
            if (partialMatch > 0 && partialMatch < 1) {
              setFeedbackIndicatorObject({
                message: t('activity_partial_match_feedback_subtitle'),
                variant: 2,
                type: lowerFeedbackType
              })
            }
          }
        } catch (e) {
          console.error('Error at getPartialMatch:', e)
        }
        // --------------------------------------------------------------
        setFeedbackBoxMessage(defaultFeedback || randomFeedback.message)

        if (activityType === ACTIVITY_TYPES.QUIZ) {
          setFeedbackTitle(
            t('activity_incorrect_feedback_title_' + randomFeedback.variant)
          )
          setFeedbackSubtitle(
            t('activity_incorrect_feedback_subtitle_' + randomFeedback.variant)
          )
          setFeedbackIcon('incorrect_' + randomFeedback.variant)
        }
      }

      if (activityType === ACTIVITY_TYPES.QUIZ) {
        onActivityEvent({
          name: 'Sound Quiz Error Answer',
          properties: {}
        })
      }
    }

    setIsFeedbackVisible(true)
  }

  const parseStudentResponse = (response) => {
    if (!response) return {}

    if (typeof response === 'string') {
      try {
        return JSON.parse(response)
      } catch (e) {
        return {}
      }
    }

    return response
  }

  useEffect(() => {
    if (
      activityType === ACTIVITY_TYPES.QUIZ &&
      activityStore &&
      showSolutionQuestionQuizPreview !== undefined
    ) {
      if (showSolutionQuestionQuizPreview) {
        activityStore.checkAnswers()
        activityStore.showSolutions()
      } else {
        // null indicates that the student did not answer the question
        if (
          (studentResponse || studentResponse === null) &&
          studentResponse?.length > 0
        ) {
          activityStore.reset()
          studentResponse !== null &&
            activityStore.setResponse(parseStudentResponse(studentResponse))
          activityStore.checkAnswers()
        } else {
          activityStore?.reset && activityStore.reset()
        }
      }
    }
  }, [showSolutionQuestionQuizPreview])

  useEffect(() => {
    if (
      activityType === ACTIVITY_TYPES.QUIZ &&
      activityStore &&
      studentResponse !== undefined
    ) {
      // null indicates that the student did not answer the question
      if (
        (studentResponse || studentResponse === null) &&
        studentResponse?.length > 0
      ) {
        activityStore.reset()
        studentResponse !== null &&
          activityStore.setResponse(parseStudentResponse(studentResponse))
        activityStore.checkAnswers()
      }
    }
  }, [activityStore, studentResponse])

  const showScaffoldFirstStep = () => {
    checkAnswer(false)
    // activityStore.reset()
    // activityStore.setDisabled(true)
    scaffoldNext()
  }

  const scaffoldNext = () => {
    scaffoldInnerNext()
    setIsFeedbackVisible(false)
    setFeedbackBoxMessage('')
    setFeedbackIndicatorObject({})
    setIsCheckButtonEnabled(false)
    setIsAnswerCorrect(false)
  }

  const resetActivity = () => {
    setIsFeedbackVisible(false)
    setFeedbackBoxMessage('')
    setFeedbackIndicatorObject({})
    if (activityType === ACTIVITY_TYPES.PREVIEW) {
      activityStore.reset()
    }
    initializeActivity(false)
  }

  const handleUserChange = (event) => {
    setIsCheckButtonEnabled(true)
  }

  const getRandomFeedback = (type) => {
    const lastVariant = parseInt(
      activityStorage.get('bb_' + type + '_feedback')
    )
    let randomVariant = Math.floor(Math.random() * FEEDBACK_TOTAL_VARIANTS)

    if (randomVariant === lastVariant) randomVariant++
    if (randomVariant > FEEDBACK_TOTAL_VARIANTS - 1) randomVariant = 0

    activityStorage.set('bb_' + type + '_feedback', randomVariant)

    return {
      title: t('activity_' + type + '_feedback_title_' + randomVariant),
      message: t('activity_' + type + '_feedback_subtitle_' + randomVariant),
      variant: randomVariant
    }
  }

  // QUIZ
  useEffect(() => {
    if (finishedTimeQuestion) {
      finishTimerQuiz()
    }
  }, [finishedTimeQuestion])

  useEffect(() => {
    if (isResponseSend) {
      isAnsweredOnTime.current = true
      setIsCheckButtonEnabled(false)
      setIsDisabledActivity(true)

      setFeedbackBoxMessage(t('app_practice_send_answer'))

      // Enviar parámetros de la respuesta a la API
      onActivityEvent({
        name: 'Check Answer Quiz',
        properties: {
          resultAnswer: activityStore.getResults(),
          lemonadeResponseData: activityStore.getUserResponse()
        }
      })

      onActivityEvent({
        name: 'Sound Quiz Send Answer',
        properties: {}
      })

      onActivityEvent({
        name: 'Analisys Practice Activity Answered Quiz',
        properties: {
          is_answered: true,
          result: activityStore.getResults()
        }
      })
    }
  }, [isResponseSend])

  useEffect(() => {
    if (showSolutionQuestion) {
      checkAnswer()

      if (!isResponseSend) {
        onActivityEvent({
          name: 'Check Answer Quiz',
          properties: {
            resultAnswer: 0,
            lemonadeResponseData: []
          }
        })

        onActivityEvent({
          name: 'Analisys Practice Activity Answered Quiz',
          properties: {
            is_answered: false,
            result: 0
          }
        })
      }
    }
  }, [showSolutionQuestion])

  const finishTimerQuiz = () => {
    setIsCheckButtonEnabled(false)
    setIsDisabledActivity(true)
  }

  return {
    feedbackBoxMessage,
    feedbackIndicatorObject,
    isFeedbackVisible,
    setIsFeedbackVisible,
    checkAnswer,
    resetActivity,
    handleUserChange,
    showScaffoldFirstStep,
    scaffoldNext,
    setIsResponseSend,
    isResponseSend,
    feedbackTitle,
    feedbackSubtitle,
    feedbackIcon
  }
}
