import uuidv4 from './uuidv4'

import SILENCE from '../assets/audios/silence.mp3'
import HINT_ES from '../assets/audios/es/hint.mp3'
import HINT_EN from '../assets/audios/en/hint.mp3'
import HINT_PT from '../assets/audios/pt/hint.mp3'
import FEEDBACK_CORRECT_ES from '../assets/audios/es/feedback_correct.mp3'
import FEEDBACK_CORRECT_EN from '../assets/audios/en/feedback_correct.mp3'
import FEEDBACK_CORRECT_PT from '../assets/audios/pt/feedback_correct.mp3'
import FEEDBACK_INCORRECT_ES from '../assets/audios/es/feedback_incorrect.mp3'
import FEEDBACK_INCORRECT_EN from '../assets/audios/en/feedback_incorrect.mp3'
import FEEDBACK_INCORRECT_PT from '../assets/audios/pt/feedback_incorrect.mp3'
import FEEDBACK_HINT_CORRECT_ES from '../assets/audios/es/feedback_hint_correct.mp3'
import FEEDBACK_HINT_CORRECT_EN from '../assets/audios/en/feedback_hint_correct.mp3'
import FEEDBACK_HINT_CORRECT_PT from '../assets/audios/pt/feedback_hint_correct.mp3'
import FEEDBACK_HINT_INCORRECT_ES from '../assets/audios/es/feedback_hint_incorrect.mp3'
import FEEDBACK_HINT_INCORRECT_EN from '../assets/audios/en/feedback_hint_incorrect.mp3'
import FEEDBACK_HINT_INCORRECT_PT from '../assets/audios/pt/feedback_hint_incorrect.mp3'

export const AUDIO_TYPE = {
  SILENCE: 'SILENCE',
  HINT: 'HINT',
  FEEDBACK_CORRECT: 'FEEDBACK_CORRECT',
  FEEDBACK_INCORRECT: 'FEEDBACK_INCORRECT',
  FEEDBACK_HINT_CORRECT: 'FEEDBACK_HINT_CORRECT',
  FEEDBACK_HINT_INCORRECT: 'FEEDBACK_HINT_INCORRECT'
}

const BLUEBERRO_AUDIOS = {}

// CORRECTO            -> NO se debe leer el mensaje (no se ve)
// INCORRECTO          -> SI se debe leer el mensaje
// CORRECTO CON PISTA  -> NO se debe leer el mensaje (no se ve)
// ICORRECTO CON PISTA -> SI se debe leer el mensaje
const isFeedbackSilence = (audioType) => {
  if (
    audioType === AUDIO_TYPE.FEEDBACK_CORRECT ||
    audioType === AUDIO_TYPE.FEEDBACK_HINT_CORRECT
  ) {
    return true
  }

  return false
}

export const ExerciseAudioManager = {
  store: {},

  initialize: (language) => {
    ExerciseAudioManager.stopAllAudios(true)

    BLUEBERRO_AUDIOS[AUDIO_TYPE.SILENCE] = new Audio(SILENCE)

    switch (language) {
      case 'es':
      case 'ES': {
        BLUEBERRO_AUDIOS[AUDIO_TYPE.HINT] = new Audio(HINT_ES)
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_CORRECT] = new Audio(
          FEEDBACK_CORRECT_ES
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_INCORRECT] = new Audio(
          FEEDBACK_INCORRECT_ES
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_HINT_CORRECT] = new Audio(
          FEEDBACK_HINT_CORRECT_ES
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_HINT_INCORRECT] = new Audio(
          FEEDBACK_HINT_INCORRECT_ES
        )
        break
      }

      case 'pt':
      case 'PT': {
        BLUEBERRO_AUDIOS[AUDIO_TYPE.HINT] = new Audio(HINT_PT)
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_CORRECT] = new Audio(
          FEEDBACK_CORRECT_PT
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_INCORRECT] = new Audio(
          FEEDBACK_INCORRECT_PT
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_HINT_CORRECT] = new Audio(
          FEEDBACK_HINT_CORRECT_PT
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_HINT_INCORRECT] = new Audio(
          FEEDBACK_HINT_INCORRECT_PT
        )
        break
      }

      default: {
        BLUEBERRO_AUDIOS[AUDIO_TYPE.HINT] = new Audio(HINT_EN)
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_CORRECT] = new Audio(
          FEEDBACK_CORRECT_EN
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_INCORRECT] = new Audio(
          FEEDBACK_INCORRECT_EN
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_HINT_CORRECT] = new Audio(
          FEEDBACK_HINT_CORRECT_EN
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_HINT_INCORRECT] = new Audio(
          FEEDBACK_HINT_INCORRECT_EN
        )
      }
    }
  },

  addAudio: (audio) => {
    const id = uuidv4()
    ExerciseAudioManager.store[id] = { audio }

    return id
  },

  isPlaying: (audio) => {
    return (
      audio.currentTime > 0 &&
      !audio.paused &&
      !audio.ended &&
      audio.readyState > audio.HAVE_CURRENT_DATA
    )
  },

  playAudio: async (id, previousAudioType) => {
    // Se paran los que estén sonando
    ExerciseAudioManager.stopAllAudios()

    // Seleccionar audio
    const auxAudio = !isFeedbackSilence(previousAudioType)
      ? ExerciseAudioManager.store[id].audio
      : BLUEBERRO_AUDIOS[AUDIO_TYPE.SILENCE]

    if (previousAudioType) {
      // Con sonido previo
      const previousAudio = BLUEBERRO_AUDIOS[previousAudioType]

      previousAudio.onended = async () => {
        if (!ExerciseAudioManager.isPlaying(auxAudio)) {
          try {
            await auxAudio.play()
          } catch (ae) {}
        }

        previousAudio.onended = null
      }

      if (!ExerciseAudioManager.isPlaying(previousAudio)) {
        try {
          await previousAudio.play()
        } catch (ae) {}
      }
    } else {
      // Sin sonido previo
      if (!ExerciseAudioManager.isPlaying(auxAudio)) {
        try {
          await auxAudio.play()
        } catch (ae) {}
      }
    }
  },

  stopAudio: (id, previousAudioType) => {
    // Si tiene previo
    if (previousAudioType) {
      const previousAudio = BLUEBERRO_AUDIOS[previousAudioType]

      if (!previousAudio.paused) {
        previousAudio.onended = null

        previousAudio.pause()
        previousAudio.currentTime = 0
      }
    }

    // El audio principal
    if (ExerciseAudioManager.store[id]) {
      const auxAudio = ExerciseAudioManager.store[id].audio

      if (!auxAudio.paused) {
        auxAudio.pause()
        auxAudio.currentTime = 0
      }
    }
  },

  stopAllAudios: (removeToo) => {
    for (const key in ExerciseAudioManager.store) {
      const auxAudio = ExerciseAudioManager.store[key].audio

      if (!auxAudio.paused) {
        auxAudio.onended = null

        auxAudio.pause()
        auxAudio.currentTime = 0

        if (removeToo) {
          delete ExerciseAudioManager.store[key]
        }
      }
    }

    for (const key in BLUEBERRO_AUDIOS) {
      const auxAudio = BLUEBERRO_AUDIOS[key]

      if (!auxAudio.paused) {
        auxAudio.onended = null

        auxAudio.pause()
        auxAudio.currentTime = 0
      }
    }
  },

  toogleAudio: (id, previousAudioType) => {
    let playing = false
    let hasToPlay = true

    // Si tiene sonido previo
    if (previousAudioType) {
      const previousAudio = BLUEBERRO_AUDIOS[previousAudioType]

      if (!previousAudio.paused) {
        hasToPlay = false

        previousAudio.onended = null
        previousAudio.pause()
        previousAudio.currentTime = 0
      }
    }

    // Sonido principal
    if (ExerciseAudioManager.store[id]) {
      const auxAudio = ExerciseAudioManager.store[id].audio
      if (!auxAudio.paused) {
        hasToPlay = false

        auxAudio.pause()
        auxAudio.currentTime = 0
      }

      if (hasToPlay) {
        playing = true
        ExerciseAudioManager.playAudio(id, previousAudioType)
      }
    }

    return playing
  },

  removeAudio: (id, previousAudioType) => {
    if (previousAudioType) {
      const previousAudio = BLUEBERRO_AUDIOS[previousAudioType]

      if (!previousAudio.paused) {
        previousAudio.onended = null
        previousAudio.pause()
        previousAudio.currentTime = 0
      }
    }

    if (ExerciseAudioManager.store[id]) {
      const auxAudio = ExerciseAudioManager.store[id].audio
      if (!auxAudio.paused) {
        auxAudio.onended = null
        auxAudio.pause()
      }

      delete ExerciseAudioManager.store[id]
    }
  }
}

// ExerciseAudioManager.initialize()
