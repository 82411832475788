import { Autocomplete, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { CourseContext } from '../../utils/contexts/CourseContext';
import { getCourseDepartments } from '../../utils/crud/content';
import { getBBTeacherUserLanguage } from '../../utils/helpers/userLanguage';
import { useLocalStorage } from '../../utils/hooks/useLocalStorage';

const TDDepartmentsFilter = ({ onChange = () => {}, defaultValue = '' }) => {
  const { t } = useTranslation();
  const { selectedCourse } = useContext(CourseContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const { getItem, setItem, removeItem } = useLocalStorage();

  const [isLoading, setIsLoading] = useState(true);
  const [departments, setDepartments] = useState([
    { value: 'all', label: t('all_departments'), guid: 'all' }
  ]);
  const [selectedDepartment, setSelectedDepartment] = useState('');

  useEffect(() => {
    setIsLoading(true);
    if (!selectedCourse?.guid) return;

    getDepartments();
  }, [selectedCourse]);

  useEffect(() => {
    if (departments?.length === 1) return;

    if (getItem(location.pathname)) {
      setSelectedDepartment(
        departments?.filter((unit) => {
          let _standard;
          try {
            _standard = JSON.parse(getItem(location.pathname))?.standard;
          } catch (error) {
            _standard = null;
            console.error(error);
          }
          return unit.guid === _standard;
        })[0]?.label
      );
    }
  }, [departments]);

  const getDepartments = async () => {
    const tempUnits = await getCourseDepartments({
      courseGuid: selectedCourse?.guid,
      lang: getBBTeacherUserLanguage()
    });

    if (defaultValue) {
      setSelectedDepartment(
        tempUnits?.filter((unit) => unit.guid === defaultValue)[0]?.label
      );
    } else {
      setSelectedDepartment(t('all_departments'));
    }

    setDepartments([
      { value: 'all', label: t('all_departments'), guid: 'all' },
      ...(Array.isArray(tempUnits)
        ? tempUnits.map((item) => ({
            ...item,
            value: item?.guid,
            label: item?.name
          }))
        : [])
    ]);

    setIsLoading(false);
  };

  useEffect(() => {
    if (selectedDepartment === '' || departments.length === 1) return;

    if (selectedDepartment === t('all_departments')) {
      setSearchParams({});
      removeItem(location.pathname);
    } else {
      const tempUnit = departments?.filter(
        (unit) => unit?.label === selectedDepartment
      )[0];
      setItem(location.pathname, JSON.stringify({ standard: tempUnit?.guid }));
      onChange(tempUnit);
      setSearchParams({
        standard: tempUnit?.guid
      });
    }
  }, [selectedDepartment]);

  return (
    <Autocomplete
      loading={isLoading}
      value={selectedDepartment}
      onChange={(event, newValue) => {
        setSelectedDepartment(newValue);
      }}
      id='units-selector'
      options={departments?.map((unit) => unit.label)}
      multiple={false}
      disableClearable
      sx={{ width: 250 }}
      renderInput={(params) => <TextField {...params} />}
    />
  );
};

TDDepartmentsFilter.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.string
};

export default TDDepartmentsFilter;
