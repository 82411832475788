import React from 'react';
import Card from '../../atoms/card/Card';
import InfoTooltip from '../../atoms/tooltip/InfoTooltip';
import Typography from '../../atoms/typography/Typography';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const colors = [
  'rgb(29, 186, 228)',
  'rgb(204, 138, 34)',
  'rgb(88, 134, 160)',
  'rgb(0, 70, 67)',
  'rgb(206, 15, 72)',
  'rgb(56, 176, 124)',
  'rgb(145, 173, 36)',
  'rgb(240, 132, 132)',
  'rgb(120, 51, 139)',
  'rgb(119, 111, 178)'
];

const StudentListPopup = ({ title, hint, students }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div style={{ display: 'flex' }}>
          <Typography variant='h4'>{title}</Typography>
        </div>
        <div style={{ display: 'flex' }}>
          <InfoTooltip text={hint} />
        </div>
      </div>
      {students && (
        <div style={{ marginTop: '1rem', fontSize: '1rem' }}>
          {students.length} {t('students')}
        </div>
      )}
      <div style={{ maxHeight: 200, overflowY: 'scroll', marginTop: '0.5rem' }}>
        <List disablePadding dense>
          {students.map((student) => (
            <ListItem key={student.person_guid} style={{ paddingLeft: 0 }}>
              <ListItemAvatar>
                <Avatar
                  sizes='xs'
                  sx={{
                    bgcolor: colors[Math.floor(Math.random() * colors.length)],
                    width: 34,
                    height: 34,
                    fontSize: '0.85rem'
                  }}
                >
                  {student.name.charAt(0) + student.lastname.charAt(0)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={`${student.name} ${student.lastname}`} />
            </ListItem>
          ))}
        </List>
      </div>
    </Card>
  );
};

StudentListPopup.propTypes = {
  title: PropTypes.string,
  hint: PropTypes.string,
  students: PropTypes.array
};

StudentListPopup.defaultProps = {
  title: '',
  hint: '',
  students: []
};

export default StudentListPopup;
