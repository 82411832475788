export const formatNumericDateWithTime = (date) => {
  // Check if date is valid just in case
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return '--/--/---- --:--';
  }

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
};
