import { Dialog, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../../../atoms/icon';
import HeaderTabs from '../../../../../../components/headerTabs/HeaderTabs';
import CellWithLabelAndProgressBar from '../../../../../../components/table/cell/cellTypes/CellWithLabelAndProgressBar';
import QuizQuestions from '../quizQuestions/QuizQuestions';
import './DialogDetailedView.scss';

const DialogDetailedView = ({
  isOpen,
  onCancelDialog,
  title,
  tabs,
  onChangeTab,
  dialogSelectedTab,
  selectedItem,
  educationYear,
  typeView,
  types,
  startsWithValuesStandards
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={onCancelDialog}
      className='dialog-detailed-view'
    >
      <DialogContent>
        <div className='dialog-detailed-view__content'>
          <div className='dialog-detailed-view__header'>
            <div className='dialog-detailed-view__title'>{title}</div>
            <div
              className='dialog-detailed-view__close'
              onClick={onCancelDialog}
            >
              <Icon
                type='close2'
                format='outline'
                color='secondary'
                fontSize='medium'
              />
            </div>
          </div>
          <div className='dialog-detailed-view__body'>
            <div className='dialog-detailed-view__left'>
              <HeaderTabs
                type='light'
                alignment='col'
                onChangeTab={onChangeTab}
                tabs={tabs}
                selectedTab={dialogSelectedTab}
              />
            </div>
            {selectedItem && (
              <div className='dialog-detailed-view__right'>
                {typeView === types.TOPICS && (
                  <div className='dialog-detailed-view__right-top'>
                    <div className='dialog-detailed-view__right-title'>
                      {selectedItem.topic}
                    </div>
                    <div className='dialog-detailed-view__right-subtitle'>
                      {selectedItem.subtopic}
                    </div>
                  </div>
                )}
                {typeView === types.BNCC && (
                  <div className='dialog-detailed-view__right-top'>
                    <div className='dialog-detailed-view__right-title'>
                      {selectedItem.standard?.name}
                    </div>
                    <div className='dialog-detailed-view__right-subtitle'>
                      {selectedItem.standard?.description}
                    </div>
                  </div>
                )}
                <div className='dialog-detailed-view__right-header'>
                  <div className='dialog-detailed-view__right-header-left'>
                    {typeView === types.STUDENTS && (
                      <div className='dialog-detailed-view__right-header-left--bold'>
                        {selectedItem.points + ' ' + t('quiz_points')}
                      </div>
                    )}
                    {typeView === types.ACTIVITIES &&
                      selectedItem.correctAnswers}
                    {(typeView === types.TOPICS || typeView === types.BNCC) &&
                      `${selectedItem?.questions?.length} ${t('Activity', {
                        count: selectedItem?.questions?.length
                      })}`}
                  </div>
                  {typeView !== types.TOPICS && (
                    <div className='dialog-detailed-view__right-header-center'>
                      {selectedItem?.activities}{' '}
                    </div>
                  )}
                  <div className='dialog-detailed-view__right-header-right'>
                    <CellWithLabelAndProgressBar
                      progress={selectedItem.progress}
                      label={Math.ceil(selectedItem.progress * 100) + '%'}
                      color={selectedItem.color}
                      disableHover
                    />
                  </div>
                </div>
                <div className='dialog-detailed-view__right-body'>
                  {typeView === types.STUDENTS && (
                    <QuizQuestions
                      questions={selectedItem.questions}
                      educationYear={educationYear}
                      readOnly={true}
                      studentResponses={selectedItem.answers}
                      startsWithValuesStandards={startsWithValuesStandards}
                    />
                  )}
                  {typeView === types.ACTIVITIES && (
                    <QuizQuestions
                      questions={selectedItem.question}
                      educationYear={educationYear}
                      readOnly={true}
                      startsWithValuesStandards={startsWithValuesStandards}
                    />
                  )}
                  {typeView === types.TOPICS && (
                    <QuizQuestions
                      questions={selectedItem.questions}
                      educationYear={educationYear}
                      readOnly={true}
                      startsWithValuesStandards={startsWithValuesStandards}
                    />
                  )}
                  {typeView === types.BNCC && (
                    <QuizQuestions
                      questions={selectedItem.questions}
                      educationYear={educationYear}
                      readOnly={true}
                      startsWithValuesStandards={startsWithValuesStandards}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

DialogDetailedView.defualtProps = {
  isOpen: false,
  onCancelDialog: () => {}
};

export default DialogDetailedView;
