import React, { useState, useRef } from 'react';

import littleApprentice from '../../assets/sounds/little_apprentice_loop.mp3';
import simpleClick5 from '../../assets/sounds/simple_click_sound_5.wav';
import powerUp44 from '../../assets/sounds/buff_power_up_44.wav';
import powerUp3 from '../../assets/sounds/power_up_3.wav';
import lightDroneSound22 from '../../assets/sounds/light_drone_sound_22.wav';
import openLootBoxSound5 from '../../assets/sounds/open_loot_box_sound_5.wav';
import applause from '../../assets/sounds/applause.mp3';

export default function useSound() {
  const EFFECTS_VOLUME = 0.8;
  const EFFECTS = {
    littleApprentice: littleApprentice,
    simpleClick5: simpleClick5,
    powerUp44: powerUp44,
    powerUp3: powerUp3,
    lightDroneSound22: lightDroneSound22,
    openLootBoxSound5: openLootBoxSound5,
    applause: applause
  };

  const howlerFXRef = useRef(null);
  const [soundFile, setSoundFile] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoop, setIsLoop] = useState(false);

  const playSoundEffect = (type, loop = false) => {
    setIsLoop(loop);
    setSoundFile(EFFECTS[type]);
    setIsPlaying(true);
  };

  return {
    playSoundEffect,
    soundFile,
    howlerFXRef,
    isPlaying,
    EFFECTS_VOLUME,
    setIsPlaying,
    isLoop
  };
}
