import PropTypes from 'prop-types';
import React from 'react';
import TableHead from '../header/TableHead';

function TableHeadGroup({ headerGroups, options }) {
  return (
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <TableHead key={header.id} header={header} />
          ))}
          {options && <th className='table__head--options' />}
        </tr>
      ))}
    </thead>
  );
}

// TableHeadGroup.propTypes = {
//   headerGroups: PropTypes.array
// };

// TableHeadGroup.defaultProps = {
//   headerGroups: []
// };

export default TableHeadGroup;
