export const MOCK_DATA_WEEK = {
  program: {
    numberOfKcs: 200
  },
  numberOfLastDays: 7,
  users: [
    {
      guid: 'guid',
      name: 'Sam',
      lastname: 'Kerr',
      username: 'username',
      activities: {
        ok: 10,
        ko: 5,
        unfinished: 2,
        total: 17
      },
      totalTimeInSeconds: 45234,
      masteredKcs: 23,
      masteredKcsInLastDays: 3,
      forgottenKcs: 2,
      needHelpLoPhase: [
        {
          guid: 'M3-NyO-14d',
          description:
            'Expresa una multiplicación dada como suma de sumandos iguales y viceversa utilizando objetos (números de 1 a 10)',
          phase: 'idenfiticar',
          numberOfAttempts: 6,
          avgOfAttempts: 4
        },
        {
          guid: 'M3-NyO-14d',
          description: 'La suma - cálculo mental',
          phase: 'idenfiticar',
          numberOfAttempts: 6,
          avgOfAttempts: 4
        }
      ]
    },
    {
      guid: 'guid2',
      name: 'Lucy',
      lastname: 'Bronze',
      username: 'username2',
      activities: {
        ok: 17,
        ko: 0,
        unfinished: 0,
        total: 17
      },
      totalTimeInSeconds: 4234,
      masteredKcs: 23,
      masteredKcsInLastDays: 6,
      forgottenKcs: 2,
      needHelpLoPhase: []
    },
    {
      guid: 'guid3',
      name: 'Alessia',
      lastname: 'Russo',
      username: 'username3',
      activities: {
        ok: 48,
        ko: 53,
        unfinished: 2,
        total: 103
      },
      totalTimeInSeconds: 534,
      masteredKcs: 200,
      masteredKcsInLastDays: 0,
      forgottenKcs: 2,
      needHelpLoPhase: []
    },
    {
      guid: 'guid4',
      name: 'Ella',
      lastname: 'Toone',
      username: 'username4',
      activities: {
        ok: 50,
        ko: 50,
        unfinished: 0,
        total: 100
      },
      totalTimeInSeconds: 1999,
      masteredKcs: 23,
      masteredKcsInLastDays: 3,
      forgottenKcs: 2,
      needHelpLoPhase: []
    },
    {
      guid: 'guid5',
      name: 'Aitana',
      lastname: 'Bonmatí',
      username: 'username4',
      activities: {
        ok: 100,
        ko: 0,
        unfinished: 0,
        total: 100
      },
      totalTimeInSeconds: 1999,
      masteredKcs: 43,
      masteredKcsInLastDays: 13,
      forgottenKcs: 10,
      needHelpLoPhase: []
    },
    {
      guid: 'guid6',
      name: 'Tere',
      lastname: 'Abelleira',
      username: 'username4',
      activities: {
        ok: 0,
        ko: 100,
        unfinished: 0,
        total: 100
      },
      totalTimeInSeconds: 1999,
      masteredKcs: 200,
      masteredKcsInLastDays: 14,
      forgottenKcs: 30,
      needHelpLoPhase: []
    },
    {
      guid: 'guid7',
      name: 'Alba',
      lastname: 'Redondo',
      username: 'username4',
      activities: {
        ok: 0,
        ko: 0,
        unfinished: 100,
        total: 100
      },
      totalTimeInSeconds: 1999,
      masteredKcs: 200,
      masteredKcsInLastDays: 200,
      forgottenKcs: 25,
      needHelpLoPhase: []
    },
    {
      guid: 'guid8',
      name: 'Olga',
      lastname: 'Carmona',
      username: 'username4',
      activities: {
        ok: 0,
        ko: 50,
        unfinished: 50,
        total: 100
      },
      totalTimeInSeconds: 1999,
      masteredKcs: 20,
      masteredKcsInLastDays: 20,
      forgottenKcs: 2,
      needHelpLoPhase: []
    },
    {
      guid: 'guid9',
      name: 'Georgia',
      lastname: 'Stanway',
      username: 'username4',
      activities: {
        ok: 50,
        ko: 0,
        unfinished: 50,
        total: 100
      },
      totalTimeInSeconds: 1999,
      masteredKcs: 0,
      masteredKcsInLastDays: 0,
      forgottenKcs: 2,
      needHelpLoPhase: []
    },
    {
      guid: 'guid10',
      name: 'María',
      lastname: 'León',
      username: 'username4',
      activities: {
        ok: 33,
        ko: 34,
        unfinished: 33,
        total: 100
      },
      totalTimeInSeconds: 1999,
      masteredKcs: 70,
      masteredKcsInLastDays: 0,
      forgottenKcs: 2,
      needHelpLoPhase: []
    },
    {
      guid: 'guid11',
      name: 'Irene',
      lastname: 'Paredes',
      username: 'username4',
      activities: {
        ok: 0,
        ko: 0,
        unfinished: 0,
        total: 0
      },
      totalTimeInSeconds: 1999,
      masteredKcs: 53,
      masteredKcsInLastDays: 25,
      forgottenKcs: 2,
      needHelpLoPhase: []
    }
  ]
};

export const MOCK_DATA_MONTH = {
  program: {
    numberOfKcs: 200
  },
  numberOfLastDays: 30,
  users: [
    {
      guid: 'guid',
      name: 'Sam',
      lastname: 'Kerr 30',
      username: 'username',
      activities: {
        ok: 10,
        ko: 5,
        unfinished: 2,
        total: 17
      },
      totalTimeInSeconds: 45234,
      masteredKcs: 23,
      masteredKcsInLastDays: 3,
      forgottenKcs: 2,
      needHelpLoPhase: [
        {
          guid: 'M3-NyO-14d',
          description:
            'Expresa una multiplicación dada como suma de sumandos iguales y viceversa utilizando objetos (números de 1 a 10)',
          phase: 'idenfiticar',
          numberOfAttempts: 6,
          avgOfAttempts: 4
        },
        {
          guid: 'M3-NyO-14d',
          description: 'La suma - cálculo mental',
          phase: 'idenfiticar',
          numberOfAttempts: 6,
          avgOfAttempts: 4
        }
      ]
    },
    {
      guid: 'guid2',
      name: 'Lucy',
      lastname: 'Bronze',
      username: 'username2',
      activities: {
        ok: 17,
        ko: 0,
        unfinished: 0,
        total: 17
      },
      totalTimeInSeconds: 4234,
      masteredKcs: 23,
      masteredKcsInLastDays: 6,
      forgottenKcs: 2,
      needHelpLoPhase: []
    },
    {
      guid: 'guid3',
      name: 'Alessia',
      lastname: 'Russo',
      username: 'username3',
      activities: {
        ok: 48,
        ko: 53,
        unfinished: 2,
        total: 103
      },
      totalTimeInSeconds: 534,
      masteredKcs: 200,
      masteredKcsInLastDays: 0,
      forgottenKcs: 2,
      needHelpLoPhase: []
    },
    {
      guid: 'guid4',
      name: 'Ella',
      lastname: 'Toone',
      username: 'username4',
      activities: {
        ok: 50,
        ko: 50,
        unfinished: 0,
        total: 100
      },
      totalTimeInSeconds: 1999,
      masteredKcs: 23,
      masteredKcsInLastDays: 3,
      forgottenKcs: 2,
      needHelpLoPhase: []
    },
    {
      guid: 'guid5',
      name: 'Aitana',
      lastname: 'Bonmatí',
      username: 'username4',
      activities: {
        ok: 100,
        ko: 0,
        unfinished: 0,
        total: 100
      },
      totalTimeInSeconds: 1999,
      masteredKcs: 43,
      masteredKcsInLastDays: 13,
      forgottenKcs: 2,
      needHelpLoPhase: []
    },
    {
      guid: 'guid6',
      name: 'Tere',
      lastname: 'Abelleira',
      username: 'username4',
      activities: {
        ok: 0,
        ko: 100,
        unfinished: 0,
        total: 100
      },
      totalTimeInSeconds: 1999,
      masteredKcs: 200,
      masteredKcsInLastDays: 14,
      forgottenKcs: 2,
      needHelpLoPhase: []
    },
    {
      guid: 'guid7',
      name: 'Alba',
      lastname: 'Redondo',
      username: 'username4',
      activities: {
        ok: 0,
        ko: 0,
        unfinished: 100,
        total: 100
      },
      totalTimeInSeconds: 1999,
      masteredKcs: 200,
      masteredKcsInLastDays: 200,
      forgottenKcs: 2,
      needHelpLoPhase: []
    },
    {
      guid: 'guid8',
      name: 'Olga',
      lastname: 'Carmona',
      username: 'username4',
      activities: {
        ok: 0,
        ko: 50,
        unfinished: 50,
        total: 100
      },
      totalTimeInSeconds: 1999,
      masteredKcs: 20,
      masteredKcsInLastDays: 20,
      forgottenKcs: 2,
      needHelpLoPhase: []
    },
    {
      guid: 'guid9',
      name: 'Georgia',
      lastname: 'Stanway',
      username: 'username4',
      activities: {
        ok: 50,
        ko: 0,
        unfinished: 50,
        total: 100
      },
      totalTimeInSeconds: 1999,
      masteredKcs: 0,
      masteredKcsInLastDays: 0,
      forgottenKcs: 2,
      needHelpLoPhase: []
    },
    {
      guid: 'guid10',
      name: 'María',
      lastname: 'León',
      username: 'username4',
      activities: {
        ok: 33,
        ko: 34,
        unfinished: 33,
        total: 100
      },
      totalTimeInSeconds: 1999,
      masteredKcs: 70,
      masteredKcsInLastDays: 0,
      forgottenKcs: 2,
      needHelpLoPhase: []
    },
    {
      guid: 'guid11',
      name: 'Irene',
      lastname: 'Paredes',
      username: 'username4',
      activities: {
        ok: 0,
        ko: 0,
        unfinished: 0,
        total: 0
      },
      totalTimeInSeconds: 1999,
      masteredKcs: 53,
      masteredKcsInLastDays: 25,
      forgottenKcs: 2,
      needHelpLoPhase: []
    }
  ]
};

export const MOCK_DATA_TERM = {
  program: {
    numberOfKcs: 200
  },
  numberOfLastDays: 90,
  users: [
    {
      guid: 'guid',
      name: 'Sam',
      lastname: 'Kerr 90',
      username: 'username',
      activities: {
        ok: 10,
        ko: 5,
        unfinished: 2,
        total: 17
      },
      totalTimeInSeconds: 45234,
      masteredKcs: 23,
      masteredKcsInLastDays: 3,
      forgottenKcs: 2,
      needHelpLoPhase: [
        {
          guid: 'M3-NyO-14d',
          description:
            'Expresa una multiplicación dada como suma de sumandos iguales y viceversa utilizando objetos (números de 1 a 10)',
          phase: 'idenfiticar',
          numberOfAttempts: 6,
          avgOfAttempts: 4
        },
        {
          guid: 'M3-NyO-14d',
          description: 'La suma - cálculo mental',
          phase: 'idenfiticar',
          numberOfAttempts: 6,
          avgOfAttempts: 4
        }
      ]
    },
    {
      guid: 'guid2',
      name: 'Lucy',
      lastname: 'Bronze',
      username: 'username2',
      activities: {
        ok: 17,
        ko: 0,
        unfinished: 0,
        total: 17
      },
      totalTimeInSeconds: 4234,
      masteredKcs: 23,
      masteredKcsInLastDays: 6,
      forgottenKcs: 2,
      needHelpLoPhase: []
    },
    {
      guid: 'guid3',
      name: 'Alessia',
      lastname: 'Russo',
      username: 'username3',
      activities: {
        ok: 48,
        ko: 53,
        unfinished: 2,
        total: 103
      },
      totalTimeInSeconds: 534,
      masteredKcs: 200,
      masteredKcsInLastDays: 0,
      forgottenKcs: 2,
      needHelpLoPhase: []
    },
    {
      guid: 'guid4',
      name: 'Ella',
      lastname: 'Toone',
      username: 'username4',
      activities: {
        ok: 50,
        ko: 50,
        unfinished: 0,
        total: 100
      },
      totalTimeInSeconds: 1999,
      masteredKcs: 23,
      masteredKcsInLastDays: 3,
      forgottenKcs: 2,
      needHelpLoPhase: []
    },
    {
      guid: 'guid5',
      name: 'Aitana',
      lastname: 'Bonmatí',
      username: 'username4',
      activities: {
        ok: 100,
        ko: 0,
        unfinished: 0,
        total: 100
      },
      totalTimeInSeconds: 1999,
      masteredKcs: 43,
      masteredKcsInLastDays: 13,
      forgottenKcs: 2,
      needHelpLoPhase: []
    },
    {
      guid: 'guid6',
      name: 'Tere',
      lastname: 'Abelleira',
      username: 'username4',
      activities: {
        ok: 0,
        ko: 100,
        unfinished: 0,
        total: 100
      },
      totalTimeInSeconds: 1999,
      masteredKcs: 200,
      masteredKcsInLastDays: 14,
      forgottenKcs: 2,
      needHelpLoPhase: []
    },
    {
      guid: 'guid7',
      name: 'Alba',
      lastname: 'Redondo',
      username: 'username4',
      activities: {
        ok: 0,
        ko: 0,
        unfinished: 100,
        total: 100
      },
      totalTimeInSeconds: 1999,
      masteredKcs: 200,
      masteredKcsInLastDays: 200,
      forgottenKcs: 2,
      needHelpLoPhase: []
    },
    {
      guid: 'guid8',
      name: 'Olga',
      lastname: 'Carmona',
      username: 'username4',
      activities: {
        ok: 0,
        ko: 50,
        unfinished: 50,
        total: 100
      },
      totalTimeInSeconds: 1999,
      masteredKcs: 20,
      masteredKcsInLastDays: 20,
      forgottenKcs: 2,
      needHelpLoPhase: []
    },
    {
      guid: 'guid9',
      name: 'Georgia',
      lastname: 'Stanway',
      username: 'username4',
      activities: {
        ok: 50,
        ko: 0,
        unfinished: 50,
        total: 100
      },
      totalTimeInSeconds: 1999,
      masteredKcs: 0,
      masteredKcsInLastDays: 0,
      forgottenKcs: 2,
      needHelpLoPhase: []
    },
    {
      guid: 'guid10',
      name: 'María',
      lastname: 'León',
      username: 'username4',
      activities: {
        ok: 33,
        ko: 34,
        unfinished: 33,
        total: 100
      },
      totalTimeInSeconds: 1999,
      masteredKcs: 70,
      masteredKcsInLastDays: 0,
      forgottenKcs: 2,
      needHelpLoPhase: []
    },
    {
      guid: 'guid11',
      name: 'Irene',
      lastname: 'Paredes',
      username: 'username4',
      activities: {
        ok: 0,
        ko: 0,
        unfinished: 0,
        total: 0
      },
      totalTimeInSeconds: 1999,
      masteredKcs: 53,
      masteredKcsInLastDays: 25,
      forgottenKcs: 2,
      needHelpLoPhase: []
    }
  ]
};
