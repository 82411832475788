import { ArrowBack, MoreHoriz, PlayCircle } from '@mui/icons-material';
import { Button, IconButton, Stack } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import HeaderTabs from '../../../../components/headerTabs/HeaderTabs';
import FullPageLayout from '../../../../layouts/dashboardFullPage/FullPageLayout';
import QuizDetails from './components/quizDetails/QuizDetails';
import QuizQuestions from './components/quizQuestions/QuizQuestions';
import QuizResults from './components/quizResults/QuizResults';
import useViewQuiz from './useViewQuiz';

import { ThemeProvider } from '@emotion/react';
import QuizConfirmDeleteDialog from '../components/QuizConfirmDeleteDialog';
import QuizConfirmPlayDialog from '../components/QuizConfirmPlayDialog';
import QuizDropdownOptions from '../components/QuizDropdownOptions';
import QuizEditNameDialog from '../components/QuizEditNameDialog';
import quizTheme from '../components/QuizTheme';
import './ViewQuiz.scss';
import AddActivityToQuizDialog from './components/addActvityToQuizDialog/AddActivityToQuizDialog';

const ViewQuiz = () => {
  const {
    quizGuid,
    quizName,
    lastGameGuid,
    isLoading,
    questions,
    numberOfQuestions,
    educationYearName,
    selectedTab,
    subTopics,
    tabs,
    educationYear,
    standards,
    lang,
    canDelete,
    isDeletingQuestion,
    onDeleteQuestion,
    questionBeingRegenerated,
    isRegeneratingQuestion,
    onRegenerateQuestion,
    onChangeTab,
    isAddingQuestion,
    onAddQuestion,
    onAddActivities,
    onChangePositionOfQuestion,
    standardsByProgram,
    startsWithValuesStandards,
    onConfirmEditName,
    onEditNameClick,
    isEditingNameDialogOpen,
    onCancelEditingName,
    isConfirmDeleteDialogOpen,
    onDeleteQuizClick,
    onConfirmDeleteQuiz,
    onCancelDeleteQuiz,
    isConfirmPlayDialogOpen,
    onPlayQuizClick,
    onConfirmPlayQuiz,
    onCancelPlayQuiz
  } = useViewQuiz();

  const { t } = useTranslation();
  const [isAddingQuestionDialogOpen, setIsAddingQuestionDialogOpen] =
    useState(false);

  const onOpenAddQuestionDialog = () => {
    setIsAddingQuestionDialogOpen(true);
  };

  const onCloseAddQuestionDialog = () => {
    setIsAddingQuestionDialogOpen(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleDropdownOptionsClose = (event) => {
    event?.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={quizTheme}>
      <div className='view-quiz'>
        <FullPageLayout
          left={
            <IconButton
              variant='transparent'
              LinkComponent={Link}
              to='/quizzes'
            >
              <ArrowBack sx={{ color: 'white' }} />
            </IconButton>
          }
          right={
            <Stack spacing={3} direction='row' alignItems='center'>
              <Button
                startIcon={<PlayCircle />}
                disableElevation
                variant='contained'
                color='secondary'
                onClick={() => {
                  onPlayQuizClick();
                }}
              >
                {t('Play')}
              </Button>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  setAnchorEl(event.currentTarget);
                }}
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.10)',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.05)'
                  }
                }}
                disabled={false}
              >
                <MoreHoriz
                  style={{
                    fill: 'white'
                  }}
                />
              </IconButton>
            </Stack>
          }
        >
          <QuizDropdownOptions
            anchorEl={anchorEl}
            open={open}
            onClose={handleDropdownOptionsClose}
            onEditNameClick={(event) => {
              handleDropdownOptionsClose(event);
              onEditNameClick();
            }}
            onDeleteClick={(event) => {
              handleDropdownOptionsClose(event);
              onDeleteQuizClick();
            }}
          />
          <div className='view-quiz__container'>
            <QuizDetails
              name={quizName}
              subTopics={subTopics}
              numberOfActivities={numberOfQuestions}
              lang={lang}
              isLoading={
                isLoading &&
                !isAddingQuestion &&
                !isDeletingQuestion &&
                !isRegeneratingQuestion
              }
            />
            <div className='view-quiz__tabs'>
              <HeaderTabs
                tabs={tabs}
                selectedTab={selectedTab}
                onChangeTab={onChangeTab}
              />
            </div>

            {selectedTab === 'activities' && (
              <QuizQuestions
                questions={questions}
                educationYear={educationYear}
                onDeleteQuestion={onDeleteQuestion}
                readOnly={canDelete}
                isDeletingQuestion={isDeletingQuestion}
                onRegenerateQuestion={onRegenerateQuestion}
                questionBeingRegenerated={questionBeingRegenerated}
                isRegeneratingQuestion={isRegeneratingQuestion}
                allowCreateAndAddQuestions={!canDelete}
                onAddQuestion={onOpenAddQuestionDialog}
                isAddingQuestion={isAddingQuestion}
                onChangePositionOfQuestion={onChangePositionOfQuestion}
                isLoading={
                  isLoading &&
                  !isAddingQuestion &&
                  !isDeletingQuestion &&
                  !isRegeneratingQuestion
                }
                startsWithValuesStandards={startsWithValuesStandards}
              />
            )}
            {selectedTab === 'results' && (
              <QuizResults
                questions={questions}
                lastGameGuid={lastGameGuid}
                quizName={quizName}
                startsWithValuesStandards={startsWithValuesStandards}
                standardsByProgram={standardsByProgram}
              />
            )}

            {isAddingQuestionDialogOpen && (
              <AddActivityToQuizDialog
                isOpen={isAddingQuestionDialogOpen}
                onClose={onCloseAddQuestionDialog}
                onAddActivities={onAddActivities}
                subTopics={subTopics}
                programGuid={educationYear}
                quizLanguage={lang}
                isAddingQuestions={isAddingQuestion}
                allStandards={standards}
                standardsByProgram={standardsByProgram}
              />
            )}

            <QuizEditNameDialog
              isOpen={isEditingNameDialogOpen}
              onCancel={onCancelEditingName}
              onSave={onConfirmEditName}
              initialName={quizName}
              quizGuid={quizGuid}
            />

            <QuizConfirmDeleteDialog
              isOpen={isConfirmDeleteDialogOpen}
              onCancel={onCancelDeleteQuiz}
              onDelete={onConfirmDeleteQuiz}
              title={t('delete_quiz_dialog_title')}
              description={t('delete_quiz_dialog_description')}
            />

            <QuizConfirmPlayDialog
              isOpen={isConfirmPlayDialogOpen}
              onCancel={onCancelPlayQuiz}
              onPlay={onConfirmPlayQuiz}
              title={t('play_quiz_dialog_title')}
              description={t('play_quiz_dialog_description')}
            />
          </div>
        </FullPageLayout>
      </div>
    </ThemeProvider>
  );
};

export default ViewQuiz;
