import React from 'react';
import { useTranslation } from 'react-i18next';
import './StudentInsights.scss';
import { Skeleton } from '@mui/material';

const StudentInsights = ({ insights, isLoading }) => {
  const { t } = useTranslation();

  return (
    <div className='student-profile-card__container'>
      <div className='student-profile-card__header'>
        <div className='student-profile-card__header--left'>{t('insight')}</div>
        <div className='student-profile-card__header--center'></div>
        <div className='student-profile-card__header--right'></div>
      </div>
      <div className='student-profile-card__content'>
        <div className='student-profile-card__content-insights'>
          {isLoading ? (
            <>
              <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
              <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
            </>
          ) : (
            insights.map((insight) => (
              <div
                className='student-profile-card__content-insights--single'
                key={insight.title}
              >
                <div className='content-insights__title-container'>
                  <span className='content-insights__title'>
                    {insight.title}
                  </span>
                </div>
                <div className='student-profile-card__content-insights--single--description'>
                  {insight.subtitle}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentInsights;
