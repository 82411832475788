import React from 'react';
import BBTooltip from '../../../../../../atoms/tooltip/BBTooltip';
import './StandardChips.scss';
import PropTypes from 'prop-types';

export const StandardChip = ({ description, name }) => {
  return (
    <BBTooltip text={description}>
      <div className='standard-chip'>{name}</div>
    </BBTooltip>
  );
};

const StandardChips = ({ standards }) => {
  return (
    <div className='standard-chips'>
      {standards.map((standard, index) => (
        <StandardChip
          key={`${standard.guid}${index}`}
          description={standard.description}
          name={standard.name}
        />
      ))}
    </div>
  );
};

StandardChips.defaultProps = {
  standards: []
};

StandardChips.propTypes = {
  standards: PropTypes.arrayOf(
    PropTypes.shape({
      guid: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string
    })
  ).isRequired
};

export default StandardChips;
