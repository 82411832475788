import { teacherDashboardEndpoints } from '../helpers/endpoints';
import { fetchWithoutToken } from '../helpers/fetcher';

export const login = async ({ email, password }) => {
  const path = teacherDashboardEndpoints.login;

  const params = { email, password, admitted_roles: ['R02', 'R03', 'R04'] };

  return fetchWithoutToken({
    method: 'POST',
    path,
    data: params,
    disableCatchHandling: true
  });
};

export const oauthLogin = async (data) => {
  const path = teacherDashboardEndpoints.oauthLogin;

  return fetchWithoutToken({
    method: 'POST',
    path,
    data
  });
};
