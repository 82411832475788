import { useTranslation } from 'react-i18next';
import TooltipCell from '../TooltipCell/TooltipCell';
import './cellStudentPerformance.scss';

const CellStudentPerformance = ({ studentPerformanceData }) => {
  const {
    total,
    correct,
    incorrect,
    abandoned,
    correctPercent,
    incorrectPercent,
    abandonedPercent
  } = studentPerformanceData;

  const { t } = useTranslation();

  // start - middle - end styles
  let correctClass = 'start';
  let incorrectClass = 'middle';
  let abandonedClass = 'end';

  if (correctPercent === 100) {
    correctClass = 'full';
    incorrectClass = 'none';
    abandonedClass = 'none';
  }
  if (correctPercent === 0) correctClass = 'none';

  if (incorrectPercent === 100) {
    incorrectClass = 'full';
    correctClass = 'none';
    abandonedClass = 'none';
  }
  if (incorrectPercent === 0) incorrectClass = 'none';
  if (!correctPercent && abandonedPercent) incorrectClass = 'start';
  if (correctPercent && !abandonedPercent) incorrectClass = 'end';

  if (abandonedPercent === 100) {
    abandonedClass = 'full';
    correctClass = 'none';
    incorrectClass = 'none';
  }
  if (abandonedPercent === 0) abandonedClass = 'none';

  const PerformanceTooltipComponent = ({
    total,
    correct,
    incorrect,
    abandoned,
    correctPercent,
    incorrectPercent,
    abandonedPercent
  }) => {
    return (
      <div className='cell-student-performance-tooltip'>
        <div className='cell-student-performance-tooltip__title'>
          {`${total} ${t('activities')}`}
        </div>
        <div className='cell-student-performance-tooltip__legend cell-student-performance-tooltip__legend--correct'>
          <span />
          {`${correct} ${t('student_activities_correct')} - ${correctPercent}%`}
        </div>
        <div className='cell-student-performance-tooltip__legend cell-student-performance-tooltip__legend--incorrect'>
          <span />
          {`${incorrect} ${t(
            'student_activities_incorrect'
          )} - ${incorrectPercent}%`}
        </div>
        <div className='cell-student-performance-tooltip__legend cell-student-performance-tooltip__legend--abandoned'>
          <span />
          {`${abandoned} ${t(
            'student_activities_abandoned'
          )} - ${abandonedPercent}%`}
        </div>
      </div>
    );
  };

  return (
    <TooltipCell
      // open={true}
      title={
        studentPerformanceData && (
          <PerformanceTooltipComponent
            total={total}
            correct={correct}
            incorrect={incorrect}
            abandoned={abandoned}
            correctPercent={correctPercent}
            incorrectPercent={incorrectPercent}
            abandonedPercent={abandonedPercent}
          />
        )
      }
      placement='bottom'
    >
      <div className='cell-student-performance'>
        <div
          className={`cell-student-performance__progress-bar cell-student-performance__progress-bar--${'progressBarClass'}`}
        >
          <div className='cell-student-performance__progress-bar-bg'></div>
          <div className='cell-student-performance__progress-bar-percentages'>
            <div
              className={`cell-student-performance__progress-bar-correct cell-student-performance__progress-bar--${correctClass}`}
              style={{ width: correctPercent + '%' }}
            ></div>
            <div
              className={`cell-student-performance__progress-bar-incorrect cell-student-performance__progress-bar--${incorrectClass}`}
              style={{ width: incorrectPercent + '%' }}
            ></div>
            <div
              className={`cell-student-performance__progress-bar-abandoned cell-student-performance__progress-bar--${abandonedClass}`}
              style={{ width: abandonedPercent + '%' }}
            ></div>
          </div>
        </div>
      </div>
    </TooltipCell>
  );
};

export default CellStudentPerformance;
