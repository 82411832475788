import { fetchWithToken } from '../helpers/fetcher';

export const getTeacherDashboardUnits = async ({
  lang,
  unitGuid,
  courseGuid
}) => {
  let response = await fetchWithToken({
    method: 'GET',
    path: `blueberry/teacher/${courseGuid}/dashboard/unit?lang=${lang}${
      unitGuid ? `&unitGuid=${unitGuid}` : ''
    }`
  });

  return response.data;
};

export const getTeacherDashboardStandards = async ({
  lang,
  courseGuid,
  standardGuid
}) => {
  let response = await fetchWithToken({
    method: 'GET',
    path: `blueberry/teacher/${courseGuid}/dashboard/standard?lang=${lang}${
      standardGuid ? `&thematicUnitGuid=${standardGuid}` : ''
    }`
  });

  return response.data;
};
