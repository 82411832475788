import Check from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './selectStudentsTimeRange.scss';

const SelectStudentsTimeRange = ({ selectedTimeRange, onChange }) => {
  const { t } = useTranslation();
  return (
    selectedTimeRange && (
      <div className='select-students-time-range'>
        <Select
          size='small'
          onChange={({ target: { value } }) => onChange(value)}
          value={selectedTimeRange}
          IconComponent={KeyboardArrowDownIcon}
          className='select-students-time-range__select'
          fullWidth
        >
          <div className='select-students-time-range__title'>
            {t('filter_by')}
          </div>
          <MenuItem
            className='select-students-time-range__select-item'
            value={7}
          >
            {t('last_week')}{' '}
            {selectedTimeRange === 7 && <Check fontSize='small' />}
          </MenuItem>
          <MenuItem
            className='select-students-time-range__select-item'
            value={30}
          >
            {t('last_month')}{' '}
            {selectedTimeRange === 30 && <Check fontSize='small' />}
          </MenuItem>
          <MenuItem
            className='select-students-time-range__select-item'
            value={90}
          >
            {t('last_term')}{' '}
            {selectedTimeRange === 90 && <Check fontSize='small' />}
          </MenuItem>
        </Select>
      </div>
    )
  );
};

export default SelectStudentsTimeRange;
