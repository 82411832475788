import BBTooltip from './BBTooltip';

const InfoTooltip = ({ text, placement = 'right', arrow = false }) => {
  return (
    <BBTooltip text={text} placement={placement} arrow={arrow}>
      <span style={{ cursor: 'help' }}>
        <svg className='page-with-header__header-info-icon' viewBox='0 0 16 16'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M16.0999 7.9999C16.0999 12.1973 12.6973 15.5999 8.4999 15.5999C4.30254 15.5999 0.899902 12.1973 0.899902 7.9999C0.899902 3.80254 4.30254 0.399902 8.4999 0.399902C12.6973 0.399902 16.0999 3.80254 16.0999 7.9999ZM9.4499 4.1999C9.4499 4.72457 9.02459 5.1499 8.4999 5.1499C7.97524 5.1499 7.5499 4.72457 7.5499 4.1999C7.5499 3.67524 7.97524 3.2499 8.4999 3.2499C9.02459 3.2499 9.4499 3.67524 9.4499 4.1999ZM7.5499 7.0499C7.02524 7.0499 6.5999 7.47524 6.5999 7.9999C6.5999 8.52459 7.02524 8.9499 7.5499 8.9499V11.7999C7.5499 12.3246 7.97524 12.7499 8.4999 12.7499H9.4499C9.97459 12.7499 10.3999 12.3246 10.3999 11.7999C10.3999 11.2752 9.97459 10.8499 9.4499 10.8499V7.9999C9.4499 7.47524 9.02459 7.0499 8.4999 7.0499H7.5499Z'
          />
        </svg>
      </span>
    </BBTooltip>
  );
};

export default InfoTooltip;
