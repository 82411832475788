import { useTranslation } from 'react-i18next';
import TooltipCell from '../TooltipCell/TooltipCell';
import './cellStudentMasteredContent.scss';

const CellStudentMasteredContent = ({
  selectedTimeRange,
  studentMasteredContentData
}) => {
  const {
    masteredKcsPercent,
    masteredKcsInLastDaysPercent,
    restOfMasteredKcsPercent,
    forgottenKcsPercent
  } = studentMasteredContentData;

  const { t } = useTranslation();

  const PROGRESS_BAR_MINIMUM_PERCENT = 4;

  // start - middle - end styles
  let forgottenKcsClass = 'start';
  if (forgottenKcsPercent === 100) forgottenKcsClass = 'full';

  let masteredKcsClass = 'start';
  if (restOfMasteredKcsPercent === 100) masteredKcsClass = 'full';

  let masteredKcsInLastDaysClass = 'middle';
  if (!restOfMasteredKcsPercent) masteredKcsInLastDaysClass = 'start';
  if (masteredKcsInLastDaysPercent + restOfMasteredKcsPercent === 100)
    masteredKcsInLastDaysClass = 'end';
  if (masteredKcsInLastDaysPercent === 100) masteredKcsInLastDaysClass = 'full';

  const MasteredContentTooltipComponent = ({
    forgottenKcsPercent,
    masteredKcsPercent,
    masteredKcsInLastDaysPercent
  }) => {
    return (
      <div className='cell-student-mastered-tooltip'>
        <div className='cell-student-mastered-tooltip__legend cell-student-mastered-tooltip__legend--forgotten'>
          <span />
          {t('student_content_forgotten')} - {forgottenKcsPercent}%
        </div>
        <div className='cell-student-mastered-tooltip__legend cell-student-mastered-tooltip__legend--progress-total'>
          <span />
          {t('student_content_total_progress')} - {masteredKcsPercent}%
        </div>
        <div className='cell-student-mastered-tooltip__legend cell-student-mastered-tooltip__legend--progress-time'>
          <span />
          {selectedTimeRange === 7 && t('student_content_total_week')}
          {selectedTimeRange === 30 && t('student_content_total_month')}
          {selectedTimeRange === 90 && t('student_content_total_term')} -
          {masteredKcsInLastDaysPercent}%
        </div>
        <div className='cell-student-mastered-tooltip__legend cell-student-mastered-tooltip__legend--total'>
          <span />
          {t('student_content_total_course')}
        </div>
      </div>
    );
  };

  return (
    <div className='cell-student-mastered-content'>
      <TooltipCell
        // open={true}
        title={
          <MasteredContentTooltipComponent
            forgottenKcsPercent={forgottenKcsPercent}
            masteredKcsPercent={masteredKcsPercent}
            masteredKcsInLastDaysPercent={masteredKcsInLastDaysPercent}
          />
        }
        placement='bottom'
      >
        <div className={`cell-student-mastered-content__progress-bar`}>
          <div className='cell-student-mastered-content__progress-bar-bg' />
          <div className='cell-student-mastered-content__progress-bar-percentages'>
            {!!restOfMasteredKcsPercent && (
              <div
                className={`cell-student-mastered-content__progress-bar-mastered cell-student-mastered-content__progress-bar--${masteredKcsClass}`}
                style={{
                  width:
                    restOfMasteredKcsPercent <= PROGRESS_BAR_MINIMUM_PERCENT
                      ? PROGRESS_BAR_MINIMUM_PERCENT + '%'
                      : restOfMasteredKcsPercent + '%'
                }}
              />
            )}
            {!!masteredKcsInLastDaysPercent && (
              <div
                className={`cell-student-mastered-content__progress-bar-mastered-last cell-student-mastered-content__progress-bar--${masteredKcsInLastDaysClass}`}
                style={{
                  width:
                    masteredKcsInLastDaysPercent <= PROGRESS_BAR_MINIMUM_PERCENT
                      ? PROGRESS_BAR_MINIMUM_PERCENT + '%'
                      : masteredKcsInLastDaysPercent + '%'
                }}
              />
            )}

            {!!forgottenKcsPercent && (
              <div
                className={`cell-student-mastered-content__progress-bar-forgotten cell-student-mastered-content__progress-bar--${forgottenKcsClass}`}
                style={{
                  width:
                    forgottenKcsPercent <= PROGRESS_BAR_MINIMUM_PERCENT
                      ? PROGRESS_BAR_MINIMUM_PERCENT + '%'
                      : forgottenKcsPercent + '%'
                }}
              />
            )}
          </div>
        </div>
      </TooltipCell>

      <div className='cell-student-mastered-content__delta'>
        {masteredKcsInLastDaysPercent > 0 && '+'}
        {masteredKcsInLastDaysPercent}%
      </div>
    </div>
  );
};

export default CellStudentMasteredContent;
