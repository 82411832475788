import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchWithToken } from '../../../utils/helpers/fetcher';
import useAuth from '../../../utils/hooks/useAuth';
import { useLocalStorage } from '../../../utils/hooks/useLocalStorage';
import { localStorageKeys } from '../../../utils/localStorageKeys';

const useProfile = () => {
  const { user } = useAuth();
  const { t, i18n } = useTranslation();
  const { setItem, getItem } = useLocalStorage();
  const [isLoading, setIsLoading] = useState(false);
  const [hasUpdatesToSave, setHasUpdatesToSave] = useState(false);
  const [userProfile, setUserProfile] = useState({
    name: '',
    lastname: '',
    langId: ''
  });

  const LANGUAGE_OPTIONS = [
    { label: t('Spanish'), code: 'es' },
    { label: t('English'), code: 'en' },
    { label: t('Portuguese'), code: 'pt' }
  ];

  const languageCodeToOption = {
    es: { label: t('Spanish'), code: 'es' },
    en: { label: t('English'), code: 'en' },
    pt: { label: t('Portuguese'), code: 'pt' }
  };

  useEffect(() => {
    setUserProfile({
      name: user?.name,
      lastname: user?.lastname,
      lang_id: getItem(localStorageKeys.language)
    });
  }, [user]);

  useEffect(() => {
    setHasUpdatesToSave(
      userProfile.name !== user?.name ||
        userProfile.lastname !== user?.lastname ||
        userProfile.lang_id !== getItem(localStorageKeys.language)
    );
  }, [user, userProfile]);

  const handleSave = async () => {
    setIsLoading(true);
    await fetchWithToken({
      path: `/users/${user.guid}`,
      method: 'PUT',
      data: {
        name: userProfile.name,
        lastname: userProfile.lastname,
        lang_id: userProfile.lang_id
      }
    });
    setItem(localStorageKeys.language, userProfile.lang_id);
    i18n.changeLanguage(userProfile.lang_id);
    setHasUpdatesToSave(
      userProfile.name !== user?.name ||
        userProfile.lastname !== user?.lastname ||
        userProfile.lang_id !== getItem(localStorageKeys.language)
    );
    setIsLoading(false);
  };

  return {
    userProfile,
    isLoading,
    setUserProfile,
    hasUpdatesToSave,
    user,
    LANGUAGE_OPTIONS,
    languageCodeToOption,
    handleSave
  };
};

export default useProfile;
