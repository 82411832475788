import { useEffect, useState } from 'react';
import { getDetailReportCourse } from '../../../../../utils/crud/report';
import { tryJsonParse } from '../../../../../utils/helpers/tryJsonParse';
// import dummyDetailJsonData from '../../prod-course-insights-lo-03.json';

const useDetailLoDialog = (t, courseGuid, userGuid, lo) => {
  const [isLoading, setIsLoading] = useState(false);
  const [reportDetail, setReportDetail] = useState([]);
  const [displayedAttempts, setDisplayedAttempts] = useState([]);

  // TODO: review this function to make it more readable and efficient
  const parseAttempts = (attempts) => {
    return attempts
      .map((attempt) => {
        try {
          if (
            attempt?.question &&
            Object.keys(attempt?.question)?.length !== 0
          ) {
            // Intenta parsear el string JSON a un objeto
            const questionParse = tryJsonParse(attempt?.question);

            // Comprueba si questionParse es un objeto vacío
            if (Object.keys(questionParse).length === 0) {
              // console.log('questionParse es un objeto vacío');
              return null;
            } else {
              if (questionParse?.type === 'scaffold') {
                // Si es de tipo scaffold, mostramos solo la primera question
                if (
                  questionParse?.questions &&
                  questionParse.questions?.length > 0
                ) {
                  attempt.question = questionParse.questions[0];
                }
              } else {
                // Parse el string JSON a un objeto
                attempt.question = questionParse;
              }
            }
          } else {
            // console.log('attempt.question es indefinido o un objeto vacío');
            return null;
          }
        } catch (error) {
          console.error("Error al parsear 'question': ", error);
        }

        // Retorna el objeto 'attempt' con 'question' parseada
        return attempt;
      })
      .filter(Boolean) // Filtra cualquier valor null
      .sort((attempt1, attempt2) => {
        const date1 = new Date(attempt1.start_at);
        const date2 = new Date(attempt2.start_at);

        // Si date1 es anterior a date2, devuelve un número positivo. Si date2 es anterior a date1, devuelve un número negativo.
        // En caso de empate (las dos fechas son iguales), devuelve 0.
        // Para orden descendente, se 'intercambian' attempt1 y attempt2 en la resta. Para un orden ascendente, sería date1 - date2.
        return date2 - date1;
      });
  };

  const fetchDetailReportCourse = async () => {
    setIsLoading(true);

    const reportResponseApi = await getDetailReportCourse({
      courseGuid: courseGuid?.guid,
      userGuid: userGuid,
      loGuid: lo?.guid
    });

    const reportResponse = reportResponseApi;

    // const reportResponse = dummyDetailJsonData.data;

    let reportDetailAttemptsAll = reportResponse?.attempts || [];

    reportDetailAttemptsAll = parseAttempts(reportDetailAttemptsAll);

    setDisplayedAttempts(reportDetailAttemptsAll);

    setReportDetail(reportResponse);

    setIsLoading(false);
  };

  useEffect(() => {
    fetchDetailReportCourse();
  }, []);

  return {
    isLoading,
    reportDetail,
    displayedAttempts
  };
};

export default useDetailLoDialog;
