import { useTranslation } from 'react-i18next';
import { ReactComponent as ClockIcon } from '../../../../../assets/icons/clock_sm.svg';
import { ReactComponent as PencilIcon } from '../../../../../assets/icons/pencil_sm.svg';
import TooltipCell from '../TooltipCell/TooltipCell';
import './cellStudentEngagement.scss';

const CellStudentEngagement = ({
  selectedTimeRange,
  studentEngagementData
}) => {
  const {
    totalActivities,
    hasEngagementHours,
    userTimeHours,
    userTimeMinutes,
    userTimeSeconds,
    userTimePercent
  } = studentEngagementData;

  const { t } = useTranslation();

  // controls smaller sizes
  const userTimeProgress =
    userTimePercent === 0
      ? '0%'
      : userTimePercent > 100
      ? '100%'
      : userTimePercent <= 2
      ? '3%'
      : userTimePercent + '%';

  // start - middle - end styles
  const progressBarClass =
    userTimePercent === 0
      ? 'empty'
      : userTimePercent >= 100
      ? 'end'
      : userTimePercent >= 3
      ? 'middle'
      : 'start';

  const EngagementTooltipComponent = () => {
    return (
      <div className='cell-student-engagement-tooltip'>
        <div className='cell-student-engagement-tooltip__legend cell-student-engagement-tooltip__legend--practiced'>
          <span></span>
          {selectedTimeRange === 7 && t('student_engagement_week')}
          {selectedTimeRange === 30 && t('student_engagement_month')}
          {selectedTimeRange === 90 && t('student_engagement_term')}
        </div>
        <div className='cell-student-engagement-tooltip__legend cell-student-engagement-tooltip__legend--total'>
          <span></span>
          {selectedTimeRange === 7 && t('student_engagement_total_week')}
          {selectedTimeRange === 30 && t('student_engagement_total_month')}
          {selectedTimeRange === 90 && t('student_engagement_total_term')}
        </div>
      </div>
    );
  };

  return (
    <div className='cell-student-engagement'>
      <TooltipCell
        // open={true}
        title={<EngagementTooltipComponent />}
        placement='bottom'
      >
        <div
          className={`cell-student-engagement__progress-bar cell-student-engagement__progress-bar--${progressBarClass}`}
        >
          <div className='cell-student-engagement__progress-bar-bg'></div>
          <div
            className='cell-student-engagement__progress-bar-percentage'
            style={{ width: userTimeProgress }}
          ></div>
        </div>
      </TooltipCell>
      <div className='cell-student-engagement__time'>
        <ClockIcon />
        {hasEngagementHours && (
          <>
            <span>{userTimeHours}</span>h
          </>
        )}
        <span>{userTimeMinutes}</span>m<span>{userTimeSeconds}</span>s
      </div>
      <div className='cell-student-engagement__activities'>
        <PencilIcon />
        <span>{totalActivities}</span>
      </div>
    </div>
  );
};

export default CellStudentEngagement;
